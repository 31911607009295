import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Collapse,
  Col,
  Input,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
import Select from "react-select";
import Header from "components/Headers/Header";
import { toast } from "react-toastify";
import {
  prePublicationList,
  getPrePublicationById,
  updatePrePublicationStatus,
  getPrePublicationSummaryById 
} from "utils/endpoint";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { isActive } from "utils/enums";
import { Pagination as Paginationes } from "antd";
import { parseURLParams } from "views/universe/UniverseList";
import useSearchParams from "utils/useSearchParams";
import { backToPreviousClickedRecord, get_type_list } from "utils/Commen";
function PrePublicationList() {
  const navigate = useNavigate();
  const [params ,setParams] = useSearchParams();
  const id = params.id;
  const currentPage = params.currentPage;
  const fromDate = params.fromDate;
  const toDate = params.toDate;
  const enTitle = params.enTitle;
  const label = params.label;
  const company = params.company;
  const is_active = params.is_active;
  const typeIdsArray = params.typeIdsArray ? params.typeIdsArray : [];
  const [isLoading, setIsLoading] = useState(false);
  const [prePublicationLists, setPrePublicationLists] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(currentPage || 1);
  const [per_Page, setPer_Page] = useState(100); 
  const [isRedirectFirstPage,setIsRedirectFirstPage] = useState(1)
  const [isOpen, setIsOpen] = React.useState(false);
  const [filterFromDate, setFilterFromDate] = useState(fromDate || "");
  const [filterToDate, setFilterToDate] = useState(toDate || "");
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterLabel, setFilterLabel] = useState(label || "");
  const [filterEnTitle, setFilterEnTitle] = useState(enTitle || "");
  const [filterCompany, setFilterCompany] = useState(company || "");
  const [filterIsActive, setFilterIsActive] = useState(is_active || "");
  const [permissions, setPermissions] = useState([]);
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [sort_key, setSortKey] = useState("created_at");
  const [sort_by, setSortBy] = useState("DESC");
  const [firstTimeFilterActive,setFirstTimeFilterActive] = useState(false);
  const [prePublicationFilter,setPrePublicationFilter] = useState('')
  const selectInputRef = useRef(null);
  const [typeList, setTypeList] = useState([]);
  const [typeSelected, setTypeSelected] = useState(null);
  const [filterType, setFilterType] = useState(typeIdsArray || []);

  const getTypeList = async () => {
    const allType = await get_type_list();
    const newTypes = [
      { id: "8f97245b-5d1a-4dfd-8158-7211baf22e82", name: "None" },
      ...allType,
    ];
    setTypeList(newTypes);
    if (params.typeIdsArray && newTypes.length > 0) {
      const selectedIds = params.typeIdsArray;
      const selectedType = newTypes.filter((data) =>
      selectedIds.includes(data.id)
      );
      const finalSelectedTypeResponse = selectedType.map((x) => {
        return {
          value: x.id,
          label: x.name,
        };
      });
      
      setTypeSelected(finalSelectedTypeResponse);
    }
  };

  useEffect(() => {
    getTypeList();
  }, []);

  
  const toggle = () => setIsOpen(!isOpen);

  const sortColumns = (currentSortKey, currentSortBy) => {
    let mySortKey = currentSortKey;
    let mySortBy = currentSortBy;
    if (!sort_by) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    if (currentSortKey === sort_key) {
      mySortKey = currentSortKey;
      mySortBy = currentSortBy === "ASC" ? "DESC" : "ASC";
      setSortBy(currentSortBy === "ASC" ? "DESC" : "ASC");
    }
    if (currentSortKey !== sort_key) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    let filter = `?page=${page}&per_page=${per_Page}&sort_key=${mySortKey}&sort_by=${mySortBy}`;

    // if (filterFromDate) {
    //   filter += `&from_date=${filterFromDate}`;
    // }

    // if (filterToDate) {
    //   filter += `&to_date=${filterToDate}`;
    // }

    // if (filterLabel && filterLabel.trim()) {
    //   filter += `&label=${filterLabel}`;
    // }

    // if (filterEnTitle && filterEnTitle.trim()) {
    //   filter += `&en_title=${filterEnTitle}`;
    // }

    // if (filterCompany && filterCompany.trim()) {
    //   filter += `&company=${filterCompany}`;
    // }

    // if (filterIsActive) {
    //   filter += `&is_active=${filterIsActive}`;
    // } else {
    //   filter += `&is_active=${filterIsActive}`;
    // }

    if(prePublicationFilter){
      let filter = prePublicationFilter;
      filter.sort_by= mySortBy;
    }

    getPrePublicationList(filter);
  };
  const getPrePublicationList = async (
   prePublicationFilter
  ) => {
    setPrePublicationLists([]);
    setIsLoading(true);
    showLoader();
    try {
      const result = await prePublicationList(prePublicationFilter);
      if (result?.isSuccess) {
        setPrePublicationLists(result?.data?.data?.records);
        // setTotalPages(
        //   Math.ceil(
        //     result?.data?.data?.totalRecords / result?.data?.pagination?.limit
        //   )
        // );
        setTotalPages(result?.data?.data?.totalRecords);
        setPer_Page(result?.data?.pagination?.limit || 10);
        setIsLoading(false);
        hideLoader();
      } else {
        setIsLoading(false);
        hideLoader();
        setPrePublicationLists([]);
      }
    } catch (error) {
      setIsLoading(false);
      hideLoader();
      setPrePublicationLists([]);
    }
  };
  // const prevPage = async () => {
  //   setPage(page - 1);
  // };

  // const nextPage = async () => {
  //   setPage(page + 1);
  // };

  const commonPrePublicationFilterOptions = (prePublicationFilterObject) => {

    let filter = `?page=${page}&per_page=${per_Page}&sort_key=${sort_key}&sort_by=${sort_by}`;
  
    const from_date = prePublicationFilterObject?.from_date;
    const to_date = prePublicationFilterObject?.to_date;
    const en_title = prePublicationFilterObject?.en_title;
    const company = prePublicationFilterObject?.company;
    const label = prePublicationFilterObject?.label;
    const is_active = prePublicationFilterObject?.is_active;
    const type_id = prePublicationFilterObject?.type_id;

    if(new Date(from_date) <= new Date(to_date)){
      filter += `&from_date=${filterFromDate}`;
    }

    if (new Date(to_date) >= new Date(from_date)) {
      filter += `&to_date=${filterToDate}`;
    }

    if (label && label.trim()) {
      filter += `&label=${label}`;
    }

    if (en_title && en_title.trim()) {
      filter += `&en_title=${en_title}`.replace(/'/g, "''");
    }

    if (company && company.trim()) {
      filter += `&company=${company}`;
    }

    if (is_active) {
      filter += `&is_active=${is_active}`;
    } else {
      filter += `&is_active=${is_active}`;
    }
    
    if (type_id) {
      filter += `&type_id=${type_id}`;
    }
     
   return filter;
  }

  // const fetchPrePublicationList = async (filter) => {
  //   if (
  //     filterFromDate ||
  //     filterToDate ||
  //     filterLabel ||
  //     filterEnTitle ||
  //     filterCompany ||
  //     filterIsActive ||
  //     filterIsActive === false
  //   ) {
  //     getPrePublicationList(page, per_Page, filter);
  //   }
  // };

  const handlePrePublicationListAddFilter = async () => {
    try {
      setFilterLoading(true);
      showLoader()
      setPage(1)
      callAPI()
    } catch (error) {
      setFilterLoading(false);
      hideLoader()
    }
  };

  const callAPI = function() {
    try {
      const filterObject = {
        from_date:filterFromDate,
        to_date : filterToDate,
        en_title: filterEnTitle,
        label:filterLabel,
        company:filterCompany,
        is_active : filterIsActive,
        type_id: filterType.length > 0 ? filterType.join(',') : "",
      }

      const filter  = commonPrePublicationFilterOptions(filterObject)
      const response = parseURLParams(filter);
      
      setPrePublicationFilter(response);
      getPrePublicationList(filter)
      setFirstTimeFilterActive(false)
      setFilterLoading(false);
      hideLoader()
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  }


  const handlePrePublicationListCancelFilter = async () => {
    setFilterLoading(true);
    showLoader()
    setFilterEnTitle("");
    setFilterLabel("");
    setFilterCompany("");
    setPrePublicationFilter('');
    setFilterFromDate("");
    setFilterToDate("");
    setFilterIsActive("");
    setIsRedirectFirstPage(1)
    selectInputRef.current.props.value.label = "";
    selectInputRef.current.props.value.label = "select";
    setFirstTimeFilterActive(false)
    setPage(1)
    const filter = `?page=${page}&per_page=${per_Page}&sort_key=${sort_key}&sort_by=${sort_by}`;
    navigate('/admin/prePublication/Prepublication-list', { replace: true });
    getPrePublicationList(filter);
    setFilterLoading(false);
    hideLoader()
  };

 

  const handleFilterWithPagination = async() => {

    const response = commonPrePublicationFilterOptions(prePublicationFilter);

    const filter = parseURLParams(response);

    const prePublicationFilterValue = filter.from_date || filter.to_date || filter.label || filter.company || filter.en_title ||filter.is_active || filter.is_active === 'false' || filter.type_id;

    if(isRedirectFirstPage){
        if(!firstTimeFilterActive && (prePublicationFilterValue !== 'undefined')){
            setPage(1)
            getPrePublicationList(response);
            setFirstTimeFilterActive(true);
            return;
        } 

        if(firstTimeFilterActive && totalPages <= filter.per_page && (prePublicationFilterValue)){
          setPage(1)
          getPrePublicationList(response);
          return;
        }
    }   

    getPrePublicationList(response);
      
  }

  useEffect(()=>{
    handleFilterWithPagination();
  },[totalPages,page, per_Page, sort_key, sort_by])

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
  }, []);

  const handleStatus = async (data) => {
    setIsLoading(true);
    const res = await updatePrePublicationStatus(data);
    toast.success(`${res.message}`);
    setIsLoading(false);
  };

  if(id !== null && id){
    const response = backToPreviousClickedRecord("pre-publication",id);
    if(response && response !== null || undefined){
      setParams({search:null})  
     }
   }

  useEffect(()=>{
    if(filterEnTitle|| filterFromDate|| filterToDate|| filterIsActive || filterLabel || filterCompany || filterType){
      setIsRedirectFirstPage(0)
      callAPI()
    }
  },[enTitle,fromDate,toDate,label,company,is_active])

  const getUrl = (baseUrl) => {

    if(filterEnTitle){
      baseUrl+=`&en_title=${filterEnTitle}`
    }

    if(filterCompany){
      baseUrl+=`&company=${filterCompany}`
    }

    if(filterLabel){
      baseUrl+=`&label=${filterLabel}`
    }

    if(filterFromDate){
      baseUrl+=`&from_date=${filterFromDate}`
    }

    if(filterToDate){
      baseUrl+=`&to_date=${filterToDate}`
    }
    
    if(filterIsActive || filterIsActive === false || filterIsActive === 'none'){
      baseUrl +=`&is_active=${filterIsActive}`
    }

    if(filterType && filterType.length > 0){
      baseUrl+=`&type_id=${filterType.join(',')}`
    }

    return baseUrl;
  }

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow ">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="6">
                    <h1 className="mb-0"> Pre-Publication List</h1>
                  </Col>
                  <Col lg="6" className="add_btn text-right">
                    {permissions &&
                      permissions.map((permission) =>
                        permission === "create-pre-publication" ? (
                          <Button
                            
                            color="primary"
                            onClick={() => {
                              navigate(
                                "/admin/prePublication/create-Prepublication"
                              );
                            }}>
                            {isLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!isLoading && (
                              <span className="indicator-label">
                                Add Pre-Publication
                              </span>
                            )}
                          </Button>
                        ) : (
                          ""
                        )
                      )}

                    <Button onClick={toggle} className="filter_btn">
                      <i className="fa-solid fa-filter"></i>
                      <span className="me-2">Filter</span>
                    </Button>
                  </Col>
                </Row>
                <Collapse isOpen={isOpen}>
                  <Card className=" mt-2">
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              From Date
                            </label>
                            <Input
                              closeonselect="true"
                              className="form-control"
                              id="from_date"
                              name="from_date"
                              type="date"
                              value={filterFromDate}
                              onChange={(e) => {
                                setFilterFromDate(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              To Date
                            </label>
                            <Input
                              closeonselect="true"
                              className="form-control"
                              id="to_date"
                              name="to_date"
                              type="date"
                              value={filterToDate}
                              onChange={(e) => {
                                setFilterToDate(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              En Title
                            </label>
                            <Input
                              id="En Title"
                              name="en title"
                              placeholder="En Title"
                              type="text"
                              value={filterEnTitle}
                              onChange={(event) => {
                                setFilterEnTitle(event.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Label</label>
                            <Input
                              id="Label"
                              name="label"
                              placeholder="Label"
                              type="text"
                              value={filterLabel}
                              onChange={(event) => {
                                setFilterLabel(event.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Company
                            </label>
                            <Input
                              id="Company"
                              name="company"
                              placeholder="Company"
                              type="text"
                              value={filterCompany}
                              onChange={(event) => {
                                setFilterCompany(event.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Active
                            </label>
                            <Select
                              name="is_active"
                              id="is_active"
                              className="react-select"
                              defaultValue={{
                                label: filterIsActive === "true" ? "Yes" : filterIsActive === "false"  ? "No" : filterIsActive == 'none'?'None':'Select',
                              }}
                              options={Array.from([{id:'none',name:'None'},...isActive]).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFilterIsActive(event.value);
                              }}
                              ref={selectInputRef}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Type</label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              name="type_id"
                              id="type_id"
                              className="react-select"
                              options={Array.from(typeList).map((value) => {
                                return {
                                  value: value.name,
                                  label: value.name,
                                };
                              })}
                              value={typeSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setTypeSelected(selected);
                                setFilterType(value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={handlePrePublicationListAddFilter}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                ADD FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={handlePrePublicationListCancelFilter}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                RESET FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div
                      className="table-responsive p-0 pb-2 fs-6"
                      id="data-table">
                      <table className="table align-items-center display justify-content-center mb-0 w-100">
                        <thead>
                          <tr>
                            <th >No.</th>
                            <th style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("en_title", sort_by)
                              }> EN Title
                              {sort_key === "en_title" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)}</th>
                            <th style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("jp_title", sort_by)
                              }>Japanese Title
                              {sort_key === "jp_title" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)}</th>
                            <th style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("label", sort_by)
                              }>Label
                              {sort_key === "label" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)}</th>
                            <th style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("company", sort_by)
                              }>Company
                              {sort_key === "company" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)}</th>
                            <th style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("type", sort_by)
                              }>Type
                              {sort_key === "type" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)}</th>
                            <th className="link" style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("link", sort_by)
                              }> Link
                              {sort_key === "link" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)}</th>

                            <th style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("created_at", sort_by)
                              }>CreatedAt
                              {sort_key === "created_at" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)} </th>
                            {permissions &&
                              permissions.map((permission) =>
                                permission === "update-pre-publication" ? (
                                  <>
                                    <th style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("is_active", sort_by)
                              }>Is Active
                              {sort_key === "is_active" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)} </th>{" "}
                                    <th className="action">Action</th>
                                  </>
                                ) : (
                                  ""
                                )
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {prePublicationLists.length > 0 ? (
                            prePublicationLists.map((info, index) => {
                              const indexNo =
                                (page - 1) * per_Page + (index + 1);
                              return (
                                <tr key={info.id} id={`pre-publication-list-record-${info.id}`}>
                                  <td>{indexNo}</td>
                                  <td className="primary-text-color" style={{cursor:'pointer'}}>
                                  <Link
                                      className="indicator-label"
                                      onClick={async () => {
                                        const prePublicationId = info.id;
                                        const res = await getPrePublicationSummaryById(prePublicationId);
                                        let baseURL = `/admin/prePublication/view-Prepublication/${prePublicationId}?page=${page}`;
                                        const url = getUrl(baseURL);
                                        navigate(
                                          url,
                                          { state: { row: res?.data } }
                                        );
                                      }}>
                                      {info.en_title}
                                    </Link>
                                    </td>
                                  <td>{info.jp_title}</td>
                                  <td>{info.label}</td>
                                  <td>{info.company}</td>
                                  <td>{info.type}</td>
                                  <td className="link primary-text-color"><a href={info.link} target="_blank" rel="noreferrer">
                                    {info?.link?.length > 50 ? `${info?.link.slice(0, 40)}...` : info?.link}
                                  </a></td>
                                  <td>
                                    {info.created_at
                                      ? moment(info.created_at).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      : ""}
                                  </td>
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission ===
                                      "update-pre-publication" ? (
                                        <>
                                          <td>
                                            <label className="custom-toggle">
                                              <input
                                                onChange={() => {}}
                                                onClick={() => {
                                                  handleStatus({
                                                    id: info.id,
                                                    is_active: !info.is_active,
                                                  });
                                                }}
                                                value={info.is_active === true}
                                                defaultChecked={
                                                  info.is_active === true
                                                }
                                                type="checkbox"
                                              />
                                              <span className="custom-toggle-slider  rounded-circle" />
                                            </label>
                                          </td>

                                          <td className="action">
                                            <Button
                                              
                                              color="primary"
                                              onClick={async () => {
                                                const res =
                                                  await getPrePublicationById({
                                                    prePublication_id: info.id,
                                                  });
                                                  let baseURL = `/admin/prePublication/update-Prepublication/${info.id}?page=${page}`;
                                                  const url = getUrl(baseURL);  
                                                  navigate(
                                                    url,
                                                    { state: { row: res?.data } }
                                                  );
                                              }}>
                                              {isLoading && (
                                                <span
                                                  className="indicator-progress"
                                                  style={{ display: "block" }}>
                                                  Please Wait..
                                                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                                </span>
                                              )}
                                              {!isLoading && (
                                                <span className="indicator-label">
                                                  Edit
                                                </span>
                                              )}
                                            </Button>
                                          </td>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={13}>
                                No Result Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="py-4">
              <Paginationes
                  className="pagination justify-content-end mb-0"
                  current={page}
                  pageSize={per_Page}
                  total={totalPages}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                />
                {/* <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0">
                  <PaginationLink className={"p-0 " + (page !== 1 ? 'active-button' : '')} disabled={page === 1}>
                    <PaginationItem
                      aria-label="Previous"
                      disabled={page === 1}
                      onClick={prevPage}>
                      <span aria-hidden="true">&laquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                  <PaginationLink>
                    {" "}
                    <PaginationItem>{page}</PaginationItem>
                  </PaginationLink>
                  <PaginationLink className={"p-0 " + (page !== totalPages ? 'active-button' : '')} disabled={page === totalPages}>
                    <PaginationItem
                      aria-label="Next"
                      disabled={page === totalPages}
                      onClick={nextPage}>
                      <span aria-hidden="true">&raquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                </Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {loader}
    </>
  );
}

export default PrePublicationList;
