import Header from "components/Headers/Header";
import { Formik, ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { updateUser, getRoleList,googleAuthenticatorUnlink } from "utils/endpoint";
import Select from "react-select";
import * as Yup from "yup";
import UseFullPageLoader from "utils/UseFullPageLoader";
import clsx from "clsx";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from "antd";
import useSearchParams from "utils/useSearchParams";

const mobileNoRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobile_no: Yup.string()
    .required("Mobile number is required")
    .matches(mobileNoRegExp, "Mobile number is not valid"),
    password: Yup.string()
    .min(8, 'Must be exactly 8 digits'),
    confirm_password: Yup
    .string()
    .min(8, 'Must be exactly 8 digits')
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
   

});

function UpdateUser() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [userQueryParams ,setUserQueryParams] = useSearchParams();
  const currentPage = userQueryParams.currentPage;
  const fromDate = userQueryParams.fromDate;
  const toDate = userQueryParams.toDate;
  const firstName = userQueryParams.firstName;
  const lastName = userQueryParams.lastName;
  const email = userQueryParams.email;
  const is_active = userQueryParams.is_active;
  const [user] = useState(state.row.userInfo);
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [selectedRole] = useState(state.row.selectedRole);
  const params = useParams();
  const { id } = params;
  const { confirm } = Modal;

  const handleUnlinkGoogleAuthenticator = async()=>{
    try{
     const response = await googleAuthenticatorUnlink(id);
     if(response && response.isSuccess){
      toast.success(`${response.message}`);
     }
     navigate("/admin/user/UserList");
    }catch(error){
      setLoading(false);
    }
  }

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure, you want to unlink google authenticator for this user?",
      icon: <ExclamationCircleFilled />,
      okText: "Unlink",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleUnlinkGoogleAuthenticator()
      },
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getRoleList();
        if (response && response.isSuccess === true) {
          setRoleList(response.data);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [])

  const handleSearchParams = () => {
    let url = `/admin/user/UserList?id=${id}&page=${currentPage}`;
    if(firstName) url+= `&first_name=${firstName}`
    if(lastName) url+= `&last_name=${lastName}`
    if(fromDate) url+= `&from_date=${fromDate}`
    if(toDate) url+= `&to_date=${toDate}`
    if(email) url += `&email=${email}`
    if(is_active || is_active === "false") url += `&is_active=${is_active === "true" ? "true" : is_active === "none" ? "none" :"false"}`;
    return url;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        mobile_no: user?.mobile_no,
        is_active: user?.is_active ?? "",
        role_id: selectedRole.id,
        password:"",
        confirm_password: "",

      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader();
        const updateData = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          mobile_no: values.mobile_no,
          french_publisher_id: values.french_publisher_id,
          french_release_date: values.french_release_date,
          link: values.link,
          comment: values.comment,
          role_id: values.role_id,
          is_active: values.is_active,
         password:values.password,
          confirm_password: values.confirm_password,
        };
        const finalObj = {};
        Object.keys(updateData).forEach(function (key) {
          if (typeof updateData[key] === "string" && updateData[key]) {
            finalObj[key] = updateData[key];
          }
          if (typeof updateData[key] === "boolean") {
            finalObj[key] = updateData[key];
          }
          if (
            typeof updateData[key] === "object" &&
            updateData[key].length > 0
          ) {
            finalObj[key] = updateData[key];
          }
          if (typeof updateData[key] === "number" && updateData[key]) {
            finalObj[key] = updateData[key];
          }
        });

        const result = await updateUser(id, finalObj);
        if (result?.isSuccess) {
          toast.success(`${result.message}`);
          setLoading(false);
          hideLoader();
          resetForm();
          const url =  handleSearchParams()
          navigate(url);
        } else {
          setSubmitting(false);
          hideLoader();
          setLoading(false);
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />

            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Update User</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="danger"
                            className=""
                            onClick={showDeleteConfirm}
                            >
                            <span>Unlink Google Authenticator</span>
                          </Button>
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              const url =  handleSearchParams();
                              navigate(url);
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                     
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              First Name
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.first_name) &&
                                  touched.first_name,
                              })}
                              id="first_name"
                              name="first_name"
                              placeholder="First Name"
                              type="text"
                              value={values.first_name}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="first_name" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              Last Name
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.last_name) &&
                                  touched.last_name,
                              })}
                              id="last_name"
                              name="last_name"
                              placeholder="Last Name"
                              type="text"
                              value={values.last_name}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="last_name" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              Email
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.email) && touched.email,
                              })}
                              id="email"
                              name="email"
                              placeholder="Email"
                              type="text"
                              value={values.email}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="email" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              Mobile Number
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.mobile_no) &&
                                  touched.mobile_no,
                              })}
                              id="mobile_no"
                              name="mobile_no"
                              placeholder="Mobile Number"
                              type="text"
                              value={values.mobile_no}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="mobile_no" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              Role
                            </label>
                            <Select
                              name="role_id"
                              id="role_id"
                              defaultValue={{
                                label: selectedRole?.name,
                                value: selectedRole?.id,
                              }}
                              options={Array.from(roleList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("role_id", event.value);
                              }}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="role_id" />
                            </div>
                          </FormGroup>
                        </Col>
                       
                        <Col lg="3">
                      <FormGroup>
                        <label className="form-control-label">
                         Password
                        </label>
                        <Input
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.password) && touched.password,
                        })}
                          id="password"
                          name="password"
                          placeholder="Password"
                          type="password"
                          // value={values.password}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="password" />
                    </div>
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup>
                        <label className="form-control-label ">
                        Confirm Password
                        </label>
                        <Input
                         className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.confirm_password) &&
                            touched.confirm_password,
                        })}
                          id="confirm_password"
                          name="confirm_password"
                          placeholder="Confirm Password"
                          type="password"
                          value={values.confirm_password}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="confirm_password" />
                    </div>
                      </FormGroup>
                    </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary">
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Update User
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
}

export default UpdateUser;
