import { Modal } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getAuthorSummaryById, getUniverseSummaryById } from "./endpoint";
import { toast } from "react-toastify";
import pageReloadOptions from "./enums";

export default function LightNovelModal({
  openLightNovelModel,
  setOpenLightNovelModel,
  lightNovelSummaryData,
  permissions,
  currentAuthorId,
  currentUniverseId,
  isPageReload,
  isLinkFunctionalityActive = false
}) {

  const navigate = useNavigate()

  const handleRedirectToViewAuthorPage = async (authorId) => {
    try {
      const res = await getAuthorSummaryById(authorId);
      let url = `/admin/author/view-author/${authorId}?redirect=1`

      if(isPageReload === pageReloadOptions.AUTHOR){
        setOpenLightNovelModel(false);
        localStorage.setItem('is_same_author_view_page',true);
        url = `/admin/author/view-author/${authorId}?redirect=1`
      }

      navigate(url, {
        state: { row: res?.data },
      });
    } catch (error) {
      toast.error(`${error.message}`);
    }
  }

  const handleRedirectToViewUniversePage = async (unvierseId) => {
    try {
      const res = await getUniverseSummaryById(unvierseId);
      let url = `/admin/universe/view-universe/${unvierseId}?redirect=1`

      if(isPageReload === pageReloadOptions.UNIVERSE){
        setOpenLightNovelModel(false);
        localStorage.setItem('is_same_universe_view_page',true);
        url = `/admin/universe/view-universe/${unvierseId}`
      }

      navigate(url, {
        state: { row: res?.data },
      });
    } catch (error) {
      toast.error(`${error.message}`);
    }
  }

  return (
    <Modal
      title="Light Novel Detail"
      centered
      open={openLightNovelModel}
      onOk={() => setOpenLightNovelModel(false)}
      onCancel={() => setOpenLightNovelModel(false)}
      okButtonProps={{ className:"model-bg-color" }}
      cancelButtonProps={{ className:"model-border-color" }}  
    >
      <Card>
        <Row>
          <Col lg="12">
            <Card className="shadow m-4 detail_card">
              <CardBody className="custom-margin-top">
                <Row>
                  {lightNovelSummaryData?.en_title ? (
                    <>
                      <Col lg="4"> EN Title</Col>
                      <Col lg="8">
                        <div className="text-dark h4">
                          {lightNovelSummaryData.en_title}
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="mt-1">
                  {lightNovelSummaryData?.universe_name ? (
                    <>
                      <Col lg="4">Universe Name</Col>
                      <Col lg="8" >
                        {!isLinkFunctionalityActive ? 
                          <span  className="text-dark">
                              {lightNovelSummaryData?.universe_name}
                           </span>
                           : 
                           currentUniverseId === lightNovelSummaryData.universe_id ?
                            <span  className="text-dark">
                                {lightNovelSummaryData?.universe_name}
                            </span> 
                           : 
                            <span className="primary-text-color" style={{cursor: "pointer"}}  onClick={() =>
                              handleRedirectToViewUniversePage(lightNovelSummaryData.universe_id)
                            }>
                              {lightNovelSummaryData?.universe_name}
                            </span>
                          }
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="mt-1">
                  {lightNovelSummaryData?.jp_title ? (
                    <>
                      <Col lg="4">JP Title</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelSummaryData?.jp_title}
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                {lightNovelSummaryData?.status_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Status</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelSummaryData?.status_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.publisher_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP publisher</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelSummaryData?.publisher_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                {lightNovelSummaryData?.pre_publication_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Pre Publication</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelSummaryData?.pre_publication_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.no_of_volumes ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">No. of vol</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelSummaryData?.no_of_volumes}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.ongoing ||
                !lightNovelSummaryData?.ongoing && lightNovelSummaryData?.ongoing !== null ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Ongoing</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelSummaryData.ongoing === true
                            ? "Yes"
                            : "No"}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.type_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Type</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelSummaryData?.type_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.print_rights_in_france ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Print Rights In France</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            lightNovelSummaryData?.print_rights_in_france_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.digital_rights_in_france ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Digital Rights In France</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            lightNovelSummaryData?.digital_rights_in_france_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.print_rights_in_us ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Print Rights In US</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            lightNovelSummaryData?.print_rights_in_us_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.digital_rights_in_us ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Digital Rights In US</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            lightNovelSummaryData?.digital_rights_in_us_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.print_rights_in_de ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Print Rights In DE</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            lightNovelSummaryData?.print_rights_in_de_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.digital_rights_in_de ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Digital Rights In DE</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            lightNovelSummaryData?.digital_rights_in_de_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.jp_print_run_1 ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Print Run 1</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelSummaryData?.jp_print_run_1}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.jp_print_run_2 ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Print Run 2</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelSummaryData?.jp_print_run_2}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.jp_print_run_3 ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Print Run 3</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelSummaryData?.jp_print_run_3}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.jp_print_run_4 ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Print Run 4</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelSummaryData?.jp_print_run_4}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.jp_wikipedia_link ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Wikipedia</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap" >
                          <a
                            href={lightNovelSummaryData?.jp_wikipedia_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lightNovelSummaryData?.jp_wikipedia_link?.length >
                            50
                              ? `${lightNovelSummaryData?.jp_wikipedia_link.slice(
                                  0,
                                  40
                                )}...`
                              : lightNovelSummaryData?.jp_wikipedia_link}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.nautiljon ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Nautiljon</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={lightNovelSummaryData?.nautiljon}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lightNovelSummaryData?.nautiljon?.length > 50
                              ? `${lightNovelSummaryData?.nautiljon.slice(
                                  0,
                                  40
                                )}...`
                              : lightNovelSummaryData?.nautiljon}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.jp_amazon_link ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Amazon</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={lightNovelSummaryData?.jp_amazon_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lightNovelSummaryData?.jp_amazon_link?.length > 50
                              ? `${lightNovelSummaryData?.jp_amazon_link.slice(
                                  0,
                                  40
                                )}...`
                              : lightNovelSummaryData?.jp_amazon_link}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelSummaryData?.jp_bookwalker_link ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP BookWalker</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={lightNovelSummaryData?.jp_bookwalker_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lightNovelSummaryData?.jp_bookwalker_link?.length >
                            50
                              ? `${lightNovelSummaryData?.jp_bookwalker_link.slice(
                                  0,
                                  40
                                )}...`
                              : lightNovelSummaryData?.jp_bookwalker_link}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                {lightNovelSummaryData?.jp_raw ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Raw</Col>
                      <Col lg="8">
                      <span className="text-dark">
                          {lightNovelSummaryData?.jp_raw}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                {permissions &&
                  permissions.map((permission) =>
                    permission === "view-light-novel-price" ? (
                      <>
                        {lightNovelSummaryData?.low_sales ? (
                          <>
                            <Row className="mt-1">
                              <Col lg="4">Low Sales</Col>
                              <Col lg="8">
                                <span className="text-dark">
                                  {lightNovelSummaryData?.low_sales}
                                </span>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}

                        {lightNovelSummaryData?.medium_sales ? (
                          <>
                            <Row className="mt-1">
                              <Col lg="4">Medium Sales</Col>
                              <Col lg="8">
                                <span className="text-dark">
                                  {lightNovelSummaryData?.medium_sales}
                                </span>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}

                        {lightNovelSummaryData?.high_sales ? (
                          <>
                            <Row className="mt-1">
                              <Col lg="4">High Sales</Col>
                              <Col lg="8">
                                <span className="text-dark">
                                  {lightNovelSummaryData?.high_sales}
                                </span>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}

                        {lightNovelSummaryData?.fr_consumer_price ? (
                          <>
                            <Row className="mt-1">
                              <Col lg="4">FR Consumer Price</Col>
                              <Col lg="8">
                                <span className="text-dark">
                                  {lightNovelSummaryData?.fr_consumer_price}
                                </span>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )
                  )}

                {lightNovelSummaryData?.summary ? (
                  <>
                    <Row className="mt-21">
                      {lightNovelSummaryData?.summary ? (
                        <>
                          <Col lg="4">Summary</Col>
                          <Col lg="8">
                            <span className="text-dark">
                              {lightNovelSummaryData?.summary}
                            </span>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {permissions &&
                      permissions.map((permission) =>
                        permission === "view-light-novel-product-pl" ? (
                          <>
                              {lightNovelSummaryData?.product_pl ? (
                                <>
                                <Row className="mt-1">
                                  <Col lg="4">Product P&L</Col>
                                  <Col lg="8">
                                      <span className="link primary-text-color text-wrap">
                                          <a
                                            href={lightNovelSummaryData?.product_pl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {lightNovelSummaryData?.product_pl?.length >
                                            50
                                              ? `${lightNovelSummaryData?.product_pl.slice(
                                                  0,
                                                  40
                                                )}...`
                                              : lightNovelSummaryData?.product_pl}
                                          </a>
                                      </span>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )
                )}

                {lightNovelSummaryData?.created_at ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">CreatedAt</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelSummaryData.created_at
                            ? moment(lightNovelSummaryData.created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : ""}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          {lightNovelSummaryData?.authors?.length ? (
            <Col>
              <Card className=" shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Authors</h3>
                  <Col>
                    {lightNovelSummaryData.authors.map((author, index) => (
                      <ul>
                        {!isLinkFunctionalityActive ? 
                         <li key={author.id}>{author.name}</li> : currentAuthorId === author.id ?  <li key={author.id}>{author.name}</li> : <li className="primary-text-color" style={{cursor: "pointer"}} onClick={()=>handleRedirectToViewAuthorPage(author.id)} key={author.id}>{author.name}</li> 
                        }
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}

          {lightNovelSummaryData?.genres?.length ? (
            <Col lg="12">
              <Card className="me-2 shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Genre</h3>
                  <Col>
                    {lightNovelSummaryData.genres.map((genre, index) => (
                      <ul>
                        <li key={index}>{genre.name}</li>
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
          {lightNovelSummaryData?.illustrators?.length ? (
            <Col lg="12">
              <Card className=" shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Illustrator</h3>
                  <Col>
                    {lightNovelSummaryData.illustrators.map(
                      (illustrators, index) => (
                        <ul>
                          <li key={index}>{illustrators.name}</li>
                        </ul>
                      )
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}

          {lightNovelSummaryData?.publics?.length ? (
            <Col lg="12">
              <Card className="me-2 shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Public</h3>
                  <Col>
                    {lightNovelSummaryData.publics.map((publicsD, index) => (
                      <ul>
                        <li key={index}>{publicsD.name}</li>
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Card>
    </Modal>
  );
}
