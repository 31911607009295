import moment from "moment";
import React, { forwardRef } from "react";
import { Card, CardBody, CardHeader, CardImg, Col, Row } from "reactstrap";

const LightNovelPreview = forwardRef(({ lightNovelPreviewData }, ref) => {
  const currentDateTime = moment().format("YYYY-MM-DD, h:mm:ss");

  return (
    <div ref={ref}>
      <Card style={{padding:'30px'}}>
        <h3 className="text-right mx-5 text-dark mt-4">{currentDateTime}</h3>
        <Row>
          <Col lg="8">
            <Card className="shadow m-2 detail_card">
              <CardBody className="custom-margin-top">
                <Row>
                  {lightNovelPreviewData?.en_title ? (
                    <>
                      <Col lg="4"> EN Title</Col>
                      <Col lg="8">
                        <div className="text-dark h4">
                          {lightNovelPreviewData.en_title}
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="mt-1">
                  {lightNovelPreviewData?.universe_name ? (
                    <>
                      <Col lg="4">Universe Name</Col>
                      <Col lg="8">
                        <span className="primary-text-color">
                          {lightNovelPreviewData?.universe_name}
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="mt-1">
                  {lightNovelPreviewData?.jp_title ? (
                    <>
                      <Col lg="4">JP Title</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.jp_title}
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                {lightNovelPreviewData?.status_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Status</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.status_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.publisher_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP publisher</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.publisher_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                {lightNovelPreviewData?.pre_publication_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Pre Publication</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.pre_publication_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.no_of_volumes ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">No. of vol</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.no_of_volumes}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.ongoing !== undefined ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Ongoing</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData.ongoing === true
                            ? "Yes"
                            : "No"}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.type_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Type</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.type_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.print_rights_in_france_publisher_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Print Rights In France</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            lightNovelPreviewData?.print_rights_in_france_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.digital_rights_in_france_publisher_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Digital Rights In France</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            lightNovelPreviewData?.digital_rights_in_france_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.print_rights_in_us_publisher_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Print Rights In US</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            lightNovelPreviewData?.print_rights_in_us_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.digital_rights_in_us_publisher_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Digital Rights In US</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            lightNovelPreviewData?.digital_rights_in_us_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.print_rights_in_de_publisher_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Print Rights In DE</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            lightNovelPreviewData?.print_rights_in_de_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.digital_rights_in_de_publisher_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Digital Rights In DE</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            lightNovelPreviewData?.digital_rights_in_de_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.jp_print_run_1 ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Print Run 1</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.jp_print_run_1}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.jp_print_run_2 ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Print Run 2</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.jp_print_run_2}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.jp_print_run_3 ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Print Run 3</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.jp_print_run_3}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.jp_print_run_4 ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Print Run 4</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.jp_print_run_4}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.jp_wikipedia_link ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Wikipedia</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={lightNovelPreviewData?.jp_wikipedia_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lightNovelPreviewData?.jp_wikipedia_link?.length >
                            50
                              ? `${lightNovelPreviewData?.jp_wikipedia_link.slice(
                                  0,
                                  40
                                )}...`
                              : lightNovelPreviewData?.jp_wikipedia_link}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.jp_raw ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Raw</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.jp_raw}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.scantrad ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">ScanTrad</Col>
                      <Col lg="8">
                        <span className="link primary-text-color">
                          <a
                            href={lightNovelPreviewData?.scantrad}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lightNovelPreviewData?.scantrad?.length > 50
                              ? `${lightNovelPreviewData?.scantrad.slice(
                                  0,
                                  40
                                )}...`
                              : lightNovelPreviewData?.scantrad}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.nautiljon ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Nautiljon</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={lightNovelPreviewData?.nautiljon}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lightNovelPreviewData?.nautiljon?.length > 50
                              ? `${lightNovelPreviewData?.nautiljon.slice(
                                  0,
                                  40
                                )}...`
                              : lightNovelPreviewData?.nautiljon}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.jp_amazon_link ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Amazon</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={lightNovelPreviewData?.jp_amazon_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lightNovelPreviewData?.jp_amazon_link?.length > 50
                              ? `${lightNovelPreviewData?.jp_amazon_link.slice(
                                  0,
                                  40
                                )}...`
                              : lightNovelPreviewData?.jp_amazon_link}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.jp_bookwalker_link ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP BookWalker</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={lightNovelPreviewData?.jp_bookwalker_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lightNovelPreviewData?.jp_bookwalker_link?.length >
                            50
                              ? `${lightNovelPreviewData?.jp_bookwalker_link.slice(
                                  0,
                                  40
                                )}...`
                              : lightNovelPreviewData?.jp_bookwalker_link}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.low_sales ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Low Sales</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.low_sales}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.medium_sales ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Medium Sales</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.medium_sales}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.high_sales ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">High Sales</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.high_sales}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.fr_consumer_price ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">FR Consumer Price</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData?.fr_consumer_price}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.summary ? (
                  <>
                    <Row className="mt-21">
                      {lightNovelPreviewData?.summary ? (
                        <>
                          <Col lg="4">Summary</Col>
                          <Col lg="8">
                            <span className="text-dark">
                              {lightNovelPreviewData?.summary}
                            </span>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.product_pl ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Product P&L</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={lightNovelPreviewData?.product_pl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lightNovelPreviewData?.product_pl?.length > 50
                              ? `${lightNovelPreviewData?.product_pl.slice(
                                  0,
                                  40
                                )}...`
                              : lightNovelPreviewData?.product_pl}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {lightNovelPreviewData?.created_at ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">CreatedAt</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {lightNovelPreviewData.created_at
                            ? moment(lightNovelPreviewData.created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : ""}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>

          <Col lg="4">
            <Card className="text-right shadow m-2 view_cards">
              <CardBody>
                {lightNovelPreviewData?.cover_image ? (
                  <>
                    <CardImg
                      src={lightNovelPreviewData.cover_image}
                      alt="Upload"
                      className="overflow-hidden"
                      style={{ width: '5cm', height: '7cm', objectFit: 'cover',marginRight:'37px' }}
                    />
                  </>
                ) : (
                  <CardImg
                    src={require("../assets/images/no-image.png")}
                    alt="Upload"
                    className=" overflow-hidden"
                    style={{ width: '5cm', height: '7cm', objectFit: 'cover',marginRight:'37px' }}
                  />
                )}
              </CardBody>
            </Card>
            {lightNovelPreviewData?.rating ||
            lightNovelPreviewData?.rating === null ? (
              <Card className="text-left shadow m-2 view_cards">
                <CardBody>
                  <h4>Ratings</h4>

                  <div className="d-inline-flex">
                    <div>
                      <img
                        src={`${process.env.PUBLIC_URL}/rating-images/1.jpg`}
                        alt="rating-1"
                         style={{ width: "20%" }}
                      />
                      <span className="m-1">
                        {lightNovelPreviewData?.rating_one_counts || 0}
                      </span>
                    </div>

                    <div className="pe-auto">
                      <img
                        src={`${process.env.PUBLIC_URL}/rating-images/2.jpg`}
                        alt="rating-2"
                         style={{ width: "20%" }}
                      />
                      <span className="m-1">
                        {lightNovelPreviewData?.rating_two_counts || 0}
                      </span>
                    </div>

                    <div>
                      <img
                        src={`${process.env.PUBLIC_URL}/rating-images/3.jpg`}
                        alt="rating-3"
                         style={{ width: "17%" }}
                      />
                      <span className="m-2">
                        {lightNovelPreviewData?.rating_three_counts || 0}
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          {lightNovelPreviewData?.authors?.length ? (
            <Col lg="3">
              <Card className="shadow m-2 view_cards">
                <CardBody style={{ paddingTop: 10, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                  <h3 className="fw-bolder dot_border">Authors</h3>
                  <Col style={{ padding: 0, margin: 0, lineHeight: 0.5 }}>
                    {lightNovelPreviewData.authors.map((author, index) => (
                      <ul style={{ paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                        <li key={author.id}>{author.name}</li>
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}

          {lightNovelPreviewData?.genres?.length ? (
            <Col lg="3">
              <Card className="shadow me-2 m-2 view_cards">
                <CardBody style={{ paddingTop: 10, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                  <h3 className="fw-bolder dot_border">Genre</h3>
                  <Col style={{ padding: 0, margin: 0, lineHeight: 0.5 }}>
                    {lightNovelPreviewData.genres.map((genre, index) => (
                      <ul style={{ paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                        <li key={index}>{genre.name}</li>
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
          {lightNovelPreviewData?.illustrators?.length ? (
            <Col lg="3">
              <Card className="shadow me-2 m-2 view_cards">
                <CardBody style={{ paddingTop: 10, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                  <h3 className="fw-bolder dot_border">Illustrator</h3>
                  <Col style={{ padding: 0, margin: 0, lineHeight: 0.5 }}>
                    {lightNovelPreviewData.illustrators.map(
                      (illustrators, index) => (
                        <ul style={{ paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                          <li key={index}>{illustrators.name}</li>
                        </ul>
                      )
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}

          {lightNovelPreviewData?.publics?.length ? (
            <Col lg="3">
              <Card className="shadow m-2 view_cards">
                <CardBody style={{ paddingTop: 10, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                  <h3 className="fw-bolder dot_border">Public</h3>
                  <Col style={{ padding: 0, margin: 0, lineHeight: 0.5 }}>
                    {lightNovelPreviewData.publics.map((publicsD, index) => (
                      <ul style={{ paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                        <li key={index}>{publicsD.name}</li>
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          {lightNovelPreviewData.comment !== '' ||
          lightNovelPreviewData.all_comments.length !== 0 ? (
            <Col lg="12">
              <Card className="me-2 m-2 shadow">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Comments</h3>
                  <>
                    <Row>
                      {lightNovelPreviewData.comment ? (
                        <Col lg="3">
                          <Card className="shadow mt-1 ">
                            <CardHeader className="h4" style={{ padding: 10 }}>
                              {lightNovelPreviewData.user_first_name}{" "}
                              {lightNovelPreviewData.user_last_name}
                            </CardHeader>
                            <CardBody style={{ padding: 10 }}>{lightNovelPreviewData.comment}</CardBody>
                          </Card>
                        </Col>
                      ) : (
                        ""
                      )}
                      {lightNovelPreviewData?.all_comments?.length > 0
                        ? lightNovelPreviewData.all_comments.map((x) => (
                            <>
                              <Col lg="3">
                                <Card className="shadow mt-1 ">
                                  <CardHeader className="h4" style={{ padding: 10 }}>
                                    {x.first_name} {x.last_name}
                                  </CardHeader>
                                  <CardBody style={{ padding: 10 }}>{x.comment}</CardBody>
                                </Card>
                              </Col>
                            </>
                          ))
                        : ""}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Card>
    </div>
  );
});

export default LightNovelPreview;
