import Header from "components/Headers/Header";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import clsx from "clsx";
import "react-datetime/css/react-datetime.css";
import { updatePrePublication } from "utils/endpoint";
import { get_type_list } from "utils/Commen";
import UseFullPageLoader from "utils/UseFullPageLoader";
import useSearchParams from "utils/useSearchParams";

const validationSchema = yup.object({
  en_title: yup.string().required("EN Title is required"),
});

const UpdatePrePublication = () => {
  const { state } = useLocation();
  const [prePublicationQueryParams ,setPrePublicationQueryParams] = useSearchParams();
  const currentPage = prePublicationQueryParams.currentPage;
  const fromDate = prePublicationQueryParams.fromDate;
  const toDate = prePublicationQueryParams.toDate;
  const enTitle = prePublicationQueryParams.enTitle;
  const label = prePublicationQueryParams.label;
  const company = prePublicationQueryParams.company;
  const is_active = prePublicationQueryParams.is_active;
  const typeIdsArray = prePublicationQueryParams.typeIdsArray;

  const [typeList, setTypeList] = useState([]);
  const [prePublication] = useState(state.row);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [loader, showLoader, hideLoader] =UseFullPageLoader();
  const { id } = params;

  const getTypeList = async () => {
    const allTYpe = await get_type_list();
    setTypeList([{ id: "", name: "None" }, ...allTYpe]);
  };

  useEffect(() => {
    getTypeList();
  }, [id]);


  const handleSearchParams = () => {
    let url = `/admin/prePublication/Prepublication-list?id=${id}&page=${currentPage}`;
    if(enTitle) url+= `&en_title=${enTitle}`
    if(fromDate) url+= `&from_date=${fromDate}`
    if(toDate) url+= `&to_date=${toDate}`
    if(label) url += `&label=${label}`
    if(company) url+= `&company=${company}`
    if(typeIdsArray && typeIdsArray.length > 0) url+= `&type_id=${typeIdsArray.join(',')}`

    if(is_active || is_active === "false") url += `&is_active=${is_active === "true" ? "true" : is_active === "none" ? "none" :"false"}`;
    return url;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        en_title: prePublication?.en_title,
        jp_title: prePublication?.jp_title,
        label: prePublication?.label,
        company: prePublication?.company,
        type: prePublication?.type !== null ? prePublication?.type : "None",
        link: prePublication?.link,
        is_active: prePublication?.is_active,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader()
        const updateData = {
          en_title: values.en_title,
          jp_title: values.jp_title,
          label: values.label,
          company: values.company,
          type: values.type !== 'None' ? values.type : null,
          link: values.link,
          is_active: values.is_active,
        };

        const result = await updatePrePublication(id, updateData);
        if (result?.isSuccess) {
          toast.success(`${result.message}`);
          setLoading(false);
          hideLoader()
          resetForm();
          const url =  handleSearchParams()
          navigate(url);
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader()
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />
            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Update Pre-Publication</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              const url =  handleSearchParams()
                              navigate(url);
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                     
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              EN Title
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.en_title) && touched.en_title,
                              })}
                              id="en_title"
                              name="en_title"
                              placeholder="EN Title"
                              type="text"
                              value={values.en_title}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="en_title" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Japanese Title
                            </label>
                            <Input
                              className="form-control"
                              id="jp_title"
                              name="jp_title"
                              placeholder="Japanese Title"
                              type="text"
                              value={values.jp_title == null ? '' : values.jp_title }
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Label</label>
                            <Input
                              className="form-control"
                              id="label"
                              name="label"
                              placeholder="Label"
                              type="text"
                              value={values.label === null ? '' : values.label }

                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Company
                            </label>
                            <Input
                              className="form-control"
                              id="company"
                              name="company"
                              placeholder="Company"
                              type="text"
                              value={values.company === null ? '' : values.company }
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Type</label>
                            <Select
                              defaultValue={{
                                label: values.type,
                                value: values.type_id,
                              }}
                              name="type"
                              id="type"
                              className="react-select"
                              options={Array.from(typeList).map((value) => {
                                return {
                                      value: value.id,
                                      label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("type", event.label);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Link</label>
                            <Input
                              className="form-control"
                              id="link"
                              name="link"
                              placeholder="Link"
                              type="text"
                              value={values.link === null ? '' : values.link }

                              // value={values.link}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary">
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Update Pre-Publication
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
};

export default UpdatePrePublication;
