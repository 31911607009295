import React, { useRef } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams,Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import clsx from "clsx";
import Header from "components/Headers/Header";
import { UUIDv4 } from "uuid-v4-validator";
import { ErrorMessage, Formik } from "formik";
import { updateWebtoon } from "utils/endpoint";
import {
  get_universes_list,
  get_status_list,
  get_author_list,
  get_Illustrator_list,
  get_type_list,
  get_genre_list,
  get_public_list,
} from "utils/Commen";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { get_User_list } from "utils/Commen";
import { Modal } from "antd";
import { deleteWebtoonComment } from "utils/endpoint";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { getWebtoonById } from "utils/endpoint";
import { RiDeleteBin6Line } from "react-icons/ri";
import CreateUniverses from "utils/CreateUniverses";
import { onGoing } from "utils/enums";
import useSearchParams from "utils/useSearchParams";
import Checkbox from '@mui/material/Checkbox';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { handleWebtoonBookMark } from "./WebtoonList";
import ImageModal from "../../../utils/ImageModal";
import { CSVLink } from "react-csv";
import { genrateCsvFileName } from "utils/Commen";
import { getData, headers } from "./ViewWebtoon";
import { verifyUniqueWebtoonJPTitle } from "utils/endpoint";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const validationSchema = yup.object({
  en_title: yup.string().required("EN Title is required"),
});



const UpdateWebtoon = () => {
  const { state } = useLocation();
  const [webtoonQueryParams ,setWebtoonQueryParams] = useSearchParams();
  const currentPage = webtoonQueryParams.currentPage;
  const enTitle = webtoonQueryParams.enTitle;
  const jpTitle = webtoonQueryParams.jpTitle;
  const jpRaw = webtoonQueryParams.jpRaw;
  const fromDate = webtoonQueryParams.fromDate;
  const toDate = webtoonQueryParams.toDate;
  const userIdString = webtoonQueryParams.userIdString;
  const statusIdString = webtoonQueryParams.statusIdString;
  const typeIdString = webtoonQueryParams.typeIdString;
  const is_bookmark = webtoonQueryParams.is_bookmark;
  const redirect = webtoonQueryParams.redirect == null || undefined ? 2 : webtoonQueryParams.redirect;
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [universeList, setUniverseList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [authorList, setAuthorList] = useState([]);
  const [illustratorList, setIllustratorList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [genreList, setGenreList] = useState([]);
  const [publicList, setPublicList] = useState([]);
  const [flagList, setFlagList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = React.useState(
    state.row.authors
  );
  const [selectedIllustrator, setSelectedIllustrator] = React.useState(
    state.row.illustrators
  );
  const [selectedPublicTargeted, setSelectedPublicTargeted] = React.useState(state.row.publics);
  const [selectedGenre, setSelectedGenre] = React.useState(state.row.genres);
  const [webtoon] = useState(state.row);
  const [currentComment, setCurrentComment] = useState(state.row.comment);
  const [show, setShowModal] = useState(false);
  const [showImageModel, setShowImageModal] = useState(false);
  const [image, setImage] = useState("");
  const params = useParams();
  const { id } = params;
  const inputRef = useRef(null);
  const [resultImages, setResultImages] = useState({});
  const [csvData,setCsvData] = useState([])
  const [selectedRating, setSelectedRating] = useState('');
  const [isJpTitleUnique, setIsJpTitleUnique] = useState(null);
  const [jPTitle,setJPTitle] = useState("")

  const navigate = useNavigate();
  const loginData = localStorage.getItem("User");
  const loginUser = JSON.parse(loginData);
  const getUniverseList = async () => {
    const allUniverses = await get_universes_list();
    setUniverseList([{ id: "", name: "None" }, ...allUniverses]);
  };
  const getStatusList = async () => {
    const allStatus = await get_status_list();
    setStatusList([{ id: "", name: "None" }, ...allStatus]);
  };

  const getAuthorList = async () => {
    const allAuthors = await get_author_list();
    setAuthorList(allAuthors);
  };
  const getIllustratorList = async () => {
    const illustrator = await get_Illustrator_list();
    setIllustratorList(illustrator);
  };
  const getTypeList = async () => {
    const allTYpe = await get_type_list();
    setTypeList([{ id: "", name: "None" }, ...allTYpe]);
  };
  const getGenreList = async () => {
    const allGenre = await get_genre_list();
    setGenreList(allGenre);
  };
  const getPublicList = async () => {
    const allPublic = await get_public_list();
    setPublicList(allPublic);
  };
  const getFlagList = async () => {
    const allFlag = await get_User_list();
    setFlagList(allFlag);
  };

  const { confirm } = Modal;
  const webtoonId = state.row.id;

  const handleDeleteWebtoonComment = async () => {
    try {
      inputRef.current.value = '';
      const response = await deleteWebtoonComment(webtoonId);
      if (response.isSuccess) {
        toast.success(`${response.message}`);
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this comment ?",
      icon: <ExclamationCircleFilled />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleDeleteWebtoonComment();
      },
    });
  };


  useEffect(() => {
    getUniverseList();
    getStatusList();
    getPublicList();
    getAuthorList();
    getIllustratorList();
    getTypeList();
    getGenreList();
    getFlagList();
  }, [id]);

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
    const data = getData(webtoon)
    setCsvData(data)
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await getWebtoonById({
          webtoon_id: webtoonId,
        });
        if(response && response.isSuccess){
          setCurrentComment(response.data.comment)
          setSelectedRating(response?.data?.rating?.rating)
        }
      } catch (error) {
        toast.error(`${error.message}`);
      }
    })();
  }, []);

  const author = webtoon.authors?.map(function (element) {
    return {
      value: element.id,
      label: element.name,
    };
  });
  const illustrator = webtoon?.illustrators?.map((e) => {
    return {
      value: e.id,
      label: e.name,
    };
  });
  const genre = webtoon.genres?.map((e) => {
    return {
      value: e.id,
      label: e.name,
    };
  });
  const publics = webtoon?.publics?.map((e) => {
    return {
      value: e.id,
      label: e.name,
    };
  });
  const flags = webtoon?.flag_to_users?.map((e) => {
    return {
      value: e.user_id,
      label: e.first_name,
    };
  });

  const handleSearchParams = () => {
    let url = `/admin/webtoon/webtoon-list?id=${id}`;
    if(currentPage) url+=`&page=${currentPage}`;
    if(enTitle) url+= `&en_title=${enTitle}`
    if(jpTitle) url+= `&jp_title=${jpTitle}`
    if(jpRaw) url+= `&jp_raw=${jpRaw}`
    if(fromDate) url+= `&from_date=${fromDate}`
    if(toDate) url+= `&to_date=${toDate}`
    if(userIdString) url+= `&user_id=${userIdString}`
    if(typeIdString) url += `&type_id=${typeIdString}`
    if(statusIdString) url += `&status_id=${statusIdString}`    
    if(is_bookmark || is_bookmark === "false" || is_bookmark === "none") url += `&is_bookmark=${is_bookmark === "true" ? "true" : is_bookmark === "none" ? "none" :"false"}`;
    return url;
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        const baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    for (let i = 0; i < selectedFilesArray.length; i++) {
      const name = selectedFilesArray[i].name;

      getBase64(selectedFilesArray[i])
        .then((result) => {
          setResultImages({ file_name: name, image_data: result });
        })
        .catch((err) => {});
    }
    event.target.value = "";
  };

  const checkUniqueJPTitle = async () => {
    if(!jPTitle) return;
    const response = await verifyUniqueWebtoonJPTitle({jp_title:jPTitle});
    if(response){
      setJPTitle(response?.data?.jp_title);
      setIsJpTitleUnique(response.isSuccess)
    }    
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        en_title: webtoon?.en_title ?? "",
        universe_id: webtoon?.universe_id ?? "",
        universe_name: webtoon?.universe_name ?? "None",
        status_id: webtoon?.status_id ?? "",
        status_name: webtoon?.status_name ?? "None",
        jp_title: webtoon?.jp_title ?? "",
        kr_title: webtoon?.kr_title ?? "",
        no_of_volumes: webtoon?.no_of_volumes ?? "",
        ongoing: webtoon?.ongoing !== "none" ? webtoon?.ongoing : "None",
        type_id: webtoon?.type_id ?? "",
        type_name: webtoon?.type_name ?? "None",
        jp_platform: webtoon?.jp_platform ?? "",
        kr_platform: webtoon?.kr_platform ?? "",
        us_platform: webtoon?.us_platform ?? "",
        fr_platform: webtoon?.fr_platform ?? "",
        jp_platform_likes: webtoon?.jp_platform_likes ?? "",
        kr_platform_likes: webtoon?.kr_platform_likes ?? "",
        us_platform_likes: webtoon?.us_platform_likes ?? "",
        fr_platform_likes: webtoon?.fr_platform_likes ?? "",
        jp_wikipedia_link: webtoon?.jp_wikipedia_link ?? "",
        kr_wikipedia_link: webtoon?.kr_wikipedia_link ?? "",
        jp_raw: webtoon?.jp_raw ?? "",
        scantrad: webtoon?.scantrad ?? "",
        low_sales: webtoon?.low_sales ?? "",
        medium_sales: webtoon?.medium_sales ?? "",
        high_sales: webtoon?.high_sales ?? "",
        fr_consumer_price: webtoon?.fr_consumer_price ?? "",
        comment: currentComment ?? "",
        is_active: webtoon?.is_active ?? "",
        authors: webtoon?.authors ?? "",
        illustrators: webtoon?.illustrators ?? "",
        genres: webtoon?.genres ?? "",
        publics: webtoon?.publics ?? "",
        pre_publication_name: webtoon?.pre_publication_name ?? "",
        flag_users: webtoon?.flag_to_users?.map((e) => e.user_id),
        rating: webtoon?.rating?.rating ?? "",
        image: resultImages,
        product_pl: webtoon?.product_pl ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader();
        const updateData = {
          en_title: values.en_title,
          universe_id: values.universe_id,
          status_id: values.status_id,
          jp_title: values.jp_title,
          kr_title: values.kr_title,
          no_of_volumes: values.no_of_volumes,
          ongoing: values.ongoing,
          type_id: values.type_id,
          jp_platform: values.jp_platform,
          kr_platform: values.kr_platform,
          us_platform: values.us_platform,
          fr_platform: values.fr_platform,
          jp_platform_likes: values.jp_platform_likes,
          kr_platform_likes: values.kr_platform_likes,
          us_platform_likes: values.us_platform_likes,
          fr_platform_likes: values.fr_platform_likes,

          jp_wikipedia_link: values.jp_wikipedia_link,
          kr_wikipedia_link: values.kr_wikipedia_link,
          jp_raw: values.jp_raw,
          scantrad: values.scantrad,
          low_sales: values.low_sales,
          medium_sales: values.medium_sales,
          high_sales: values.high_sales,
          fr_consumer_price: values.fr_consumer_price,
          comment: values.comment,
          is_active: values.is_active,
          author_ids: values.author_ids,
          illustrator_ids: values.illustrator_ids,
          new_authors: values.new_authors,
          new_illustrators: values.new_illustrators,
          genre_ids: values.genre_ids,
          public_ids: values.public_ids,
          flag_users: values.flag_users,
          rating: values.rating,
          product_pl: values.product_pl,
        };

        const finalObj = {};
        Object.keys(updateData).forEach(function (key) {
          if (typeof updateData[key] === "string" && updateData[key]) {
            finalObj[key] = updateData[key];
          }
          if (typeof updateData[key] === "number" && updateData[key]) {
            finalObj[key] = updateData[key];
          }
          if (typeof updateData[key] === "boolean") {
            finalObj[key] = updateData[key];
          }
          if (
            typeof updateData[key] === "object"
          ) {
            finalObj[key] = updateData[key];
          }
        });

        if (resultImages) {
          finalObj.image = resultImages;
        }
        
        finalObj.universe_id = finalObj?.universe_id ? finalObj?.universe_id : null;
        finalObj.status_id = finalObj?.status_id ? finalObj?.status_id : null;
        finalObj.type_id = finalObj?.type_id ? finalObj?.type_id : null;
        finalObj.ongoing = finalObj?.ongoing !== "none" ? finalObj?.ongoing : null;

        const result = await updateWebtoon(id, finalObj);

        if (result?.isSuccess) {
          toast.success("Webtoon Updated successfully");
          setLoading(false);
          hideLoader();
          resetForm();
          const url =  handleSearchParams()
          navigate(url);
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader();
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />

            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Update Webtoon
                        <Checkbox
                            {...label}
                            icon={<BookmarkBorderIcon />}
                            checkedIcon={<BookmarkIcon />}
                            defaultChecked={webtoon?.bookmark_id !== null ? true : false}
                            onClick={(e)=>handleWebtoonBookMark({isChecked:e.target.checked,webtoonId:webtoon?.id})}
                          />
                        </h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                        <CSVLink
                          data={csvData}
                          headers={headers}
                          filename={genrateCsvFileName(`webtoon_${webtoon.en_title}`)}
                          className="btn btn-primary"
                          target="_blank"
                         >
                            Export to CSV
                          </CSVLink>
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              const isSameWebtoonViewPage = localStorage.getItem('is_same_webtoon_view_page');
                              
                              if(isSameWebtoonViewPage == 'true'){
                                navigate(-1)
                              }

                              if(redirect == 1){
                                navigate(-1);
                              }

                              if(redirect == 2){
                                const url =  handleSearchParams()
                                navigate(url);
                              }
                              
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                      
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              EN Title ({webtoon?.update_history?.en_title})
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.en_title) && touched.en_title,
                              })}
                              id="en_title"
                              name="en_title"
                              placeholder="EN Title"
                              type="text"
                              value={values.en_title}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="en_title" />
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <div className="d-flex justify-content-between">
                            <label className="form-control-label">
                              Universe ({webtoon?.update_history?.universe_id})
                            </label>
                            <Button
                              className="btn-sm ml-10 text-right"
                              color="primary"
                              onClick={async () => {
                                setShowModal(true);
                              }}>
                                +
                            </Button>
                            </div>
                            <Select
                              defaultValue={{
                                label: values.universe_name,
                                value: values.universe_id,
                              }}
                              name="universe_id"
                              id="universe_id"
                              className="react-select mt-1"
                              classNamePrefix="my-react-select"
                              options={Array.from(universeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };  
                              })}
                              onChange={(event) => {
                                setFieldValue("universe_id", event.value);
                              }}
                            />                        
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Status ({webtoon?.update_history?.status_id})
                            </label>
                            <Select
                              defaultValue={{
                                label: values.status_name,
                                value: values.status_id,
                              }}
                              name="status_id"
                              id="status_id"
                              className="react-select"
                              options={Array.from(statusList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("status_id", event.value);
                              }}
                              isSearchable={true}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <div className="d-flex justify-content-between">
                              <label className="form-control-label">
                                Cover ({webtoon?.update_history?.cover_image})
                              </label>
                              <Button
                                className="btn-sm ml-10 text-right"
                                color="primary"
                                onClick={async () => {
                                  setShowImageModal(true);
                                  setImage(webtoon?.cover_image);
                                }}
                              >
                                <i className="fa fa-eye"></i>
                              </Button>
                            </div>

                            <div className="form-control fs-1 w-100 mt-1">
                              <label
                                className="fs-6 mb-0 text-muted"
                                style={{ cursor: "pointer" }}
                              >
                                Click here to upload images{" "}
                                <i
                                  className="fa fa-upload"
                                  aria-hidden="true"
                                ></i>
                                <Input
                                  id="image"
                                  type="file"
                                  accept="image/png , image/jpeg, image/webp"
                                  name="image"
                                  className="form-control d-none"
                                  onChange={onSelectFile}
                                />
                              </label>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Title ({webtoon?.update_history?.jp_title})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_title"
                              name="jp_title"
                              placeholder="JP Title"
                              type="text"
                              value={values.jp_title}
                              onBlur={()=> checkUniqueJPTitle()}
                              onChange={(event) => {
                                setJPTitle(event.target.value);
                                setFieldValue("jp_title", event.target.value);
                              }}
                            />
                             {isJpTitleUnique === false &&
                              <div className="text-danger text-align-top-center mt-1">
                                <span>Might be this title already exists, Please <Link to={`/admin/webtoon/webtoon-list?jp_title=${jPTitle}`}>'click here'</Link> to view label.</span>
                              </div>
                             }
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              KR Title ({webtoon?.update_history?.kr_title})
                            </label>
                            <Input
                              className="form-control"
                              id="kr_title"
                              name="kr_title"
                              placeholder="KR  Title"
                              type="text"
                              value={values.kr_title}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Author(s) ({webtoon?.update_history?.author_ids})
                            </label>
                            <CreatableSelect
                              defaultValue={author}
                              isMulti
                              isValidNewOption={() => true}
                              id="author_ids"
                              name="author_ids"
                              options={Array.from(authorList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              isOptionDisabled={() =>
                                selectedAuthors.length >= 3
                              }
                              onChange={(event) => {
                                const selectedAuthors = event.map(
                                  (e) => e.value
                                );
                                const existingAuthors = [];
                                const newAuthors = [];
                                selectedAuthors?.map((x) => {
                                  if (UUIDv4.validate(x)) {
                                    existingAuthors.push(x);
                                  } else {
                                    newAuthors.push(x);
                                  }
                                });
                                setFieldValue("author_ids", existingAuthors);
                                setFieldValue("new_authors", newAuthors);
                                setSelectedAuthors(selectedAuthors);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Illustrator (
                              {webtoon?.update_history?.illustrator_ids})
                            </label>
                            <CreatableSelect
                              defaultValue={illustrator}
                              isMulti
                              id="illustrator_ids"
                              name="illustrator_ids"
                              options={Array.from(illustratorList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              isOptionDisabled={() =>
                                selectedIllustrator.length >= 3
                              }
                              onChange={(event) => {
                                const selectedIllustrator = event.map(
                                  (e) => e.value
                                );
                                const existingIllustrator = [];
                                const newIllustrator = [];
                                selectedIllustrator.map((x) => {
                                  if (UUIDv4.validate(x)) {
                                    existingIllustrator.push(x);
                                  } else {
                                    newIllustrator.push(x);
                                  }
                                });
                                setFieldValue(
                                  "illustrator_ids",
                                  existingIllustrator
                                );
                                setFieldValue(
                                  "new_illustrators",
                                  newIllustrator
                                );
                                setSelectedIllustrator(selectedIllustrator);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              No. of chpt (
                              {webtoon?.update_history?.no_of_volumes})
                            </label>
                            <Input
                              className="form-control"
                              id="no_of_volumes"
                              name="no_of_volumes"
                              placeholder=" No. of chpt"
                              type="number"
                              value={values.no_of_volumes}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              OnGoing ({webtoon?.update_history?.ongoing})
                            </label>
                            <Select
                              defaultValue={{
                                label:
                                  values.ongoing === true
                                    ? "Yes"
                                    : values.ongoing === null
                                    ? "None"
                                    : "No",
                              }}
                              name="ongoing"
                              id="ongoing"
                              className=""
                              options={Array.from(onGoing).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("ongoing", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Type ({webtoon?.update_history?.type_id})
                            </label>
                            <Select
                              defaultValue={{
                                label: values.type_name,
                                value: values.type_id,
                              }}
                              name="type_id"
                              id="type_id"
                              className="react-select"
                              options={Array.from(typeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("type_id", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Genre ({webtoon?.update_history?.genre_ids})
                            </label>
                            <Select
                              defaultValue={genre}
                              isMulti
                              name="genre_ids"
                              id="genre_ids"
                              className="select"
                              options={Array.from(genreList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              isOptionDisabled={() => selectedGenre.length >= 3}
                              onChange={(event) => {
                                const selectedGenre = event.map((e) => e.value);
                                const existingGenre = [];
                                selectedGenre.map((x) => {
                                  if (UUIDv4.validate(x)) {
                                    existingGenre.push(x);
                                  }
                                });
                                setFieldValue("genre_ids", existingGenre);
                                setSelectedGenre(selectedGenre);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Public Targeted (
                              {webtoon?.update_history?.public_ids})
                            </label>
                            <Select
                              isMulti
                              defaultValue={publics}
                              name="public_ids"
                              id="public_ids"
                              className="react-select"
                              options={Array.from(publicList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              isOptionDisabled={() => selectedPublicTargeted.length >= 3}
                              onChange={(event) => {
                                const selectedPublicTargeted = event.map((e) => e.value);
                                const existingPublicTargeted = [];
                                selectedPublicTargeted.map((x) => {
                                  if (UUIDv4.validate(x)) {
                                    existingPublicTargeted.push(x);
                                  }
                                });
                                setFieldValue("public_ids", existingPublicTargeted);
                                setSelectedPublicTargeted(selectedPublicTargeted);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Platform (
                              {webtoon?.update_history?.jp_platform})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_platform"
                              name="jp_platform"
                              placeholder="JP Platform"
                              type="text"
                              value={values.jp_platform}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              KR Platform (
                              {webtoon?.update_history?.kr_platform})
                            </label>
                            <Input
                              className="form-control"
                              id=" kr_platform"
                              name="kr_platform"
                              placeholder="KR Platform"
                              type="text"
                              value={values.kr_platform}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              US Platform (
                              {webtoon?.update_history?.us_platform})
                            </label>
                            <Input
                              className="form-control"
                              id="us_platform"
                              name="us_platform"
                              placeholder="US Platform"
                              type="text"
                              value={values.us_platform}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              FR Platform (
                              {webtoon?.update_history?.fr_platform})
                            </label>
                            <Input
                              className="form-control"
                              id="fr_platform"
                              name="fr_platform"
                              placeholder="US Platform"
                              type="text"
                              value={values.fr_platform}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP platform likes / views / subscribers (
                              {webtoon?.update_history?.jp_platform_likes})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_platform_likes"
                              name="jp_platform_likes"
                              placeholder="JP platform likes / views / subscribers"
                              type="number"
                              value={values.jp_platform_likes}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              KR platform likes / views / subscribers (
                              {webtoon?.update_history?.kr_platform_likes})
                            </label>
                            <Input
                              className="form-control"
                              id="kr_platform_likes"
                              name="kr_platform_likes"
                              placeholder="KR platform likes / views / subscribers"
                              type="number"
                              value={values.kr_platform_likes}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              US platform likes / views / subscribers (
                              {webtoon?.update_history?.us_platform_likes})
                            </label>
                            <Input
                              className="form-control"
                              id="us_platform_likes"
                              name="us_platform_likes"
                              placeholder="US platform likes / views / subscribers"
                              type="number"
                              value={values.us_platform_likes}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              FR platform likes / views / subscribers (
                              {webtoon?.update_history?.fr_platform_likes})
                            </label>
                            <Input
                              className="form-control"
                              id="fr_platform_likes"
                              name="fr_platform_likes"
                              placeholder="FR platform likes / views / subscribers"
                              type="number"
                              value={values.fr_platform_likes}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      
                        {permissions &&
                          permissions.map((permission) =>
                            permission === "update-webtoon-low-sale" ? (
                              <Col lg="3">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Low Sales (
                                    {webtoon?.update_history?.low_sales})
                                  </label>
                                  <Input
                                    className="form-control"
                                    id="low_sales"
                                    name="low_sales"
                                    placeholder="Low Sales"
                                    type="number"
                                    value={values.low_sales}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </Col>
                          ) : (
                            ""
                          )
                        )}

                        {permissions &&
                          permissions.map((permission) =>
                            permission === "update-webtoon-medium-sale" ? (
                              <Col lg="3">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Medium Sales (
                                    {webtoon?.update_history?.medium_sales})
                                  </label>
                                  <Input
                                    className="form-control"
                                    id="medium_sales"
                                    name="medium_sales"
                                    placeholder="Medium Sales"
                                    type="number"
                                    value={values.medium_sales}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </Col>
                          ) : (
                            ""
                          )
                        )}

                        {permissions &&
                          permissions.map((permission) =>
                            permission === "update-webtoon-high-sale" ? (
                              <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  High Sales (
                                  {webtoon?.update_history?.high_sales})
                                </label>
                                <Input
                                  className="form-control"
                                  id="high_sales"
                                  name="high_sales"
                                  placeholder="High Sales"
                                  type="number"
                                  value={values.high_sales}
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                          ) : (
                            ""
                          )
                        )}

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              FR consumer price (
                              {
                                webtoon?.update_history
                                  ?.fr_consumer_price
                              }
                              )
                            </label>
                            <Input
                              className="form-control"
                              id="fr_consumer_price"
                              name="fr_consumer_price"
                              placeholder="FR consumer price"
                              type="number"
                              value={values.fr_consumer_price}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>


                        {permissions &&
                          permissions.map((permission) =>
                            permission === "update-webtoon-product-pl" ? (
                              <Col lg="3">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Product P&L (
                                    {webtoon?.update_history?.product_pl})
                                  </label>
                                  <Input
                                    className="form-control"
                                    id="product_pl"
                                    name="product_pl"
                                    placeholder="Product P&L"
                                    type="text"
                                    value={values.product_pl}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            ) : (
                              ""
                            )
                        )}

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Wikipedia (
                              {webtoon?.update_history?.jp_wikipedia_link})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_wikipedia_link"
                              name="jp_wikipedia_link"
                              placeholder="JP Wikipedia"
                              type="text"
                              value={values.jp_wikipedia_link}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              KR Wikipedia (
                              {webtoon?.update_history?.kr_wikipedia_link})
                            </label>
                            <Input
                              className="form-control"
                              id="kr_wikipedia_link"
                              name="kr_wikipedia_link"
                              placeholder="KR Wikipedia"
                              type="text"
                              value={values.kr_wikipedia_link}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Raw (
                              {webtoon?.update_history?.jp_raw})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_raw"
                              name="jp_raw"
                              placeholder="JP Raw"
                              type="text"
                              value={values.jp_raw}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              ScanTrad (
                              {webtoon?.update_history?.scantrad})
                            </label>
                            <Input
                              className="form-control"
                              id="scantrad"
                              name="scantrad"
                              placeholder="ScanTrad"
                              type="text"
                              value={values.scantrad}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Flag</label>
                            <Select
                              isMulti
                              defaultValue={flags}
                              name="flag_users"
                              id="flag_users"
                              className="select"
                              options={Array.from(flagList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.first_name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue(
                                  "flag_users",
                                  event?.map((e) => e.value)
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Comments ({loginUser.user?.first_name}{" "}
                              {loginUser.user?.last_name})
                              {values.comment &&  <RiDeleteBin6Line
                                  onClick={showDeleteConfirm}
                                  className="ml-19"
                                  style={{ cursor: "pointer" }}
                                  size={26}
                                /> }
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="comments..."
                              type="text"
                              name="comment"
                              id="comment"
                              value={values.comment}
                              onChange={(event) => {
                                if(event.target.value.trim() !== ''){
                                  setFieldValue(
                                    "comment",
                                    event.target.value
                                  );
                                }else {
                                  setFieldValue('comment','');
                                }
                              }}
                              rows={2}
                              cols={40}
                              ref={inputRef}
                            ></textarea>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <div className="d-flex justify-content-between mb-1">
                              <label className="form-control-label">
                                Rating ({loginUser.user?.first_name}{" "}
                              {loginUser.user?.last_name})
                              </label>
                              <Button
                                className="btn-sm ml-10 text-right"
                                color="primary"
                                onClick={async () => {
                                  setFieldValue("rating", "");
                                  setSelectedRating('');
                                }}
                              >
                                <i className="fa fa-refresh"></i>
                              </Button>
                            </div>
                            <div style={{display: 'flex', border: '1px solid #cad1d7', borderRadius:'0.375rem'}} className="pl-2 pt-2">
                              <div className="mr-2">
                                <label>
                                  <input
                                    type="radio"
                                    value="1"
                                    name="rating"
                                    checked={selectedRating == '1'}
                                    onChange={(event) => {
                                      setFieldValue("rating", event.target.value);
                                      setSelectedRating('1');
                                    }}
                                    className="mr-2"
                                  />
                                  <img src={`${process.env.PUBLIC_URL}/rating-images/1.jpg`} alt="rating-1" height="30%" width="30%" />
                                </label>
                              </div>
                              <div className="mr-2">
                                <label>
                                  <input
                                    type="radio"
                                    value="2"
                                    name="rating"
                                    checked={selectedRating == '2'}
                                    onChange={(event) => {
                                      setFieldValue("rating", event.target.value);
                                      setSelectedRating('2');
                                    }}
                                    className="mr-2"
                                  />
                                  <img src={`${process.env.PUBLIC_URL}/rating-images/2.jpg`} alt="rating-2" height="30%" width="30%" />
                                </label>
                              </div>
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    value="3"
                                    name="rating"
                                    checked={selectedRating == '3'}
                                    onChange={(event) => {
                                      setFieldValue("rating", event.target.value);
                                      setSelectedRating('3');
                                    }}
                                    className="mr-2"
                                  />
                                  <img src={`${process.env.PUBLIC_URL}/rating-images/3.jpg`} alt="rating-3" height="27%" width="27%" />
                                </label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <label className="form-control-label">
                            All Comments
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        {webtoon?.all_comments?.length > 0 ? (
                          webtoon.all_comments?.map((x) => (
                            <>
                              <Col lg="3">
                                <li className="fs-5 text-dark">
                                  {x.first_name} {x.last_name}
                                </li>

                                <textarea
                                  className="form-control"
                                  type="text"
                                  name="all_comments"
                                  value={x.comment}
                                  onChange={handleChange}
                                  disabled
                                  rows={2}
                                  cols={40}
                                ></textarea>
                              </Col>
                            </>
                          ))
                        ) : (
                          <>
                            <div>No Comments</div>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary"
                          >
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Update Webtoon
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
              <ImageModal
                show={showImageModel}
                image={image}
                onClose={() => {
                  setShowImageModal(false);
                }}
              />
              <CreateUniverses
                show={show}
                mediaType={"WEBTOON"}
                onClose={() => {
                  getUniverseList();
                  setShowModal(false);
                }}
              />
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
};

export default UpdateWebtoon;