import { ErrorMessage, Formik } from "formik";
import React, { useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import clsx from "clsx";
import Header from "components/Headers/Header";
import { updateManga, deleteMangaComment } from "utils/endpoint";
import CreatableSelect from "react-select/creatable";
import { UUIDv4 } from "uuid-v4-validator";
import {
  get_universes_list,
  get_status_list,
  get_publisher_list,
  get_author_list,
  get_Illustrator_list,
  get_type_list,
  get_genre_list,
  get_public_list,
  get_PrePublication_list,
} from "utils/Commen";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { get_User_list } from "utils/Commen";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { getMangaById } from "utils/endpoint";
import CreateUniverses from "utils/CreateUniverses";
import { onGoing } from "utils/enums";
import useSearchParams from "utils/useSearchParams";
import Checkbox from '@mui/material/Checkbox';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { handleMangaBookMark } from "./MangaList";
import ImageModal from "../../../utils/ImageModal";
import { CSVLink } from "react-csv";
import { genrateCsvFileName } from "utils/Commen";
import { getData, headers } from "./ViewManga";
import { verifyUniqueMangaJPTitle } from "utils/endpoint";

const validationSchema = yup.object({
  en_title: yup.string().required("EN Title is required"),
});

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const UpdateManga = () => {
  const { state } = useLocation();
  const [mangaQueryParams ,setMangaQueryParams] = useSearchParams();
  const currentPage = mangaQueryParams.currentPage;
  const enTitle = mangaQueryParams.enTitle;
  const jpTitle = mangaQueryParams.jpTitle;
  const jpRaw = mangaQueryParams.jpRaw;
  const fromDate = mangaQueryParams.fromDate;
  const toDate = mangaQueryParams.toDate;
  const userIdString = mangaQueryParams.userIdString;
  const statusIdString = mangaQueryParams.statusIdString;
  const publisherIdString = mangaQueryParams.publisherIdString;
  const typeIdString = mangaQueryParams.typeIdString;
  const prePublicationIdString = mangaQueryParams.prePublicationIdString;
  const is_bookmark = mangaQueryParams.is_bookmark;
  const redirect = mangaQueryParams.redirect == null || undefined ? 2 : mangaQueryParams.redirect;
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [universeList, setUniverseList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);
  const [frPublisherList, setFrPublisherList] = useState([]);
  const [usPublisherList, setUsPublisherList] = useState([]);
  const [dePublisherList, setDePublisherList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [resultImages, setResultImages] = useState({});
  const [loading, setLoading] = useState(false);
  const [authorList, setAuthorList] = useState([]);
  const [illustratorList, setIllustratorList] = useState([]);
  const [prePublicationList, setPrePublicationList] = useState([]);
  const [genreList, setGenreList] = useState([]);
  const [publicList, setPublicList] = useState([]);
  const [flagList, setFlagList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = React.useState(
    state.row.authors
  );
  const [selectedPublicTargeted, setSelectedPublicTargeted] = React.useState(state.row.publics);
  const [selectedIllustrator, setSelectedIllustrator] = React.useState(
    state.row.illustrators
  );
  const [selectedGenre, setSelectedGenre] = React.useState(state.row.genres);
  const [manga] = useState(state.row);
  const [currentComment, setCurrentComment] = useState(state.row.comment);
  const [show, setShowModal] = useState(false);
  const [showImageModel, setShowImageModal] = useState(false);
  const [image, setImage] = useState("");
  const [csvData,setCsvData] = useState([])

  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const inputRef = useRef(null);
  const [selectedRating, setSelectedRating] = useState('');
  const [isJpTitleUnique, setIsJpTitleUnique] = useState(null);
  const [jPTitle,setJPTitle] = useState("")

  const loginData = localStorage.getItem("User");
  const loginUser = JSON.parse(loginData);

  const getUniverseList = async () => {
    const allUniverses = await get_universes_list();
    setUniverseList([{ id: "", name: "None" }, ...allUniverses]);
  };

  const getStatusList = async () => {
    const allStatus = await get_status_list();
    setStatusList([{ id: "", name: "None" }, ...allStatus]);
  };
  const getPublisherList = async () => {
    const allPublisher = await get_publisher_list();
    const jpPublisherData = allPublisher.filter((publisher) => publisher.publisher_type == 'JP')
    const frPublisherData = allPublisher.filter((publisher) => publisher.publisher_type == 'FR')
    const usPublisherData = allPublisher.filter((publisher) => publisher.publisher_type == 'US')
    const dePublisherData = allPublisher.filter((publisher) => publisher.publisher_type == 'DE')
    setPublisherList([{ id: "", name: "None" }, ...jpPublisherData]);
    setFrPublisherList([{ id: "", name: "None" }, ...frPublisherData]);
    setUsPublisherList([{ id: "", name: "None" }, ...usPublisherData]);
    setDePublisherList([{ id: "", name: "None" }, ...dePublisherData]);
  };

  const getTypeList = async () => {
    const allTYpe = await get_type_list();
    setTypeList([{ id: "", name: "None" }, ...allTYpe]);
  };
  const getAuthorList = async () => {
    const allAuthors = await get_author_list();
    setAuthorList(allAuthors);
  };
  const getIllustratorList = async () => {
    const illustrator = await get_Illustrator_list();
    setIllustratorList(illustrator);
  };

  const getGenreList = async () => {
    const allGenre = await get_genre_list();
    setGenreList(allGenre);
  };
  const getPublicList = async () => {
    const allPublic = await get_public_list();
    setPublicList(allPublic);
  };
  const getPrePublicationList = async () => {
    const allPrePublication = await get_PrePublication_list();
    setPrePublicationList([{ id: "", en_title: "None" }, ...allPrePublication]);
  };
  const getFlagList = async () => {
    const allFlag = await get_User_list();
    setFlagList(allFlag);
  };

  const { confirm } = Modal;

  const handleDeleteMangaComment = async () => {
    try {
      inputRef.current.value = '';
      const response = await deleteMangaComment(id);
      if (response.isSuccess) {
        toast.success(`${response.message}`);
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getMangaById({
          manga_id: id,
        });
        if(response && response.isSuccess){
          setCurrentComment(response.data.comment)
          setSelectedRating(response?.data?.rating?.rating)
        }
      } catch (error) {
        toast.error(`${error.message}`);
      }
    })();
  }, []);

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this comment ?",
      icon: <ExclamationCircleFilled />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleDeleteMangaComment();
      },
    });
  };

  const handleSearchParams = () => {
    let url = `/admin/manga/manga-list?id=${id}`;
    if(currentPage) url+=`&page=${currentPage}`;
    if(enTitle) url+= `&en_title=${enTitle}`
    if(jpTitle) url+= `&jp_title=${jpTitle}`
    if(jpRaw) url+= `&jp_raw=${jpRaw}`
    if(fromDate) url+= `&from_date=${fromDate}`
    if(toDate) url+= `&to_date=${toDate}`
    if(prePublicationIdString) url += `&pre_publication_id=${prePublicationIdString}`
    if(userIdString) url+= `&user_id=${userIdString}`
    if(publisherIdString) url += `&publisher_id=${publisherIdString}`
    if(typeIdString) url += `&type_id=${typeIdString}`
    if(statusIdString) url += `&status_id=${statusIdString}`    
    if(is_bookmark || is_bookmark === "false" || is_bookmark === "none") url += `&is_bookmark=${is_bookmark === "true" ? "true" : is_bookmark === "none" ? "none" :"false"}`;
    return url;
  }

  useEffect(() => {
    getUniverseList();
    getStatusList();
    getPublisherList();
    getAuthorList();
    getIllustratorList();
    getTypeList();
    getGenreList();
    getPublicList();
    getPrePublicationList();
    getFlagList();
  }, [id]);

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
    const data = getData(manga)
    setCsvData(data)
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        const baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    for (let i = 0; i < selectedFilesArray.length; i++) {
      const name = selectedFilesArray[i].name;
      getBase64(selectedFilesArray[i])
        .then((result) => {
          setResultImages({ file_name: name, image_data: result });
        })
        .catch((err) => {});
    }
    event.target.value = "";
  };
  const author = manga.authors.map(function (element) {
    return {
      value: element.id,
      label: element.name,
    };
  });
  const illustrator = manga?.illustrators.map((e) => {
    return {
      value: e.id,
      label: e.name,
    };
  });
  const genre = manga.genres.map((e) => {
    return {
      value: e.id,
      label: e.name,
    };
  });
  const publics = manga?.publics.map((e) => {
    return {
      value: e.id,
      label: e.name,
    };
  });
  const flags = manga?.flag_to_users?.map((e) => {
    return {
      value: e.user_id,
      label: e.first_name,
    };
  });

  const checkUniqueJPTitle = async () => {
    if(!jPTitle) return;
    const response = await verifyUniqueMangaJPTitle({jp_title:jPTitle});
    if(response){
      setJPTitle(response?.data?.jp_title);
      setIsJpTitleUnique(response.isSuccess)
    }    
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        en_title: manga?.en_title ?? "",
        universe_id: manga?.universe_id ?? "",
        universe_name: manga?.universe_name ?? "None",
        status_id: manga?.status_id ?? "",
        jp_label: manga?.jp_label ?? "",
        pre_publication_id: manga?.pre_publication_id ?? "",
        status_name: manga?.status_name ?? "None",
        jp_title: manga?.jp_title ?? "",
        publisher_id: manga?.publisher_id ?? "",
        no_of_volumes: manga?.no_of_volumes ?? "",
        ongoing: manga?.ongoing !== "none" ? manga?.ongoing : "None",
        type_id: manga?.type_id ?? "",
        type_name: manga?.type_name ?? "None",
        print_rights_in_france: manga?.print_rights_in_france ?? "",
        digital_rights_in_france: manga?.digital_rights_in_france ?? "",
        print_rights_in_us: manga?.print_rights_in_us ?? "",
        digital_rights_in_us: manga?.digital_rights_in_us ?? "",
        print_rights_in_de: manga?.print_rights_in_de ?? "",
        digital_rights_in_de: manga?.digital_rights_in_de ?? "",
        jp_print_run_1: manga?.jp_print_run_1 ?? "",
        jp_print_run_2: manga?.jp_print_run_2 ?? "",
        jp_print_run_3: manga?.jp_print_run_3 ?? "",
        jp_print_run_4: manga?.jp_print_run_4 ?? "",
        jp_wikipedia_link: manga?.jp_wikipedia_link ?? "",
        nautiljon: manga?.nautiljon ?? "",
        jp_amazon_link: manga?.jp_amazon_link ?? "",
        jp_raw: manga?.jp_raw ?? "",
        scantrad: manga?.scantrad ?? "",
        jp_bookwalker_link: manga?.jp_bookwalker_link ?? "",
        low_sales: manga?.low_sales ?? "",
        medium_sales: manga?.medium_sales ?? "",
        high_sales: manga?.high_sales ?? "",
        fr_consumer_price: manga?.fr_consumer_price ?? "",
        comment: currentComment ?? "",
        is_active: manga?.is_active ?? "",
        authors: manga?.authors ?? "",
        illustrators: manga?.illustrators ?? "",
        genres: manga?.genres ?? "",
        publics: manga?.publics ?? "",
        pre_publication_name: manga?.pre_publication_name ?? "None",
        image: [],
        summary: manga?.summary ?? "",
        flag_users: manga?.flag_to_users?.map((e) => e.user_id),
        rating: manga?.rating?.rating ?? "",
        product_pl: manga?.product_pl ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader();
        const updateData = {
          en_title: values.en_title,
          universe_id: values.universe_id,
          status_id: values.status_id,
          jp_title: values.jp_title,
          publisher_id: values.publisher_id,
          jp_label: values.jp_label,
          pre_publication_id: values.pre_publication_id,
          no_of_volumes: values.no_of_volumes,
          ongoing: values.ongoing,
          type_id: values.type_id,
          print_rights_in_france: values.print_rights_in_france,
          digital_rights_in_france: values.digital_rights_in_france,
          print_rights_in_us: values.print_rights_in_us,
          digital_rights_in_us: values.digital_rights_in_us,
          print_rights_in_de: values.print_rights_in_de,
          digital_rights_in_de: values.digital_rights_in_de,
          jp_print_run_1: values.jp_print_run_1,
          jp_print_run_2: values.jp_print_run_2,
          jp_print_run_3: values.jp_print_run_3,
          jp_print_run_4: values.jp_print_run_4,
          jp_wikipedia_link: values.jp_wikipedia_link,
          nautiljon: values.nautiljon,
          jp_amazon_link: values.jp_amazon_link,
          scantrad: values.scantrad,
          jp_raw: values.jp_raw,
          jp_bookwalker_link: values.jp_bookwalker_link,
          low_sales: values.low_sales,
          medium_sales: values.medium_sales,
          high_sales: values.high_sales,
          fr_consumer_price: values.fr_consumer_price,
          comment: values.comment,
          is_active: values.is_active,
          author_ids: values.author_ids,
          illustrator_ids: values.illustrator_ids,
          new_authors: values.new_authors,
          new_illustrators: values.new_illustrators,
          genre_ids: values.genre_ids,
          public_ids: values.public_ids,
          image: resultImages,
          summary: values.summary,
          flag_users: values.flag_users,
          rating: values.rating,
          product_pl: values.product_pl,
        };

        const finalObj = {};
        Object.keys(updateData).forEach(function (key) {
          if (typeof updateData[key] === "string" && updateData[key]) {
            finalObj[key] = updateData[key];
          }
          if (typeof updateData[key] === "boolean") {
            finalObj[key] = updateData[key];
          }
          if (
            typeof updateData[key] === "object"
          ) {
            finalObj[key] = updateData[key];
          }
          if (typeof updateData[key] === "number" && updateData[key]) {
            finalObj[key] = updateData[key];
          }
        });
        if (resultImages) {
          finalObj.image = resultImages;
        }
        finalObj.universe_id = finalObj?.universe_id ? finalObj?.universe_id : null;
        finalObj.status_id = finalObj?.status_id ? finalObj?.status_id : null;
        finalObj.publisher_id = finalObj?.publisher_id ? finalObj?.publisher_id : null;
        finalObj.pre_publication_id = finalObj?.pre_publication_id ? finalObj?.pre_publication_id : null;
        finalObj.type_id = finalObj?.type_id ? finalObj?.type_id : null;
        finalObj.print_rights_in_france = finalObj?.print_rights_in_france ? finalObj?.print_rights_in_france : null;
        finalObj.digital_rights_in_france = finalObj?.digital_rights_in_france ? finalObj?.digital_rights_in_france : null;
        finalObj.print_rights_in_us = finalObj?.print_rights_in_us ? finalObj?.print_rights_in_us : null;
        finalObj.digital_rights_in_us = finalObj?.digital_rights_in_us ? finalObj?.digital_rights_in_us : null;
        finalObj.print_rights_in_de = finalObj?.print_rights_in_de ? finalObj?.print_rights_in_de : null;
        finalObj.digital_rights_in_de = finalObj?.digital_rights_in_de ? finalObj?.digital_rights_in_de : null;
        finalObj.ongoing = finalObj?.ongoing !== "none" ? finalObj?.ongoing : null;
        
        const result = await updateManga(id, finalObj);
        if (result?.isSuccess) {
          toast.success("Manga Updated successfully");
          setLoading(false);
          hideLoader();
          resetForm();
          const url =  handleSearchParams()
          navigate(url);
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader();
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />

            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                          <h1 className="mb-0">Update Manga
                          <Checkbox
                              {...label}
                              icon={<BookmarkBorderIcon />}
                              checkedIcon={<BookmarkIcon />}
                              defaultChecked={manga?.bookmark_id !== null ? true : false}
                              onClick={(e)=>handleMangaBookMark({isChecked:e.target.checked,mangaId:manga?.id})}
                          />
                          </h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <CSVLink
                            data={csvData}
                            headers={headers}
                            filename={genrateCsvFileName(`manga_${manga.en_title}`)}
                            className="btn btn-primary"
                            target="_blank"
                          >
                            Export to CSV
                          </CSVLink>

                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              const isSameMangaViewPage = localStorage.getItem('is_same_manga_view_page');

                              if(isSameMangaViewPage == 'true'){
                                navigate(-1)
                              }
                              if(redirect == 1){
                                navigate(-1)
                              }

                              if(redirect == 2){
                                const url =  handleSearchParams()
                                navigate(url);
                              }
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              EN Title ({manga?.update_history?.en_title})
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.en_title) && touched.en_title,
                              })}
                              id="en_title"
                              name="en_title"
                              placeholder="EN Title"
                              type="text"
                              value={values.en_title}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="en_title" />
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <div className="d-flex justify-content-between">
                            <label className="form-control-label">
                              Universe ({manga?.update_history?.universe_id})
                            </label>
                            <Button
                              className="btn-sm ml-10 text-right"
                              color="primary"
                              onClick={async () => {
                                setShowModal(true);
                              }}>
                                +
                            </Button>
                            </div>
                            <Select
                              defaultValue={{
                                label: values.universe_name,
                                value: values.universe_id,
                              }}
                              name="universe_id"
                              id="universe_id"
                              className="react-select mt-1"
                              classNamePrefix="my-react-select"
                              options={Array.from(universeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };  
                              })}
                              onChange={(event) => {
                                setFieldValue("universe_id", event.value);
                              }}
                            />                        
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Status ({manga?.update_history?.status_id})
                            </label>
                            <Select
                              defaultValue={{
                                label: values.status_name,
                                value: values.status_id,
                              }}
                              name="status_id"
                              id="status_id"
                              className="react-select"
                              options={Array.from(statusList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("status_id", event.value);
                              }}
                              isSearchable={true}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Japanese Title ({manga?.update_history?.jp_title})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_title"
                              name="jp_title"
                              placeholder="Japanese Title"
                              type="text"
                              value={values.jp_title}
                              onBlur={()=> checkUniqueJPTitle()}
                              onChange={(event) => {
                                setJPTitle(event.target.value);
                                setFieldValue("jp_title", event.target.value);
                              }}
                            />
                            {isJpTitleUnique === false &&
                              <div className="text-danger text-align-top-center mt-1">
                                <span>Might be this title already exists, Please <Link to={`/admin/manga/manga-list?jp_title=${jPTitle}`}>'click here'</Link> to view label.</span>
                              </div>
                            }
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Publisher (
                              {manga?.update_history?.publisher_id})
                            </label>
                            <Select
                              defaultValue={{
                                label: manga?.publisher_name ?? "None",
                                value: values.publisher_id,
                              }}
                              name="publisher_id"
                              id="publisher_id"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(publisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("publisher_id", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Label ({manga?.update_history?.jp_label})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_label"
                              name="jp_label"
                              placeholder="JP Label"
                              type="text"
                              value={values.jp_label}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Pre-Publication (
                              {manga?.update_history?.pre_publication_id})
                            </label>
                            <Select
                              defaultValue={{
                                label: manga?.pre_publication_name ?? "None",
                                value: values.pre_publication_id,
                              }}
                              name="pre_publication_id"
                              id="pre_publication_id"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(prePublicationList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.en_title,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue(
                                  "pre_publication_id",
                                  event.value
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <div className="d-flex justify-content-between">
                              <label className="form-control-label">
                                Cover ({manga?.update_history?.image})
                              </label>
                              <Button
                                className="btn-sm ml-10 text-right"
                                color="primary"
                                onClick={async () => {
                                  setShowImageModal(true);
                                  setImage(manga?.cover_image);
                                }}
                              >
                                <i className="fa fa-eye"></i>
                              </Button>
                            </div>

                            <div className="form-control fs-1 w-100 mt-1">
                              <label
                                className="fs-6 mb-0 text-muted"
                                style={{ cursor: "pointer" }}
                              >
                                Click here to upload images{" "}
                                <i
                                  className="fa fa-upload"
                                  aria-hidden="true"
                                ></i>
                                <Input
                                  id="image"
                                  type="file"
                                  accept="image/png , image/jpeg, image/webp"
                                  name="image"
                                  className="form-control d-none"
                                  onChange={onSelectFile}
                                />
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Author(s) ({manga?.update_history?.author_ids})
                            </label>
                            <CreatableSelect
                              defaultValue={author}
                              isMulti
                              isValidNewOption={() => true}
                              id="author_ids"
                              name="author_ids"
                              options={Array.from(authorList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              isOptionDisabled={() =>
                                selectedAuthors.length >= 3
                              }
                              onChange={(event) => {
                                const selectedAuthors = event.map(
                                  (e) => e.value
                                );
                                const existingAuthors = [];
                                const newAuthors = [];
                                selectedAuthors.map((x) => {
                                  if (UUIDv4.validate(x)) {
                                    existingAuthors.push(x);
                                  } else {
                                    newAuthors.push(x);
                                  }
                                });
                                setFieldValue("author_ids", existingAuthors);
                                setFieldValue("new_authors", newAuthors);
                                setSelectedAuthors(selectedAuthors);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Illustrator (
                              {manga?.update_history?.illustrator_ids})
                            </label>

                            <CreatableSelect
                              defaultValue={illustrator}
                              isMulti
                              id="illustrator_ids"
                              name="illustrator_ids"
                              options={Array.from(illustratorList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              isOptionDisabled={() =>
                                selectedIllustrator.length >= 3
                              }
                              onChange={(event) => {
                                const selectedIllustrator = event.map(
                                  (e) => e.value
                                );
                                const existingIllustrator = [];
                                const newIllustrator = [];
                                selectedIllustrator.map((x) => {
                                  if (UUIDv4.validate(x)) {
                                    existingIllustrator.push(x);
                                  } else {
                                    newIllustrator.push(x);
                                  }
                                });
                                setFieldValue(
                                  "illustrator_ids",
                                  existingIllustrator
                                );
                                setFieldValue(
                                  "new_illustrators",
                                  newIllustrator
                                );
                                setSelectedIllustrator(selectedIllustrator);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              No. of vol (
                              {manga?.update_history?.no_of_volumes})
                            </label>
                            <Input
                              className="form-control"
                              id="no_of_volumes"
                              name="no_of_volumes"
                              placeholder=" No. of vol"
                              type="number"
                              value={values.no_of_volumes}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              OnGoing ({manga?.update_history?.ongoing})
                            </label>
                            <Select
                              defaultValue={{
                                label:
                                  values.ongoing === true
                                    ? "Yes"
                                    : values.ongoing === null
                                    ? "None"
                                    : "No",
                              }}
                              name="ongoing"
                              id="ongoing"
                              className="react-select"
                              options={Array.from(onGoing).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("ongoing", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Type ({manga?.update_history?.type_id})
                            </label>
                            <Select
                              defaultValue={{
                                label: values.type_name,
                                value: values.type_id,
                              }}
                              name="type_id"
                              id="type_id"
                              className="react-select"
                              options={Array.from(typeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("type_id", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Genre ({manga?.update_history?.genre_ids})
                            </label>
                            <Select
                              defaultValue={genre}
                              isMulti
                              name="genre_ids"
                              id="genre_ids"
                              className="select"
                              options={Array.from(genreList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              isOptionDisabled={() => selectedGenre.length >= 3}
                              onChange={(event) => {
                                const selectedGenre = event.map((e) => e.value);
                                const existingGenre = [];
                                selectedGenre.map((x) => {
                                  if (UUIDv4.validate(x)) {
                                    existingGenre.push(x);
                                  }
                                });
                                setFieldValue("genre_ids", existingGenre);
                                setSelectedGenre(selectedGenre);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Public Targeted (
                              {manga?.update_history?.public_ids})
                            </label>
                            <Select
                              isMulti
                              defaultValue={publics}
                              name="public_ids"
                              id="public_ids"
                              className="react-select"
                              options={Array.from(publicList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              isOptionDisabled={() => selectedPublicTargeted.length >= 3}
                              onChange={(event) => {
                                const selectedPublicTargeted = event.map((e) => e.value);
                                const existingPublicTargeted = [];
                                selectedPublicTargeted.map((x) => {
                                  if (UUIDv4.validate(x)) {
                                    existingPublicTargeted.push(x);
                                  }
                                });
                                setFieldValue("public_ids", existingPublicTargeted);
                                setSelectedPublicTargeted(selectedPublicTargeted);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Print Rights In France (
                              {manga?.update_history?.print_rights_in_france})
                            </label>
                            <Select
                              defaultValue={{
                                label:
                                  manga?.print_rights_in_france_publisher_name ||
                                  "None",
                                value: values.print_rights_in_france,
                              }}
                              id="print_rights_in_france"
                              name="print_rights_in_france"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(frPublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("print_rights_in_france", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Digital Rights in France (
                              {manga?.update_history?.digital_rights_in_france})
                            </label>
                            <Select
                              defaultValue={{
                                label:
                                  manga?.digital_rights_in_france_publisher_name ||
                                  "None",
                                value: values.digital_rights_in_france,
                              }}
                              id=" digital_rights_in_france"
                              name="digital_rights_in_france"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(frPublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("digital_rights_in_france", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Print Rights In US (
                              {manga?.update_history?.print_rights_in_us})
                            </label>
                            <Select
                              defaultValue={{
                                label:
                                  manga?.print_rights_in_us_publisher_name ||
                                  "None",
                                value: values.print_rights_in_us,
                              }}
                              id="print_rights_in_us"
                              name="print_rights_in_us"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(usPublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("print_rights_in_us", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Digital Rights in US (
                              {manga?.update_history?.digital_rights_in_us})
                            </label>
                            <Select
                              defaultValue={{
                                label:
                                  manga?.digital_rights_in_us_publisher_name ||
                                  "None",
                                value: values.digital_rights_in_us,
                              }}
                              id="digital_rights_in_us"
                              name="digital_rights_in_us"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(usPublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("digital_rights_in_us", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Print Rights in DE (
                              {manga?.update_history?.print_rights_in_de})
                            </label>
                            <Select
                              defaultValue={{
                                label:
                                  manga?.print_rights_in_de_publisher_name ||
                                  "None",
                                value: values.print_rights_in_de,
                              }}
                              id="print_rights_in_de"
                              name="print_rights_in_de"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(dePublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("print_rights_in_de", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Digital Rights in DE (
                              {manga?.update_history?.digital_rights_in_de})
                            </label>
                            <Select
                              defaultValue={{
                                label:
                                  manga?.digital_rights_in_de_publisher_name ||
                                  "None",
                                value: values.digital_rights_in_de,
                              }}
                              id="digital_rights_in_de"
                              name="digital_rights_in_de"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(dePublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("digital_rights_in_de", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP print run 1 (
                              {manga?.update_history?.jp_print_run_1})
                            </label>
                            <Input
                              className="form-control"
                              id=" jp_print_run_1"
                              name="jp_print_run_1"
                              placeholder=" JP print run 1"
                              type="number"
                              value={values.jp_print_run_1}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP print run 2 (
                              {manga?.update_history?.jp_print_run_2})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_print_run_2"
                              name="jp_print_run_2"
                              placeholder="JP print run 2"
                              type="number"
                              value={values.jp_print_run_2}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP print run 3 (
                              {manga?.update_history?.jp_print_run_3})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_print_run_3"
                              name="jp_print_run_3"
                              placeholder="JP print run 3"
                              type="number"
                              value={values.jp_print_run_3}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP print run 4 (
                              {manga?.update_history?.jp_print_run_4})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_print_run_4"
                              name="jp_print_run_4"
                              placeholder="JP print run 4"
                              type="number"
                              value={values.jp_print_run_4}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        {permissions &&
                          permissions.map((permission) =>
                            permission === "update-manga-product-pl" ? (
                              <Col lg="3">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Product P&L (
                                    {manga?.update_history?.product_pl})
                                  </label>
                                  <Input
                                    className="form-control"
                                    id="product_pl"
                                    name="product_pl"
                                    placeholder="Product P&L"
                                    type="text"
                                    value={values.product_pl}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            ) : (
                              ""
                            )
                        )}

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP wikipedia (
                              {manga?.update_history?.jp_wikipedia_link})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_wikipedia_link"
                              name="jp_wikipedia_link"
                              placeholder="JP wikipedia"
                              type="text"
                              value={values.jp_wikipedia_link}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                               Nautiljon (
                              {manga?.update_history?.nautiljon})
                            </label>
                            <Input
                              className="form-control"
                              id="nautiljon"
                              name="nautiljon"
                              placeholder="Nautiljon"
                              type="text"
                              value={values.nautiljon}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        {permissions &&
                          permissions.map((permission) =>
                            permission === "update-manga-low-sale" ? (
                                <Col lg="3">
                                  <FormGroup>
                                    <label className="form-control-label">
                                      Low Sales (
                                      {manga?.update_history?.low_sales})
                                    </label>
                                    <Input
                                      className="form-control"
                                      id="low_sales"
                                      name="low_sales"
                                      placeholder="Low Sales"
                                      type="number"
                                      value={values.low_sales}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                            ) : (
                              ""
                            )
                        )}

                        {permissions &&
                          permissions.map((permission) =>
                            permission === "update-manga-medium-sale" ? (
                              <Col lg="3">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Medium Sales (
                                    {manga?.update_history?.medium_sales})
                                  </label>
                                  <Input
                                    className="form-control"
                                    id="medium_sales"
                                    name="medium_sales"
                                    placeholder="Medium Sales"
                                    type="number"
                                    value={values.medium_sales}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            ) : (
                              ""
                            )
                        )}

                        {permissions &&
                          permissions.map((permission) =>
                            permission === "update-manga-high-sale" ? (
                              <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  High Sales (
                                  {manga?.update_history?.high_sales})
                                </label>
                                <Input
                                  className="form-control"
                                  id="high_sales"
                                  name="high_sales"
                                  placeholder="High Sales"
                                  type="number"
                                  value={values.high_sales}
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                            ) : (
                              ""
                            )
                        )}
                           
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              FR consumer price (
                              {manga?.update_history?.fr_consumer_price}
                              )
                            </label>
                            <Input
                              className="form-control"
                              id="fr_consumer_price"
                              name="fr_consumer_price"
                              placeholder="FR Consumer Price"
                              type="number"
                              value={values.fr_consumer_price}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP BookWalker (
                              {manga?.update_history?.jp_bookwalker_link})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_bookwalker_link"
                              name="jp_bookwalker_link"
                              placeholder="JP BookWalker"
                              type="text"
                              value={values.jp_bookwalker_link}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Amazon ({manga?.update_history?.jp_amazon_link}
                              )
                            </label>
                            <Input
                              className="form-control"
                              id="jp_amazon_link"
                              name="jp_amazon_link"
                              placeholder="JP Amazon"
                              type="text"
                              value={values.jp_amazon_link}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Raw (
                              {manga?.update_history?.jp_raw})
                            </label>
                            <Input
                              className="form-control"
                              id="jp_raw"
                              name="jp_raw"
                              placeholder="JP Raw"
                              type="text"
                              value={values.jp_raw}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              ScanTrad ({manga?.update_history?.scantrad}
                              )
                            </label>
                            <Input
                              className="form-control"
                              id="scantrad"
                              name="scantrad"
                              placeholder="ScanTrad"
                              type="text"
                              value={values.scantrad}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                     
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Flag</label>
                            <Select
                              isMulti
                              defaultValue={flags}
                              name="flag_users"
                              id="flag_users"
                              className="select"
                              options={Array.from(flagList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.first_name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue(
                                  "flag_users",
                                  event.map((e) => e.value)
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Summary ({manga?.update_history?.summary})
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Summary..."
                              type="text"
                              name="summary"
                              id="summary"
                              value={values.summary}
                              onChange={handleChange}
                              rows={2}
                              cols={40}
                            ></textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Comments ({loginUser.user?.first_name}{" "}
                              {loginUser.user?.last_name})
                              {values.comment &&  <RiDeleteBin6Line
                                  onClick={showDeleteConfirm}
                                  className="ml-19"
                                  style={{ cursor: "pointer" }}
                                  size={26}
                                /> }
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="comments..."
                              type="text"
                              name="comment"
                              id="comment"
                              value={values.comment}
                              onChange={(event) => {
                                if(event.target.value.trim() !== ''){
                                  setFieldValue(
                                    "comment",
                                    event.target.value
                                  );
                                }else {
                                  setFieldValue('comment','');
                                }
                              }}
                              rows={2}
                              cols={40}
                              ref={inputRef}
                            ></textarea>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <div className="d-flex justify-content-between mb-1">
                              <label className="form-control-label">
                                Rating ({loginUser.user?.first_name}{" "}
                              {loginUser.user?.last_name})
                              </label>
                              <Button
                                className="btn-sm ml-10 text-right"
                                color="primary"
                                onClick={async () => {
                                  setFieldValue("rating", "");
                                  setSelectedRating('');
                                }}
                              >
                                <i className="fa fa-refresh"></i>
                              </Button>
                            </div>
                            <div style={{display: 'flex', border: '1px solid #cad1d7', borderRadius:'0.375rem'}} className="pl-2 pt-2">
                              <div className="mr-2">
                                <label>
                                  <input
                                    type="radio"
                                    value="1"
                                    name="rating"
                                    checked={selectedRating == '1'}
                                    onChange={(event) => {
                                      setFieldValue("rating", event.target.value);
                                      setSelectedRating('1');
                                    }}
                                    className="mr-2"
                                  />
                                  <img src={`${process.env.PUBLIC_URL}/rating-images/1.jpg`} alt="rating-1" height="30%" width="30%" />
                                </label>
                              </div>
                              <div className="mr-2">
                                <label>
                                  <input
                                    type="radio"
                                    value="2"
                                    name="rating"
                                    checked={selectedRating == '2'}
                                    onChange={(event) => {
                                      setFieldValue("rating", event.target.value);
                                      setSelectedRating('2');
                                    }}
                                    className="mr-2"
                                  />
                                  <img src={`${process.env.PUBLIC_URL}/rating-images/2.jpg`} alt="rating-2" height="30%" width="30%" />
                                </label>
                              </div>
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    value="3"
                                    name="rating"
                                    checked={selectedRating == '3'}
                                    onChange={(event) => {
                                      setFieldValue("rating", event.target.value);
                                      setSelectedRating('3');
                                    }}
                                    className="mr-2"
                                  />
                                  <img src={`${process.env.PUBLIC_URL}/rating-images/3.jpg`} alt="rating-3" height="27%" width="27%" />
                                </label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <label className="form-control-label">
                            All Comments
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        {manga?.all_comments?.length > 0 ? (
                          manga.all_comments.map((x) => (
                            <>
                              <Col lg="3">
                                <li className="fs-5 text-dark">
                                  {x.first_name} {x.last_name}
                                </li>

                                <textarea
                                  className="form-control"
                                  type="text"
                                  name="all_comments"
                                  value={x.comment}
                                  onChange={handleChange}
                                  rows={2}
                                  cols={40}
                                  disabled
                                ></textarea>
                              </Col>
                            </>
                          ))
                        ) : (
                          <>
                            <div>No Comments</div>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary"
                          >
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Update Manga
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
              <ImageModal
                show={showImageModel}
                image={image}
                onClose={() => {
                  setShowImageModal(false);
                }}
              />
              <CreateUniverses
                show={show}
                mediaType={"MANGA"}
                onClose={() => {
                  getUniverseList();
                  setShowModal(false);
                }}
              />
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
};

export default UpdateManga;
