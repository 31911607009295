import {useState } from 'react'
import { useLocation } from 'react-router-dom';

function useSearchParams() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const fromDate = searchParams.get("from_date");
    const toDate = searchParams.get("to_date");
    const currentPage = searchParams.get('page');
    const jpRaw = searchParams.get('jp_raw');
    const enTitle = searchParams.get('en_title');
    const jpTitle = searchParams.get('jp_title');
    const name = searchParams.get('name');
    const jpName = searchParams.get('jp_name');
    const original_medium = searchParams.get('original_medium');
    const is_active = searchParams.get('is_active');
    const noOfMediaCount = searchParams.get('no_of_media_count');
    const userIdString = searchParams.get('user_id');
    const statusIdString = searchParams.get('status_id');
    const frPublisherIdString = searchParams.get('fr_publisher_id');
    const jpPublisherIdString = searchParams.get('jp_publisher_id');
    const universeIdString = searchParams.get('universe_id');
    const publisherIdString = searchParams.get('publisher_id');
    const typeIdString = searchParams.get('type_id');
    const prePublicationIdString = searchParams.get('pre_publication_id');
    const prePublicationIdsArray = prePublicationIdString ? prePublicationIdString.split(',').map((x)=>x) : "";
    const userIdsArray = userIdString ? userIdString.split(',').map((x)=>x) : "";
    const statusIdsArray = statusIdString ? statusIdString.split(',').map((x)=>x) : "";
    const publisherIdsArray = publisherIdString ? publisherIdString.split(',').map((x)=>x) : "";
    const typeIdsArray = typeIdString ? typeIdString.split(',').map((x)=>x) : "";
    const universeIdsArray = universeIdString ? universeIdString.split(',').map((x)=>x) : "";
    const frPublisherIdsArray = frPublisherIdString ? frPublisherIdString.split(',').map((x)=>x) : "";
    const jpPublisherIdsArray = jpPublisherIdString ? jpPublisherIdString.split(',').map((x)=>x) : "";
    const publisher_type = searchParams.get('publisher_type');
    const label = searchParams.get('label');
    const company = searchParams.get('company');
    const firstName = searchParams.get('first_name');
    const lastName = searchParams.get('last_name');
    const email = searchParams.get('email');
    const is_bookmark = searchParams.get('is_bookmark');
    const redirect = searchParams.get('redirect');
    const is_form_dashboard  = searchParams.get('is_form_dashboard')


    const [response,setResponse] = useState({
        id,
        currentPage,
        enTitle,
        name,
        original_medium,
        noOfMediaCount,
        is_active,
        fromDate,
        toDate,
        userIdString,
        statusIdString,
        publisherIdString,
        typeIdString,
        prePublicationIdString,
        prePublicationIdsArray,
        userIdsArray,
        statusIdsArray,
        publisherIdsArray,
        typeIdsArray,
        universeIdString,
        universeIdsArray,
        frPublisherIdString,
        frPublisherIdsArray,
        jpPublisherIdString,
        jpPublisherIdsArray,
        jpName,
        publisher_type,
        label,
        company,
        firstName,
        lastName,
        email,
        is_bookmark,
        redirect,
        is_form_dashboard,
        jpTitle,
        jpRaw
    })


    if(response?.search === null){
        location.search = null
    }

  
  return [response,setResponse];
}

export default useSearchParams
