import Header from "components/Headers/Header";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { get_universes_list, get_publisher_list } from "utils/Commen";
import { useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import clsx from "clsx";
import { updateVideoGame } from "utils/endpoint";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from "antd";
import { deleteVideoGameComment } from "utils/endpoint";
import { getVideoGameById } from "utils/endpoint";
import { RiDeleteBin6Line } from "react-icons/ri";
import CreateUniverses from "utils/CreateUniverses";
import useSearchParams from "utils/useSearchParams";
import { CSVLink } from "react-csv";
import { genrateCsvFileName } from "utils/Commen";

const validationSchema = yup.object({
  en_title: yup.string().required("EN Title is required"),
});

export const headers = [
  { label: "EN Title", key: "en_title" },
  { label: "Universe Name", key: "universe_name" },
  { label: "Jp Publisher", key: "jp_publisher_name" },
  { label: "JP Release Date", key: "jp_release_date" },
  { label: "French publisher ", key: "french_publisher_name" },
  { label: "French Release Date", key: "french_release_date" },
  { label: "Link", key: "link" },
  { label: "CreatedAt", key: "created_at" },
];

export const getData = (data) => {
  const videoGameData = [{
    en_title:data?.en_title,
    universe_name:data?.universe_name,
    french_publisher_name:data?.french_publisher_name,
    french_release_date:data?.french_release_datemoment ? (data.french_release_date).format("YYYY-MM-DD"):'',
    jp_publisher_name:data?.jp_publisher_name,
    jp_release_date:data?.jp_release_date ? moment(data.jp_release_date).format("YYYY-MM-DD") : '',
    link:data?.link,
    created_at:data?.created_at ? moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"):''
  }];

  return videoGameData
}


const UpdateVideoGame = () => {
  const { state } = useLocation();
  const [videoGameQueryParams ,setVideoGameQueryParams] = useSearchParams();
  const currentPage = videoGameQueryParams.currentPage;
  const enTitle = videoGameQueryParams.enTitle;
  const fromDate = videoGameQueryParams.fromDate;
  const toDate = videoGameQueryParams.toDate;
  const userIdString = videoGameQueryParams.userIdString;
  const universeIdString = videoGameQueryParams.universeIdString;
  const jpPublisherIdString = videoGameQueryParams.jpPublisherIdString;
  const frPublisherIdString = videoGameQueryParams.frPublisherIdString;
  const is_active = videoGameQueryParams.is_active;
  const [loader, showLoader, hideLoader] =UseFullPageLoader();
  const [universeList, setUniverseList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);
  const [frPublisherList, setFrPublisherList] = useState([]);
  const [currentComment, setCurrentComment] = useState(state.row.comment);
  const [videoGame] = useState(state.row);
  const [show, setShowModal] = useState(false);
  const [csvData,setCsvData] = useState([])

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const { id } = params;
  const inputRef = useRef(null);

  const loginData = localStorage.getItem("User");
  const loginUser = JSON.parse(loginData);
  
  const getUniverseList = async () => {
    const allUniverses = await get_universes_list();
    setUniverseList([{id:'',name:'None'},...allUniverses]);
  };
  const getPublisherList = async () => {
    const allPublisher = await get_publisher_list();
    const jpPublisherData = allPublisher.filter((publisher) => publisher.publisher_type == 'JP')
    const frPublisherData = allPublisher.filter((publisher) => publisher.publisher_type == 'FR')
    setPublisherList([{ id: "", name: "None" }, ...jpPublisherData]);
    setFrPublisherList([{ id: "", name: "None" }, ...frPublisherData]);
  };

  const { confirm } = Modal;
  const videoGameId = state.row.id;

  const handleDeleteVideoGameComment = async () => {
    try {
      inputRef.current.value = '';
      const response = await deleteVideoGameComment(videoGameId);
      if (response.isSuccess) {
        toast.success(`${response.message}`);
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this comment ?",
      icon: <ExclamationCircleFilled />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleDeleteVideoGameComment();
      },
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getVideoGameById({
          videoGame_id: videoGameId,
        });
        if(response && response.isSuccess){
          setCurrentComment(response.data.comment)
        }
      } catch (error) {
        toast.error(`${error.message}`);
      }
    })();
  }, []);

  const handleSearchParams = () => {
    let url = `/admin/videoGame/videoGame-list?id=${id}`;
    if(currentPage) url+=`&page=${currentPage}`;
    if(enTitle) url+= `&en_title=${enTitle}`
    if(fromDate) url+= `&from_date=${fromDate}`
    if(toDate) url+= `&to_date=${toDate}`
    if(universeIdString) url += `&universe_id=${universeIdString}`
    if(jpPublisherIdString) url += `&jp_publisher_id=${jpPublisherIdString}`
    if(frPublisherIdString) url += `&fr_publisher_id=${frPublisherIdString}`
    if(is_active || is_active === "false") url += `&is_active=${is_active === "true" ? "true" : is_active === "none" ? "none" :"false"}`;
    if(userIdString) url+= `&user_id=${userIdString}`    
    return url;
  }


  useEffect(() => {
    // getVideoGame(id);
    getUniverseList();
    getPublisherList();
  }, [id]);

  useEffect(()=>{
    const data = getData(videoGame)
    setCsvData(data)
  },[])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        en_title: videoGame?.en_title ?? "",
        universe_id: videoGame?.universe_id ?? "",
        universe_name: videoGame?.universe_name ?? "None",
        jp_publisher_name: videoGame?.jp_publisher_name ?? "None",
        jp_publisher_id: videoGame?.jp_publisher_id ?? "",
        jp_release_date:
          videoGame?.jp_release_date ?? ""
            ? moment(videoGame?.jp_release_date).format("YYYY-MM-DD")
            : "" ?? "",
        french_publisher_id: videoGame?.french_publisher_id ?? "",
        french_publisher_name: videoGame?.french_publisher_name ?? "None",
        french_release_date: videoGame?.french_release_date
          ? moment(videoGame?.french_release_date).format("YYYY-MM-DD")
          : "" ?? "",
        link: videoGame?.link ?? "",
        comment: currentComment ?? "",
        is_active: videoGame?.is_active ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader()
        const updateData = {
          en_title: values.en_title,
          universe_id: values.universe_id,
          jp_publisher_id: values.jp_publisher_id,
          jp_release_date: values.jp_release_date,
          french_publisher_id: values.french_publisher_id,
          french_release_date: values.french_release_date,
          link: values.link,
          comment: values.comment,
          is_active: values.is_active,
        };

        const finalObj = {};

        Object.keys(updateData).forEach(function (key) {
          if (typeof updateData[key] === "string" && updateData[key]) {
            finalObj[key] = updateData[key];
          }
          if (typeof updateData[key] === "boolean") {
            finalObj[key] = updateData[key];
          }
          if (
            typeof updateData[key] === "object" &&
            updateData[key].length > 0
          ) {
            finalObj[key] = updateData[key];
          }
          if (typeof updateData[key] === "number" && updateData[key]) {
            finalObj[key] = updateData[key];
          }
        });
        
        finalObj.universe_id = finalObj?.universe_id ? finalObj?.universe_id : null;
        finalObj.jp_publisher_id = finalObj?.jp_publisher_id  ? finalObj?.jp_publisher_id : null;
        finalObj.french_publisher_id = finalObj?.french_publisher_id ? finalObj?.french_publisher_id : null;

        const result = await updateVideoGame(id, finalObj);
        if (result?.isSuccess) {
          toast.success("VideoGame Updated successfully");
          setLoading(false);
          hideLoader()
          resetForm();
          const url =  handleSearchParams()
          navigate(url);
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader()
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />
            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Update Video Game</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                        <CSVLink
                          data={csvData}
                          headers={headers}
                          filename={genrateCsvFileName(`video_game_${videoGame.en_title}`)}
                          className="btn btn-primary"
                          target="_blank"
                        >
                          Export to CSV
                        </CSVLink>
                         <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              const url =  handleSearchParams()
                              navigate(url);
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                      
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              EN Title ({videoGame?.update_history?.en_title})
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.en_title) && touched.en_title,
                              })}
                              id="en_title"
                              name="en_title"
                              placeholder="EN Title"
                              type="text"
                              value={values.en_title}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="en_title" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <div className="d-flex justify-content-between">
                            <label className="form-control-label">
                              Universe ({videoGame?.update_history?.universe_id})
                            </label>
                            <Button
                              className="btn-sm ml-10 text-right"
                              color="primary"
                              onClick={async () => {
                                setShowModal(true);
                              }}>
                                +
                            </Button>
                            </div>
                            <Select
                              defaultValue={{
                                label: values.universe_name,
                                value: values.universe_id,
                              }}
                              name="universe_id"
                              id="universe_id"
                              className="react-select mt-1"
                              classNamePrefix="my-react-select"
                              options={Array.from(universeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };  
                              })}
                              onChange={(event) => {
                                setFieldValue("universe_id", event.value);
                              }}
                            />                        
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Publisher ({videoGame?.update_history?.jp_publisher_id})
                            </label>
                            <Select
                              defaultValue={{
                                label: values.jp_publisher_name,
                                value: values.jp_publisher_id,
                              }}
                              name="jp_publisher_id"
                              id="jp_publisher_id"
                              className="react-select"
                              options={Array.from(publisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("jp_publisher_id", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Release Date ({videoGame?.update_history?.jp_release_date})
                            </label>
                            <Input
                              name="jp_release_date"
                              id="jp_release_date"
                              closeonselect="true"
                              timeformat="false"
                              defaultValue={values.jp_release_date}
                              onChange={handleChange}
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              French publisher ({videoGame?.update_history?.french_publisher_id})
                            </label>
                            <Select
                              defaultValue={{
                                label: values.french_publisher_name,
                                value: values.french_publisher_id,
                              }}
                              name="french_publisher_id"
                              id="french_publisher_id"
                              className="react-select"
                              options={Array.from(frPublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue(
                                  "french_publisher_id",
                                  event.value
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">
                              French Release Date ({videoGame?.update_history?.french_release_date})
                            </label>
                            <Input
                              type="date"
                              id="french_release_date"
                              name="french_release_date"
                              closeonselect="true"
                              timeformat="false"
                              value={values.french_release_date}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">Link ({videoGame?.update_history?.link})</label>
                            <Input
                              className="form-control"
                              id="link"
                              name="link"
                              placeholder="Link"
                              type="text"
                              value={values.link}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                          <label className="form-control-label">
                              Comments ({loginUser.user?.first_name}{" "}
                              {loginUser.user?.last_name})
                              {values.comment &&  <RiDeleteBin6Line
                                  onClick={showDeleteConfirm}
                                  className="ml-19"
                                  style={{ cursor: "pointer" }}
                                  size={26}
                                /> }
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="comments..."
                              type="text"
                              name="comment"
                              id="comment"
                              value={values.comment}
                              onChange={(event) => {
                                if(event.target.value.trim() !== ''){
                                  setFieldValue(
                                    "comment",
                                    event.target.value
                                  );
                                }else {
                                  setFieldValue('comment','');
                                }
                              }}
                              ref={inputRef}
                              rows={2}
                              cols={40}></textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <label className="form-control-label">
                            All Comments
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        {videoGame?.all_comments?.length > 0 ? (
                          videoGame.all_comments.map((x) => (
                            <>
                              <Col lg="3">
                                <li className="fs-5 text-dark">
                                  {x.first_name} {x.last_name}
                                </li>

                                <textarea
                                  className="form-control"
                                  type="text"
                                  name="all_comments"
                                  value={x.comment}
                                  onChange={handleChange}
                                  disabled
                                  rows={2}
                                  cols={40}></textarea>
                              </Col>
                            </>
                          ))
                        ) : (
                          <>
                            <div>No Comments</div>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary">
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Update Video Game
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
              <CreateUniverses
                show={show}
                mediaType={"VIDEO_GAME"}
                onClose={() => {
                  getUniverseList();
                  setShowModal(false);
                }}
              />
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
};

export default UpdateVideoGame;
