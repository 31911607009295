import { Get, Post, PostCustom, Put } from './requesthandler'

export const login = (data) => Post('api/auth/login', data)
export const generate2FaQrCode = (param, data) => PostCustom('api/auth/generate-2fa-qrcode', param, data, true);
export const verify2faOtp = (param, data) => PostCustom('api/auth/verify-2fa', param, data, true);

export const createUser = (data) => Post('api/users', data, true)
export const userList = (data) => Get('api/users',data , true)
export const updateUser = (id, param) => Put(`api/users/${id}`, param ,true)
export const updateUserStatus = (data) => Post(`api/users/update-user-status`, data, true)
export const getUserById = (data) => Get(`api/users/get/${data.user_id}`, '' ,true)
export const changePassword = (data) => Post('api/users/change-password', data, true)

export const getUniverses = () => Get('api/universe/get-universes','' , true)
export const getStatus = () => Get('api/status/get-statuses','' , true)
export const getPublishers = () => Get('api/publisher/get-publishers','' , true)
export const getTypes = () => Get('api/type/get-types','' , true)
export const getGenres = () => Get('api/genre/get-genres','' , true)
export const getAuthors = () => Get('api/author/get-authors','' , true)
export const getPrePublications = () => Get('api/pre-publication/get-pre-publications','' , true)
export const getIllustrators = () => Get('api/illustrator/get-illustrators','' , true)
export const getPublics = () => Get('api/public/get-publics','' , true)
export const getPermission = () => Get('api/permission','' , true)
export const getUser = () => Get('api/users/get-users','' , true)


export const createLightNovel = (data) => Post('api/light-novel', data, true)
export const lightNovelList = (data) => Post('api/light-novel/list',data , true)
export const getLightNovelById = (data) => Get(`api/light-novel/${data.lightNovel_id}`, '' ,true)
export const updateLightNovelStatus = (data) => Post(`api/light-novel/update-light-novel-status`, data, true)
export const updateLightNovel = (id, param) => Put(`api/light-novel/${id}`, param ,true)
export const importLightNovel = (param) => Post(`api/light-novel/import-light-novel`, param ,true)

export const createManga = (data) => Post('api/manga', data, true)
export const mangaList = (data) => Post('api/manga/list',data , true)
export const getMangaById = (data) => Get(`api/manga/${data.manga_id}`, '' ,true)
export const updateManga = (id, param) => Put(`api/manga/${id}`, param ,true)
export const updateMangaStatus = (data) => Post(`api/manga/update-manga-status`, data, true)
export const importManga = (param) => Post(`api/manga/import-manga`, param ,true)

export const createAnime = (data) => Post('api/anime', data, true)
export const animeList = (data) => Post('api/anime/list',data , true)
export const getAnimeById = (data) => Get(`api/anime/${data.anime_id}`, '' ,true)
export const updateAnime = (id, param) => Put(`api/anime/${id}`, param ,true)
export const updateAnimeStatus = (data) => Post(`api/anime/update-anime-status`, data, true)
export const importAnime = (param) => Post(`api/anime/import-anime`, param ,true)

export const createVideoGame = (data) => Post('api/video-game', data, true)
export const videoGameList = (data) => Post('api/video-game/list',data , true)
export const getVideoGameById = (data) => Get(`api/video-game/${data.videoGame_id}`, '' ,true)
export const updateVideoGame = (id, param) => Put(`api/video-game/${id}`, param ,true)
export const updateVideoGameStatus = (data) => Post(`api/video-game/update-video-game-status`, data, true)
export const importVideoGame = (param) => Post(`api/video-game/import-video-game`, param ,true)

export const createWebtoon = (data) => Post('api/webtoon', data, true)
export const webtoonList = (data) => Post('api/webtoon/list',data , true)
export const getWebtoonById = (data) => Get(`api/webtoon/${data.webtoon_id}`, '' ,true)
export const updateWebtoon = (id, param) => Put(`api/webtoon/${id}`, param ,true)
export const updateWebtoonStatus = (data) => Post(`api/webtoon/update-webtoon-status`, data, true)
export const importWebtoon = (param) => Post(`api/webtoon/import-webtoon`, param ,true)

export const createUniverse = (data) => Post('api/universe', data, true)
export const universeList = (data) => Get('api/universe',data , true)
export const getUniverseById = (data) => Get(`api/universe/get/${data.universe_id}`, '' ,true)
export const updateUniverse = (id, param) => Put(`api/universe/${id}`, param ,true)
export const updateUniverseStatus = (data) => Post(`api/universe/update-universe-status`, data, true)
export const deleteUniverse = (id) => Post(`api/universe/delete/${id}` ,'',true)

export const createStatus = (data) => Post('api/status', data, true)
export const statusList = (data) => Get('api/status',data , true)
export const getStatusById = (data) => Get(`api/status/get/${data.status_id}`, '' ,true)
export const updateStatus = (id, param) => Put(`api/status/${id}`, param ,true)
export const updateACtiveStatus = (data) => Post(`api/status/update-active-status`, data, true)

export const createPublisher = (data) => Post('api/publisher', data, true)
export const publisherList = (data) => Get('api/publisher',data , true)
export const getPublisherById = (data) => Get(`api/publisher/get/${data.publisher_id}`, '' ,true)
export const updatePublisher = (id, param) => Put(`api/publisher/${id}`, param ,true)
export const updatePublisherStatus = (data) => Post(`api/publisher/update-publisher-status`, data, true)

export const createType = (data) => Post('api/type', data, true)
export const typeList = (data) => Get('api/type',data , true)
export const getTypeById = (data) => Get(`api/type/get/${data.type_id}`, '' ,true)
export const updateType = (id, param) => Put(`api/type/${id}`, param ,true)
export const updateTypeStatus = (data) => Post(`api/type/update-type-status`, data, true)

export const createGenre = (data) => Post('api/genre', data, true)
export const genreList = (data) => Get('api/genre',data , true)
export const getGenreById = (data) => Get(`api/genre/get/${data.genre_id}`, '' ,true)
export const updateGenre = (id, param) => Put(`api/genre/${id}`, param ,true)
export const updateGenreStatus = (data) => Post(`api/genre/update-genre-status`, data, true)

export const createAuthor = (data) => Post('api/author', data, true)
export const authorList = (data) => Get('api/author',data , true)
export const getAuthorById = (data) => Get(`api/author/get/${data.author_id}`, '' ,true)
export const updateAuthor = (id, param) => Put(`api/author/${id}`, param ,true)
export const updateAuthorStatus = (data) => Post(`api/author/update-author-status`, data, true)

export const createPrePublication = (data) => Post('api/pre-publication', data, true)
export const prePublicationList = (data) => Get('api/pre-publication',data , true)
export const getPrePublicationById = (data) => Get(`api/pre-publication/get/${data.prePublication_id}`, '' ,true)
export const updatePrePublicationStatus = (data) => Post(`api/pre-publication/update-pre-publication-status`, data, true)
export const updatePrePublication = (id, param) => Put(`api/pre-publication/${id}`, param ,true)

export const createIllustrator = (data) => Post('api/illustrator', data, true)
export const illustratorList = (data) => Get('api/illustrator',data , true)
export const getIllustratorById = (data) => Get(`api/illustrator/get/${data.illustrator_id}`, '' ,true)
export const updateIllustrator = (id, param) => Put(`api/illustrator/${id}`, param ,true)
export const updateIllustratorStatus = (data) => Post(`api/illustrator/update-illustrator-status`, data, true)

export const createPublic = (data) => Post('api/public', data, true)
export const publicList = (data) => Get('api/public',data , true)
export const getPublicById = (data) => Get(`api/public/get/${data.public_id}`, '' ,true)
export const updatePublic = (id, param) => Put(`api/public/${id}`, param ,true)
export const updatePublicStatus = (data) => Post(`api/public/update-public-status`, data, true)

export const getRoleList = () => Get(`api/role/get`,'',true)
export const getRoleById = (data) => Get(`api/role/get/${data.role_id}`, '' ,true)
export const createRole = (data) => Post('api/role', data, true)
export const roleList = (data) => Get('api/role',data , true)
export const updateRole = (id, param) => Put(`api/role/${id}`, param ,true)

export const getUniverseSummaryById = (universe_id) => Get(`api/universe/get-universe-summary/${universe_id}`,'' , true)
export const getAuthorSummaryById = (author_id) => Get(`api/author/get-author-summary/${author_id}`,'' , true)
export const getPrePublicationSummaryById = (pre_publication_id) => Get(`api/pre-publication/get-pre-publication-summary/${pre_publication_id}`,'' , true)
export const getIllustratorSummaryById = (illustrator_id) => Get(`api/illustrator/get-illustrator-summary/${illustrator_id}`,'' , true)

export const lightNovelFlagList = (data) => Post('api/light-novel/get-light-novel-flag-list',data , true)
export const mangaFlagList = (data) => Post('api/manga/get-manga-flag-list',data , true)
export const webtoonFlagList = (data) => Post('api/webtoon/get-webtoon-flag-list',data , true)

export const lightNovelUpdateFlagStatus = (data) => Post('api/light-novel/update-flag-status', data, true)
export const mangaUpdateFlagStatus = (data) => Post('api/manga/update-flag-status', data, true)
export const webtoonUpdateFlagStatus = (data) => Post('api/webtoon/update-flag-status', data, true)

export const deleteMangaComment = (manga_id) => Put(`api/manga/delete-manga-comment/${manga_id}`,'' , true)
export const deleteLightNovelComment = (light_novel_id) => Put(`api/light-novel/delete-light-novel-comment/${light_novel_id}`,'' , true)
export const deleteWebtoonComment = (webtoon_id) => Put(`api/webtoon/delete-webtoon-comment/${webtoon_id}`,'' , true)
export const deleteAnimeComment = (anime_id) => Put(`api/anime/delete-anime-comment/${anime_id}`,'' , true)
export const deleteVideoGameComment = (video_game_id) => Put(`api/video-game/delete-video-game-comment/${video_game_id}`,'' , true)

export const googleAuthenticatorUnlink = (user_id) => Post(`api/users/google-authenticator-unlink/${user_id}`,'' , true)

export const createLightNovelBookMark = (data) => Post('api/light-novel/create-light-novel-bookmark', data, true);
export const createMangaBookMark = (data) => Post('api/manga/create-manga-bookmark', data, true);
export const createWebtoonBookMark = (data) => Post('api/webtoon/create-webtoon-bookmark', data, true);

export const deleteMangaBookMark = (manga_bookmark_id) => Put(`api/manga/delete-manga-bookmark/${manga_bookmark_id}`,'' , true)
export const deleteLightNovelBookMark = (light_novel_bookmark_id) => Put(`api/light-novel/delete-light-novel-bookmark/${light_novel_bookmark_id}`,'' , true)
export const deleteWebtoonBookMark = (webtoon_bookmark_id) => Put(`api/webtoon/delete-webtoon-bookmark/${webtoon_bookmark_id}`,'' , true)

export const verifyUniqueLightNovelJPTitle = (data) => Post('api/light-novel/verify-unique-light-novel-jp-title', data, true)
export const verifyUniqueMangaJPTitle = (data) => Post('api/manga/verify-unique-manga-jp-title', data, true)
export const verifyUniqueWebtoonJPTitle = (data) => Post('api/webtoon/verify-unique-webtoon-jp-title', data, true)