import Header from "components/Headers/Header";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardImg,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import ImageModal from "utils/ImageModal";
import { RiDeleteBin6Line } from "react-icons/ri";
import { getMangaById, deleteMangaComment } from "utils/endpoint";
import { mangaUpdateFlagStatus } from "utils/endpoint";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { getUniverseSummaryById } from "utils/endpoint";
import { getAuthorSummaryById } from "utils/endpoint";
import { getIllustratorSummaryById } from "utils/endpoint";
import AuthorModal from "utils/authorModal";
import IllustratorModal from "utils/illustratorModal";
import UniverseModal from "utils/universeModal";
import useSearchParams from "utils/useSearchParams";
import Checkbox from '@mui/material/Checkbox';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { handleMangaBookMark } from "./MangaList";
import { CSVLink } from "react-csv";
import { genrateCsvFileName } from "utils/Commen";
import  isPageReload  from "utils/enums";
import RatingUserListModal from "utils/ratingUserListModal";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const headers = [
  { label: "EN Title", key: "en_title" },
  { label: "Universe Name", key: "universe_name" },
  { label: "Status", key: "status_name" },
  { label: "JP Title", key: "jp_title" },
  { label: "JP publisher", key: "publisher_name" },
  { label: "JP Label", key: "jp_label" },
  { label: "Pre Publication", key: "pre_publication_name" },
  { label: "Cover Image", key: "cover_image" },
  { label: "Authors", key: "authors" },
  { label: "Illustrators", key: "illustrators" },
  { label: "No. of vol", key: "no_of_volumes" },
  { label: "Ongoing", key: "ongoing" },
  { label: "Type", key: "type_name" },
  { label: "Genres", key: "genres" },
  { label: "Publics", key: "publics" },
  { label: "Print Rights In France", key: "print_rights_in_france" },
  { label: "Digital Rights In France", key: "digital_rights_in_france" },
  { label: "Print Rights In US", key: "print_rights_in_us" },
  { label: "Digital Rights In US", key: "digital_rights_in_us" },
  { label: "Print Rights In DE", key: "print_rights_in_de" },
  { label: "Digital Rights In DE", key: "digital_rights_in_de" },
  { label: "JP Print Run 1", key: "jp_print_run_1" },
  { label: "JP Print Run 2", key: "jp_print_run_2" },
  { label: "JP Print Run 3", key: "jp_print_run_3" },
  { label: "JP Print Run 4", key: "jp_print_run_4" },
  { label: "Product P&L", key: "product_pl" },
  { label: "JP Wikipedia", key: "jp_wikipedia_link" },
  { label: "Nautiljon", key: "nautiljon" },
  { label: "Low Sales", key: "low_sales" },
  { label: "Medium Sales", key: "medium_sales" }, 
  { label: "High Sales", key: "high_sales" },
  { label: "FR Consumer Price", key: "fr_consumer_price" },
  { label: "JP BookWalker", key: "jp_bookwalker_link" },
  { label: "JP Amazon", key: "jp_amazon_link" },
  { label: "JP Raw", key: "jp_raw" },
  { label: "ScanTrad", key: "scantrad" },
  { label: "Flag To Users", key: "flag_to_users" },
  { label: "Summary", key: "summary" },
  { label: "CreatedAt", key: "created_at" },
];

export const getData = (data) => {
  const mangaData = [{
    en_title:data?.en_title,
    universe_name:data?.universe_name,
    status_name:data?.status_name,
    jp_title:data?.jp_title,
    publisher_name:data?.publisher_name,
    jp_label:data?.jp_label,
    pre_publication_name:data?.pre_publication_name,
    cover_image:data?.cover_image,
    authors:data.authors?.map((author)=>author.name.split(', ')),
    illustrators:data.illustrators?.map((illustrator)=>illustrator.name.split(', ')),
    no_of_volumes:data?.no_of_volumes,
    ongoing:data?.ongoing === true ? "Yes" : "No",
    type_name:data?.type_name,
    genres:data.genres?.map((genre)=>genre.name.split(', ')),
    publics:data.publics?.map((publicsD)=> publicsD.name.split(', ')),
    print_rights_in_france:data?.print_rights_in_france_publisher_name,
    digital_rights_in_france:data?.digital_rights_in_france_publisher_name,
    print_rights_in_us:data?.print_rights_in_us_publisher_name,
    digital_rights_in_us:data?.digital_rights_in_us_publisher_name,
    print_rights_in_de:data?.print_rights_in_de_publisher_name,
    digital_rights_in_de:data?.digital_rights_in_de_publisher_name,
    jp_print_run_1:data?.jp_print_run_1,
    jp_print_run_2:data?.jp_print_run_2,
    jp_print_run_3:data?.jp_print_run_3,
    jp_print_run_4:data?.jp_print_run_4,
    jp_wikipedia_link:data?.jp_wikipedia_link,
    nautiljon:data?.nautiljon,
    low_sales:data?.low_sales,
    medium_sales:data?.medium_sales,
    high_sales:data?.high_sales,
    fr_consumer_price:data?.fr_consumer_price,
    jp_bookwalker_link:data?.jp_bookwalker_link,
    jp_amazon_link:data?.jp_amazon_link,
    jp_raw:data.jp_raw,
    scantrad:data?.scantrad,
    flag_to_users:data?.flag_to_users?.map((flaguser)=>flaguser.first_name.split(', ')),
    summary:data?.summary,
    product_pl:data?.product_pl,
    created_at:moment(data?.created_at).format("YYYY-MM-DD HH:mm:ss")
  }];

  return mangaData
}

function ViewManga() {
  const { state } = useLocation();
  const [mangaQueryParams ,setMangaQueryParams] = useSearchParams();
  const currentPage = mangaQueryParams.currentPage;
  const enTitle = mangaQueryParams.enTitle;
  const jpTitle = mangaQueryParams.jpTitle;
  const fromDate = mangaQueryParams.fromDate;
  const jpRaw = mangaQueryParams.jpRaw;
  const toDate = mangaQueryParams.toDate;
  const userIdString = mangaQueryParams.userIdString;
  const statusIdString = mangaQueryParams.statusIdString;
  const publisherIdString = mangaQueryParams.publisherIdString;
  const typeIdString = mangaQueryParams.typeIdString;
  const prePublicationIdString = mangaQueryParams.prePublicationIdString;
  const is_bookmark = mangaQueryParams.is_bookmark;
  const redirect = mangaQueryParams.redirect !== null || undefined && mangaQueryParams.redirect;
  const is_form_dashboard = mangaQueryParams.is_form_dashboard !== null || undefined && mangaQueryParams.is_form_dashboard ? true : false;
  const [manga,setManga] = useState(state.row);
  const [show, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [currentComment, setCurrentComment] = useState(state.row.comment);
  const [currentLoggedInUser, setCurrentLoggedInUser] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [universeSummaryData, setUniverseSummaryData] = useState([]);
  const [authorSummaryData, setAuthorSummaryData] = useState([]);
  const [illustratorSummaryData, setIllustratorSummaryData] = useState([]);
  const [openUniverseModel, setOpenUniverseModel] = useState(false);
  const [openAuthorModel, setOpenAuthorModel] = useState(false);
  const [openIllustratorModel, setOpenIllustratorModel] = useState(false);
  const [csvData,setCsvData] = useState([])
  const [openUserRatingListModel, setOpenUserRatingListModel] = useState(false);
  const [ratingType, setRatingType] = useState('');
  const [ratingInfo, setRatingInfo] = useState([]);

  const navigate = useNavigate();
  // const updateFlagStatus = () => {
  //   mangaUpdateFlagStatus({
  //     manga_id: state.row.id,
  //   });
  // };
  const { confirm } = Modal;
  const mangaId = state.row.id;
  const isSameMangaViewPage = localStorage.getItem('is_same_manga_view_page');

  const handleDeleteMangaComment = async () => {
    try {
      setCurrentComment("");
      const response = await deleteMangaComment(mangaId);
      if (response.isSuccess) {
        toast.success(`${response.message}`);
      }
    } catch (error) {
      setCurrentComment(state.row.comment);
      toast.error(`${error.message}`);
    }
  };

  const fetchUniverseDetail = async (unvierseId) => {
    setOpenUniverseModel(true);
    const res = await getUniverseSummaryById(unvierseId);

    if (res && res.isSuccess) {
      setUniverseSummaryData(res.data);
    }
  };

  const fetchAuthorsDetail = async (authorId) => {
    setOpenAuthorModel(true);
    const res = await getAuthorSummaryById(authorId);
    if (res && res.isSuccess) {
      setAuthorSummaryData(res.data);
    }
  };

  const fetchIllustratorDetail = async (illustratorId) => {
    setOpenIllustratorModel(true);
    const res = await getIllustratorSummaryById(illustratorId);
    if (res && res.isSuccess) {
      setIllustratorSummaryData(res.data);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getMangaById({
          manga_id: mangaId,
        });
        if(response && response.isSuccess){
          setCurrentComment(response.data.comment)
        }
      } catch (error) {
        toast.error(`${error.message}`);
      }
    })();
  }, []);

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this comment ?",
      icon: <ExclamationCircleFilled />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleDeleteMangaComment();
      },
    });
  };
  useEffect(() => {
    // updateFlagStatus();
    const data = getData(manga)
    setCsvData(data)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
      setCurrentLoggedInUser(loginData.user);
    }
  }, []);

  const handleSearchParams = (baseurl,isback,isRedirect) => {
    if(currentPage) {
      isback ?  baseurl+=`&page=${currentPage}` : baseurl+=`?page=${currentPage}`
    }
    if(enTitle) baseurl+= `&en_title=${ enTitle}`
    if(jpTitle) baseurl+= `&jp_title=${ jpTitle}`
    if(jpRaw) baseurl+= `&jp_raw=${jpRaw}`
    if(fromDate) baseurl+= `&from_date=${fromDate}`
    if(toDate) baseurl+= `&to_date=${toDate}`
    if(prePublicationIdString) baseurl += `&pre_publication_id=${prePublicationIdString}`
    if(userIdString) baseurl+= `&user_id=${userIdString}`
    if(publisherIdString) baseurl += `&publisher_id=${ publisherIdString}`
    if(typeIdString) baseurl += `&type_id=${typeIdString}`
    if(statusIdString) baseurl += `&status_id=${statusIdString}`
    if(is_bookmark || is_bookmark === "false" || is_bookmark === "none") baseurl += `&is_bookmark=${is_bookmark === "true" ? "true" : is_bookmark === "none" ? "none" :"false"}`;
    if(isRedirect && isSameMangaViewPage == 'false' && !is_form_dashboard){
      baseurl += `&redirect=1`
    };

    return baseurl;
  }

  useEffect(()=>{
    setManga(state.row);
  },[state.row])


  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card>
          <Row>
            <Col lg="7">
              <Card className="shadow m-4 detail_card ">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg="5">
                      <h1 className="mb-0"> Manga Detail
                      <Checkbox
                          {...label}
                          icon={<BookmarkBorderIcon />}
                          checkedIcon={<BookmarkIcon />}
                          defaultChecked={manga?.bookmark_id !== null ? true : false}
                          onClick={(e)=>handleMangaBookMark({isChecked:e.target.checked,mangaId:manga?.id})}
                          />
                      </h1>
                    </Col>
                    <Col lg="7" className="add_btn text-right">
                      {permissions &&
                        permissions.map((permission) =>
                          permission === "update-manga" ? (
                            <Button
                              color="primary"
                              onClick={async () => {
                                const res = await getMangaById({
                                  manga_id: state.row.id,
                                });
                                let isSetRedirect = true;
                                let baseURL = `/admin/manga/update-manga/${mangaId}`;  
                                
                                if(redirect == 1 || is_form_dashboard){
                                  baseURL += '?redirect=1'
                                  isSetRedirect = false
                                }
     
                                if(redirect == 2){
                                  baseURL += '?redirect=2'
                                }

                                const url = handleSearchParams(baseURL,false,isSetRedirect); 
                                
                                navigate(
                                  url,
                                  {
                                    state: { row: res?.data },
                                  }
                                );  
                              }}
                            >
                              <span className="indicator-label">Edit</span>
                            </Button>
                          ) : (
                            ""
                          )
                        )}

                      <CSVLink
                          data={csvData}
                          headers={headers}
                          filename={genrateCsvFileName(`manga_${manga.en_title}`)}
                          className="btn btn-primary"
                          target="_blank"
                        >
                          Export to CSV
                      </CSVLink>

                      <Button
                        color="primary"
                        className="m-2"
                        onClick={() =>{
                          navigate(`/admin/manga/view-Manga-Print-Selection/${mangaId}`,{state:{row:manga}});
                        }}
                      >
                        <span className="me-2">Print</span>
                      </Button>

                      <Button
                        color="primary"
                        className="m-2"
                        onClick={() => {
                          if(isSameMangaViewPage == 'true'){
                            navigate(-1)
                            localStorage.setItem('is_same_manga_view_page',false);
                          }
                    
                          if(redirect == 1){
                            navigate(-1)
                          }
                          
                          if(redirect == 2 && redirect !== 1 || !redirect){
                            let baseURL = `/admin/manga/manga-list?id=${mangaId}`;
                            const url = handleSearchParams(baseURL,true);
                            navigate(url);
                            localStorage.setItem('is_same_manga_view_page',false);
                          }
                         
                        }}
                      >
                        <span className="me-2">Back</span>
                      </Button>   
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="custom-margin-top">
               
                  <Row>
                    {manga?.en_title ? (
                      <>
                        <Col lg="4"> EN Title</Col>
                        <Col lg="8">
                          <div className="text-dark h2">{manga.en_title}</div>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row className="mt-1">
                    {manga?.universe_name ? (
                      <>
                        <Col lg="4">Universe Name</Col>
                        <Col lg="8">
                          <span
                            className="primary-text-color"
                            style={{ cursor: "pointer"}}
                            onClick={() =>
                              fetchUniverseDetail(manga.universe_id)
                            }
                          >
                            {manga?.universe_name}
                          </span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row className="mt-1">
                    {manga?.jp_title ? (
                      <>
                        <Col lg="4">JP Title</Col>
                        <Col lg="8">
                          <span className="text-dark">{manga?.jp_title}</span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  {manga?.status_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Status</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.status_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.publisher_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP publisher</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                  {manga?.pre_publication_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Pre Publication</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.pre_publication_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.no_of_volumes ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">No. of vol</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.no_of_volumes}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.ongoing || !manga?.ongoing && manga?.ongoing !== null ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Ongoing</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga.ongoing === true ? "Yes" : "No"}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.type_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Type</Col>
                        <Col lg="8">
                          <span className="text-dark">{manga?.type_name}</span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.print_rights_in_france ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Print Rights In France</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.print_rights_in_france_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.digital_rights_in_france ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Digital Rights In France</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.digital_rights_in_france_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.print_rights_in_us ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Print Rights In US</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.print_rights_in_us_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.digital_rights_in_us ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Digital Rights In US</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.digital_rights_in_us_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.print_rights_in_de ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Print Rights In DE</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.print_rights_in_de_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.digital_rights_in_de ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Digital Rights In DE</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.digital_rights_in_de_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.jp_print_run_1 ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Print Run 1</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.jp_print_run_1}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.jp_print_run_2 ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Print Run 2</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.jp_print_run_2}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.jp_print_run_3 ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Print Run 3</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.jp_print_run_3}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.jp_print_run_4 ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Print Run 4</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.jp_print_run_4}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.jp_wikipedia_link ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Wikipedia</Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={manga?.jp_wikipedia_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {manga?.jp_wikipedia_link?.length > 50
                                ? `${manga?.jp_wikipedia_link.slice(0, 40)}...`
                                : manga?.jp_wikipedia_link}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}


                  {manga?.nautiljon ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Nautiljon</Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={manga?.nautiljon}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {manga?.nautiljon?.length > 50
                                ? `${manga?.nautiljon.slice(0, 40)}...`
                                : manga?.nautiljon}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.jp_amazon_link ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Amazon</Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={manga?.jp_amazon_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {manga?.jp_amazon_link?.length > 50
                                ? `${manga?.jp_amazon_link.slice(0, 40)}...`
                                : manga?.jp_amazon_link}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                {manga?.jp_raw ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Raw</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga?.jp_raw}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.scantrad ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">ScanTrad</Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={manga?.scantrad}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {manga?.scantrad?.length > 50
                                ? `${manga?.scantrad.slice(0, 40)}...`
                                : manga?.scantrad}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {manga?.jp_bookwalker_link ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP BookWalker</Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={manga?.jp_bookwalker_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {manga?.jp_bookwalker_link?.length > 50
                                ? `${manga?.jp_bookwalker_link.slice(0, 40)}...`
                                : manga?.jp_bookwalker_link}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                  {permissions &&
                    permissions.map((permission) =>
                      permission === "view-manga-low-sale" ? (
                        <>
                          {manga?.low_sales ? (
                              <Row className="mt-1">
                                <Col lg="4">Low Sales</Col>
                                <Col lg="8">
                                  <span className="text-dark">
                                    {manga?.low_sales}
                                  </span>
                                </Col>
                              </Row>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )
                  )}

                  {permissions &&
                      permissions.map((permission) =>
                        permission === "view-manga-medium-sale" ? (
                          <>
                            {manga?.medium_sales ? (
                              <>
                                <Row className="mt-1">
                                  <Col lg="4">Medium Sales</Col>
                                  <Col lg="8">
                                    <span className="text-dark">
                                      {manga?.medium_sales}
                                    </span>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )
                  )}

                  {permissions &&
                        permissions.map((permission) =>
                          permission === "view-manga-high-sale" ? (
                            <>
                                {manga?.high_sales ? (
                                <>
                                  <Row className="mt-1">
                                    <Col lg="4">High Sales</Col>
                                    <Col lg="8">
                                      <span className="text-dark">
                                        {manga?.high_sales}
                                      </span>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )
                   )}
                  {manga?.fr_consumer_price ? (
                        <>
                          <Row className="mt-1">
                            <Col lg="4">FR Consumer Price</Col>
                            <Col lg="8">
                              <span className="text-dark">
                                {manga?.fr_consumer_price}
                              </span>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                   )}       
                  {manga?.summary ? (
                    <>
                      <Row className="mt-21">
                        {manga?.summary ? (
                          <>
                            <Col lg="4">Summary</Col>
                            <Col lg="8">
                              <span className="text-dark">
                                {manga?.summary}
                              </span>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                {permissions &&
                      permissions.map((permission) =>
                        permission === "view-manga-product-pl" ? (
                          <>
                              {manga?.product_pl ? (
                                <>
                                <Row className="mt-1">
                                  <Col lg="4">Product P&L</Col>
                                  <Col lg="8">
                                      <span className="link primary-text-color text-wrap">
                                          <a
                                            href={manga?.product_pl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {manga?.product_pl?.length >
                                            50
                                              ? `${manga?.product_pl.slice(
                                                  0,
                                                  40
                                                )}...`
                                              : manga?.product_pl}
                                          </a>
                                      </span>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )
                )}
                  {manga?.created_at ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">CreatedAt</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {manga.created_at
                              ? moment(manga.created_at).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : ""}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg="2" className="columns"></Col>
            <Col lg="3">
              <Card className="text-right shadow m-4 view_cards">
                <CardBody>
                  {manga?.cover_image ? (
                    <>
                      <CardImg
                        src={manga?.cover_image}
                        alt="Upload"
                        className=" overflow-hidden"
                        onClick={async () => {
                          setShowModal(true);
                          setImage(manga.cover_image);
                        }}
                      />
                    </>
                  ) : (
                    <CardImg
                      src={require("../../../assets/images/no-image.png")}
                      alt="Upload"
                      className=" overflow-hidden"
                      onClick={async () => {
                        setShowModal(true);
                        setImage(
                          require("../../../assets/images/no-image.png")
                        );
                      }}
                    />
                  )}
                </CardBody>
              </Card>
              <Card className="text-left shadow m-4 view_cards">
                <CardBody>
                   <h4>Ratings</h4>

                   <div className="d-inline-flex">
                        <div>
                            <img 
                              src={`${process.env.PUBLIC_URL}/rating-images/1.jpg`} 
                              alt="rating-1" 
                              style={{ width: '30%' }} 
                              />
                            <span 
                              className={`m-1 ${manga?.rating_one_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`}
                              onClick={()=>{
                                if(manga?.rating_one_counts !== "0"){
                                  setRatingType(1);
                                  setRatingInfo(manga);
                                  setOpenUserRatingListModel(true);
                                  }
                              }} 
                             > 
                             {manga?.rating_one_counts || 0}
                            </span> 
                        </div>

                        <div className="pe-auto">
                            <img 
                              src={`${process.env.PUBLIC_URL}/rating-images/2.jpg`} 
                              alt="rating-2" 
                              style={{ width: '30%' }} 
                            />
                           <span 
                            className={`m-1 ${manga?.rating_two_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`}
                            onClick={()=>{
                              if(manga?.rating_two_counts !== "0"){
                                setRatingType(2);
                                setRatingInfo(manga);
                                setOpenUserRatingListModel(true);
                                }
                            }} 
                            >
                            {manga?.rating_two_counts || 0}
                           </span> 
                        </div>

                        <div>
                            <img 
                              src={`${process.env.PUBLIC_URL}/rating-images/3.jpg`} 
                              alt="rating-3" 
                              style={{ width: '27%'}} 
                            />
                            <span 
                              className={`m-2 ${manga?.rating_three_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`}
                              onClick={()=>{
                                if(manga?.rating_three_counts !== "0"){
                                  setRatingType(3);
                                  setRatingInfo(manga);
                                  setOpenUserRatingListModel(true);
                                  }
                              }} 
                            >
                              {manga?.rating_three_counts || 0}
                            </span> 
                        </div>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            {manga?.authors.length ? (
              <Col lg="4">
                <Card className=" shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Authors</h3>
                    <Col>
                      {manga.authors.map((author, index) => (
                        <li
                          className="primary-text-color"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            fetchAuthorsDetail(author.id)
                          }
                          key={index}
                        >
                          {author.name}
                        </li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {manga?.authors.length ? <Col lg="2"></Col> : ""}
            {manga?.genres.length ? (
              <Col lg="4">
                <Card className="me-2 shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Genre</h3>
                    <Col>
                      {manga.genres.map((genre, index) => (
                        <li key={index}>{genre.name}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            {manga?.illustrators.length ? (
              <Col lg="4">
                <Card className=" shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Illustrator</h3>
                    <Col>
                      {manga.illustrators.map((illustrators, index) => (
                        <li className="primary-text-color"
                        style={{
                          cursor: "pointer",
                        }}  onClick={() =>
                          fetchIllustratorDetail(
                            illustrators.id
                          )
                        } key={index}>{illustrators.name}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {manga?.illustrators.length ? <Col lg="2"></Col> : ""}

            {manga?.publics.length ? (
              <Col lg="4">
                <Card className="me-2 shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Public</h3>
                    <Col>
                      {manga.publics.map((publicsD, index) => (
                        <li key={index}>{publicsD.name}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col lg="12">
              <Card className="me-2 shadow m-4 ">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Comments</h3>
                  {currentComment || manga.all_comments ? (
                    <>
                      <Row>
                        {currentComment ? (
                          <Col lg="3">
                            <Card className=" shadow mt-2">
                              <CardHeader className="h4">
                                {currentLoggedInUser.first_name}{" "}
                                {currentLoggedInUser.last_name}
                                <RiDeleteBin6Line
                                  onClick={showDeleteConfirm}
                                  className="ml-7"
                                  style={{ cursor: "pointer" }}
                                  size={26}
                                  color="#e62a2e"
                                />
                              </CardHeader>
                              <CardBody>{currentComment}</CardBody>
                            </Card>
                          </Col>
                        ) : (
                          ""
                        )}
                        {manga?.all_comments?.length > 0
                          ? manga.all_comments.map((x) => (
                              <>
                                <Col lg="3">
                                  <Card className="shadow mt-2 ">
                                    <CardHeader className="h4">
                                      {x.first_name} {x.last_name}
                                    </CardHeader>
                                    <CardBody>{x.comment}</CardBody>
                                  </Card>
                                </Col>
                              </>
                            ))
                          : ""}
                      </Row>
                    </>
                  ) : (
                    "No Comments"
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ImageModal
            show={show}
            image={image}
            onClose={() => {
              setShowModal(false);
            }}
          />
          <UniverseModal openUniverseModel = {openUniverseModel} setOpenUniverseModel={setOpenUniverseModel} universeSummaryData = {universeSummaryData} currentMangaId={mangaId} isPageReload = {isPageReload.MANGA} isLinkFunctionalityActive={true} />
          <AuthorModal openAuthorModel = {openAuthorModel} setOpenAuthorModel={setOpenAuthorModel} authorSummaryData={authorSummaryData} currentMangaId={mangaId} isPageReload = {isPageReload.MANGA} isLinkFunctionalityActive={true}/>
          <IllustratorModal openIllustratorModel = {openIllustratorModel} setOpenIllustratorModel={setOpenIllustratorModel} illustratorSummaryData={illustratorSummaryData} currentMangaId={mangaId} isPageReload = {isPageReload.MANGA} isLinkFunctionalityActive={true} />
          <RatingUserListModal openUserRatingListModel={openUserRatingListModel} setOpenUserRatingListModel={setOpenUserRatingListModel} ratingInfo={ratingInfo} ratingType = {ratingType} title = "Manga Rating Details" />
        </Card>
      </Container>
    </>
  );
}


export default ViewManga;
