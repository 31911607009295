import Dashboard from "views/Dashboard.js";
import Login from "views/auth/Login.js";
import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPassword";
import CreateManga from "views/media/manga/CreateManga";
import ViewManga from "views/media/manga/ViewManga";
import CreateLightNovel from "views/media/lightNovel/CreateLightNovel";
import CreateWebtoon from "views/media/webtoon/CreateWebtoon";
import CreateAnime from "views/media/anime/CreateAnime";
import CreateVideoGame from "views/media/videoGame/CreateVideoGame";
import CreateUser from "views/user/CreateUser";
import UserList from "views/user/UserList";
import UpdateUser from "views/user/UpdateUser";
import LightNovelList from "views/media/lightNovel/LightNovelList";
import UpdateLightNovel from "views/media/lightNovel/UpdateLightNovel";
import MangaList from "views/media/manga/MangaList";
import UpdateManga from "views/media/manga/UpdateManga";
import CreatePrePublication from "views/prePublication/CreatePrePublication";
import AnimeList from "views/media/anime/AnimeList";
import UpdateAnime from "views/media/anime/UpdateAnime";
import UpdateVideoGame from "views/media/videoGame/UpdateVideoGame";
import VideoGameList from "views/media/videoGame/VideoGameList";
import UpdateWebtoon from "views/media/webtoon/UpdateWebtoon";
import WebtoonList from "views/media/webtoon/WebtoonList";
import ViewWebtoon from "views/media/webtoon/ViewWebtoon";
import CreateUniverse from "views/universe/CreateUniverse";
import UniverseList from "views/universe/UniverseList";
import ViewUniverse from "views/universe/ViewUniverse";
import UpdateUniverse from "views/universe/UpdateUniverse";
import ViewPrePublication from "views/prePublication/ViewPrePublication";
import PrePublicationList from "views/prePublication/PrePublicationList";
import UpdatePrePublication from "views/prePublication/UpdatePrePublication";
import CreateStatus from "views/status/CreateStatus";
import StatusList from "views/status/StatusList";
import UpdateStatus from "views/status/UpdateStatus";
import CreatePublisher from "views/publisher/CreatePublisher";
import UpdatePublisher from "views/publisher/UpdatePublisher";
import PublisherList from "views/publisher/PublisherList";
import CreateType from "views/type/CreateType";
import TypeList from "views/type/TypeList";
import UpdateType from "views/type/UpdateType";
import CreateGenre from "views/genre/CreateGenre";
import GenreList from "views/genre/GenreList";
import UpdateGenre from "views/genre/UpdateGenre";
import CreateIllustrator from "views/illustrator/CreateIllustrator";
import IllustratorList from "views/illustrator/IllustratorList";
import UpdateIllustrator from "views/illustrator/UpdateIllustrator";
import CreatePublic from "views/public/CreatePublic";
import PublicList from "views/public/PublucList";
import UpdatePublic from "views/public/UpdatePublic";
import AuthorList from "views/author/AuthorList";
import ViewAuthor from "views/author/ViewAuthor";
import CreateAuthor from "views/author/CreateAurhor";
import UpdateAuthor from "views/author/UpdateAuthor";
import CreateRole from "views/role/CreateRole";
import RoleList from "views/role/RoleList";
import UpdateRole from "views/role/UpdateRole";
import ViewLightNovel from "views/media/lightNovel/ViewLightNovel";
import ViewLightNovelPrintSelection from "views/media/lightNovel/ViewLightNovelPrintSelection";
import ViewWebtoonPrintSelection from "views/media/webtoon/ViewWebtoonPrintSelection";
import ViewMangaPrintSelection from "views/media/manga/ViewMangaPrintSelection";
import ImportLightNovel from "views/media/lightNovel/ImportLightNovel";
import ImportManga from "views/media/manga/ImportManga";
import ImportWebtoon from "views/media/webtoon/ImportWebtoon";
import ImportAnime from "views/media/anime/ImportAnime";
import ImportVideoGame from "views/media/videoGame/ImportVideogame";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Dashboard />,
    layout: "/admin",
    slug: "dashboard",
    visibleOnMenu: true,
  },
  {
    path: "/manga/create-manga",
    name: "Create Manga",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreateManga />,
    layout: "/admin",
    slug: "create-manga",
    visibleOnMenu: false,
  },
  {
    path: "/manga/manga-list",
    name: "Manga ",
    icon: "fa fa-female text-primary",
    component: <MangaList />,
    layout: "/admin",
    slug: "view-manga",
    visibleOnMenu: true,
  },
  {
    path: "/manga/update-manga/:id",
    name: "Update manga",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdateManga />,
    layout: "/admin",
    slug: "update-manga",
    visibleOnMenu: false,
  },
  {
    path: "/manga/view-manga/:id",
    name: "view Manga",
    // icon: "ni ni-tv-2 text-primary",
    component: <ViewManga />,
    layout: "/admin",
    slug: "view-manga",
    visibleOnMenu: false,
  },
  {
    path: "/manga/view-Manga-Print-Selection/:id",
    name: "View Manga Print Selection",
    // icon: "ni ni-tv-2 text-primary",
    component: <ViewMangaPrintSelection />,
    layout: "/admin",
    slug: "view-manga",
    visibleOnMenu: false,
  },
  {
    path: "/manga/import-manga",
    name: "Import Manga",
    component: <ImportManga />,
    layout: "/admin",
    slug: "create-manga",
    visibleOnMenu: false,
  },
  {
    path: "/lightNovel/light-Novel-list",
    name: "Light Novel",
    icon: "fa fa-book text-primary",
    component: <LightNovelList />,
    layout: "/admin",
    slug: "view-light-novel",
    visibleOnMenu: true,
  },
  {
    path: "/lightNovel/createLight-Novel",
    name: "Create Light Novel",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreateLightNovel />,
    layout: "/admin",
    slug: "create-light-novel",
    visibleOnMenu: false,
  },
  {
    path: "/lightNovel/view-Light-Novel/:id",
    name: "view Light Novel",
    // icon: "ni ni-tv-2 text-primary",
    component: <ViewLightNovel />,
    layout: "/admin",
    slug: "view-light-novel",
    visibleOnMenu: false,
  },
  {
    path: "/lightNovel/view-Light-Novel-Print-Selection/:id",
    name: "View Light Novel Print Selection",
    // icon: "ni ni-tv-2 text-primary",
    component: <ViewLightNovelPrintSelection />,
    layout: "/admin",
    slug: "view-light-novel",
    visibleOnMenu: false,
  },
  {
    path: "/lightNovel/updateLightNovel/:id",
    name: "Update Light Novel",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdateLightNovel />,
    layout: "/admin",
    slug: "update-light-novel",
    visibleOnMenu: false,
  },
  {
    path: "/lightNovel/import-light-novel",
    name: "Import Light Novel",
    component: <ImportLightNovel />,
    layout: "/admin",
    slug: "create-light-novel",
    visibleOnMenu: false,
  },
  {
    path: "/webtoon/create-Webtoon",
    name: "Create Webtoon",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreateWebtoon />,
    layout: "/admin",
    slug: "create-webtoon",
    visibleOnMenu: false,
  },
  {
    path: "/webtoon/update-webtoon/:id",
    name: "Update Webtoon",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdateWebtoon />,
    layout: "/admin",
    slug: "update-webtoon",
    visibleOnMenu: false,
  },
  {
    path: "/webtoon/webtoon-list",
    name: "Webtoon ",
    icon: "fa fa-snowflake text-primary",
    component: <WebtoonList />,
    layout: "/admin",
    slug: "view-webtoon",
    visibleOnMenu: true,
  },
  {
    path: "/webtoon/view-webtoon/:id",
    name: "view Webtoon",
    // icon: "ni ni-tv-2 text-primary",
    component: <ViewWebtoon />,
    layout: "/admin",
    slug: "view-webtoon",
    visibleOnMenu: false,
  },
  {
    path: "/webtoon/view-Webtoon-Print-Selection/:id",
    name: "View Webtoon Print Selection",
    // icon: "ni ni-tv-2 text-primary",
    component: <ViewWebtoonPrintSelection />,
    layout: "/admin",
    slug: "view-webtoon",
    visibleOnMenu: false,
  },
  {
    path: "/webtoon/import-webtoon",
    name: "Import Webtoon",
    component: <ImportWebtoon />,
    layout: "/admin",
    slug: "create-webtoon",
    visibleOnMenu: false,
  },
  {
    path: "/anime/createAnime",
    name: "Create Anime",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreateAnime />,
    layout: "/admin",
    slug: "create-anime",
    visibleOnMenu: false,
  },
  {
    path: "/anime/anime-list",
    name: "Anime",
    icon: "fa fa-female text-primary",
    component: <AnimeList />,
    layout: "/admin",
    slug: "view-anime",
    visibleOnMenu: true,
  },
  {
    path: "/anime/update-anime/:id",
    name: "Update Anime",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdateAnime />,
    layout: "/admin",
    slug: "update-anime",
    visibleOnMenu: false,
  },
  {
    path: "/anime/import-anime",
    name: "Import Anime",
    component: <ImportAnime />,
    layout: "/admin",
    slug: "create-anime",
    visibleOnMenu: false,
  },
  {
    path: "/videoGame/create-videoGame",
    name: "Create Video Game",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreateVideoGame />,
    layout: "/admin",
    slug: "create-video-game",
    visibleOnMenu: false,
  },
  {
    path: "/videoGame/update-videoGame/:id",
    name: "Update Video Game",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdateVideoGame />,
    layout: "/admin",
    slug: "update-video-game",
    visibleOnMenu: false,
  },
  {
    path: "/videoGame/videoGame-list",
    name: "Video Game ",
    icon: "fa fa-gamepad text-primary",
    component: <VideoGameList />,
    layout: "/admin",
    slug: "view-video-game",
    visibleOnMenu: true,
  },
  {
    path: "/videoGame/import-video-game",
    name: "Import Video Game",
    component: <ImportVideoGame />,
    layout: "/admin",
    slug: "create-video-game",
    visibleOnMenu: false,
  },
  {
    path: "/universe/create-universe",
    name: "Create Universe",
    slug: "create-universe",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreateUniverse />,
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/universe/universe-list",
    name: "Universe ",
    slug: "view-universe",
    icon: "fa fa-university text-primary",
    component: <UniverseList />,
    layout: "/admin",
    visibleOnMenu: true,
  },
  {
    path: "/universe/view-universe/:id",
    name: "View Universe ",
    slug: "view-universe",
    icon: "fa fa-university text-primary",
    component: <ViewUniverse />,
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/universe/update-universe/:id",
    name: "Update Universe",
    slug: "update-universe",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdateUniverse />,
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/author/create-author",
    name: "Create Author",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreateAuthor />,
    layout: "/admin",
    slug: "create-author",
    visibleOnMenu: false,
  },
  {
    path: "/author/author-list",
    name: "Author",
    icon: "fa fa-user-circle text-primary",
    component: <AuthorList />,
    slug: "view-author",
    layout: "/admin",
    visibleOnMenu: true,
  },
  {
    path: "/author/view-author/:id",
    name: "View Author",
    icon: "fa fa-user-circle text-primary",
    component: <ViewAuthor />,
    slug: "view-author",
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/author/update-author/:id",
    name: "Update Author",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdateAuthor />,
    slug: "update-author",
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/illustrator/create-illustrator",
    name: "Create Illustrator",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreateIllustrator />,
    layout: "/admin",
    slug: "create-illustrator",
    visibleOnMenu: false,
  },
  {
    path: "/illustrator/illustrator-list",
    name: "Illustrator",
    icon: "fa fa-cube text-primary",
    component: <IllustratorList />,
    layout: "/admin",
    slug: "view-illustrator",
    visibleOnMenu: true,
  },
  {
    path: "/illustrator/update-illustrator/:id",
    name: "Update Illustrator",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdateIllustrator />,
    layout: "/admin",
    slug: "update-illustrator",
    visibleOnMenu: false,
  },
  {
    path: "/publisher/create-publisher",
    name: "Create Publisher",
    slug: "create-publisher",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreatePublisher />,
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/publisher/publisher-list",
    name: "Publisher ",
    slug: "view-publisher",
    icon: "fa fa-users text-primary",
    component: <PublisherList />,
    layout: "/admin",
    visibleOnMenu: true,
  },
  {
    path: "/publisher/update-publisher/:id",
    name: "Update Publisher",
    slug: "update-publisher",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdatePublisher />,
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/prePublication/create-Prepublication",
    name: "Create Pre-Publication",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreatePrePublication />,
    slug: "create-pre-publication",
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/prePublication/Prepublication-list",
    name: "Pre-Publication ",
    icon: "ni ni-collection text-primary",
    component: <PrePublicationList />,
    slug: "view-pre-publication",
    layout: "/admin",
    visibleOnMenu: true,
  },
  {
    path: "/prePublication/view-Prepublication/:id",
    name: "View Pre-Publication ",
    icon: "ni ni-collection text-primary",
    component: <ViewPrePublication />,
    slug: "view-pre-publication",
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/prePublication/update-Prepublication/:id",
    name: "Update Pre-Publication",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdatePrePublication />,
    layout: "/admin",
    slug: "update-pre-publication",
    visibleOnMenu: false,
  },
  {
    path: "/genre/create-genre",
    name: "Create Genre",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreateGenre />,
    slug: "create-genre",
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/genre/genre-list",
    name: "Genre",
    icon: "fa fa-music text-primary",
    component: <GenreList />,
    slug: "view-genre",
    layout: "/admin",
    visibleOnMenu: true,
  },
  {
    path: "/genre/update-genre/:id",
    name: "Update Genre",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdateGenre />,
    slug: "update-genre",
    layout: "/admin",
    visibleOnMenu: false,
  },

 
  {
    path: "/type/create-type",
    name: "Create Type",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreateType />,
    layout: "/admin",
    slug: "create-type",
    visibleOnMenu: false,
  },
  {
    path: "/type/type-list",
    name: "Type ",
    icon: "ni ni-air-baloon text-primary",
    component: <TypeList />,
    slug: "view-type",
    layout: "/admin",
    visibleOnMenu: true,
  },
  {
    path: "/type/update-type/:id",
    name: "Update Type",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdateType />,
    slug: "update-type",
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/public/create-public",
    name: "Create Public",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreatePublic />,
    layout: "/admin",
    slug: "create-public",
    visibleOnMenu: false,
  },
  {
    path: "/public/public-list",
    name: "Public",
    icon: "fa fa-users text-primary",
    component: <PublicList />,
    layout: "/admin",
    slug: "view-public",
    visibleOnMenu: true,
  },
  {
    path: "/public/update-public/:id",
    name: "Update Public",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdatePublic />,
    layout: "/admin",
    slug: "update-public",
    visibleOnMenu: false,
  },
  {
    path: "/status/create-status",
    name: "Create Status",
    slug: "create-status",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreateStatus />,
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/status/update-status/:id",
    name: "Update Status",
    slug: "update-status",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdateStatus />,
    layout: "/admin",
    visibleOnMenu: false,
  },
  {
    path: "/status/status-list",
    name: "Status ",
    slug: "view-status",
    icon: "fa fa-check-square text-primary",
    component: <StatusList />,
    layout: "/admin",
    visibleOnMenu: true,
  },
  {
    path: "/role/create-role",
    name: "Create Role",
    // icon: "ni ni-tv-2 text-primary",
    component: <CreateRole />,
    layout: "/admin",
    slug: "create-role",
    visibleOnMenu: false,
  },
  {
    path: "/role/role-list",
    name: "Role",
    icon: "fa fa-bullseye text-primary",
    slug: "view-role",
    component: <RoleList />,
    layout: "/admin",
    visibleOnMenu: true,
  },
  {
    path: "/role/update-role/:id",
    name: "Update Role",
    slug: "update-role",
    // icon: "ni ni-tv-2 text-primary",
    component: <UpdateRole />,
    layout: "/admin",
    visibleOnMenu: false,
  },

  {
    path: "/user/createUser",
    name: "Create Users",
    component: <CreateUser />,
    layout: "/admin",
    slug: "create-user",
    visibleOnMenu: false,
  },
  {
    path: "/user/updateUser/:id",
    name: "Update Users",
    component: <UpdateUser />,
    layout: "/admin",
    slug: "update-user",
    visibleOnMenu: false,
  },
  {
    path: "/user/UserList",
    name: "Users ",
    icon: "fa fa-user text-primary",
    component: <UserList />,
    layout: "/admin",
    slug: "view-user",
    visibleOnMenu: true,
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
    visibleOnMenu: false,
  },
  {
    path: "/forgot-password",
    name: "",
    icon: "ni ni-key-25 text-info",
    component: <ForgotPassword />,
    layout: "/auth",
    visibleOnMenu: false,
  },
  {
    path: "/reset-password",
    name: "",
    icon: "ni ni-circle-08 text-pink",
    component: <ResetPassword />,
    layout: "/auth",
    visibleOnMenu: false,
  },
];
export default routes;
