import Header from "components/Headers/Header";
import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { updatePublisher } from "utils/endpoint";
import * as yup from "yup";
import clsx from "clsx";
import Select from "react-select";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { publisherType } from "utils/enums";
import useSearchParams from "utils/useSearchParams";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  publisher_type: yup.string().required("Publisher Type is required"),

});

function UpdatePublisher() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [publisherQueryParams ,setPublisherQueryParams] = useSearchParams();
  const currentPage = publisherQueryParams.currentPage;
  const fromDate = publisherQueryParams.fromDate;
  const toDate = publisherQueryParams.toDate;
  const name = publisherQueryParams.name;
  const publisher_type = publisherQueryParams.publisher_type;
  const is_active = publisherQueryParams.is_active;
  const [publisher] = useState(state.row);
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] =UseFullPageLoader();
  const params = useParams();
  const { id } = params;

  const handleSearchParams = () => {
    let url = `/admin/publisher/publisher-list?id=${id}&page=${currentPage}`;
    if(name) url+= `&name=${name}`
    if(fromDate) url+= `&from_date=${fromDate}`
    if(toDate) url+= `&to_date=${toDate}`
    if(publisher_type) url += `&publisher_type=${publisher_type}`
    if(is_active || is_active === "false") url += `&is_active=${is_active === "true" ? "true" : is_active === "none" ? "none" :"false"}`;
    return url;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: publisher?.name,
        publisher_type: publisher?.publisher_type,
        is_active: publisher?.is_active ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader()
        const updateData = {
            name: values.name,
            publisher_type: values.publisher_type,
            is_active: values.is_active,
        };
        const result = await updatePublisher(id, updateData);
        if (result?.isSuccess) {
          toast.success(`${result.message}`);
          setLoading(false);
          hideLoader()
          resetForm();
          const url =  handleSearchParams()
          navigate(url);
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader()
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />

            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Update Publisher</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              const url =  handleSearchParams();
                              navigate(url);
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                      
                    </CardHeader>
                    <CardBody>
                    <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                            Name
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.name) && touched.name,
                              })}
                              id="name"
                              name="name"
                              placeholder="Name"
                              type="text"
                              value={values.name}
                              onChange={handleChange}
                            />
                             <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="name" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                            Publisher Type
                            </label>
                            <Select
                             defaultValue={{
                              label: values.publisher_type,
                            }}
                              name="publisher_type"
                              id="publisher_type"
                              className=""
                              options={Array.from(publisherType).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("publisher_type", event.value);
                              }}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="publisher_type" />
                            </div>
                          </FormGroup>
                        </Col>
                       
                      </Row>
                     
                      <Row>
                        <Col lg="3">
                        <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary">
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Update Publisher
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
}

export default UpdatePublisher;
