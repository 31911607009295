import Header from "components/Headers/Header";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CardImg,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import ImageModal from "utils/ImageModal";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { toast } from "react-toastify";
import { deleteLightNovelComment,getLightNovelById } from "utils/endpoint";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Modal } from "antd";
import { getAuthorSummaryById } from "utils/endpoint";
import { getUniverseSummaryById } from "utils/endpoint";
import { getIllustratorSummaryById } from "utils/endpoint";
import AuthorModal from "utils/authorModal";
import IllustratorModal from "utils/illustratorModal";
import UniverseModal from "utils/universeModal";
import useSearchParams from "utils/useSearchParams";
import Checkbox from '@mui/material/Checkbox';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { handleLightNovelBookMark } from "./LightNovelList";
import { CSVLink } from "react-csv";
import { genrateCsvFileName } from "utils/Commen";
import  isPageReload  from "utils/enums";
import RatingUserListModal from "utils/ratingUserListModal";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const headers = [
  { label: "EN Title", key: "en_title" },
  { label: "Universe Name", key: "universe_name" },
  { label: "Status", key: "status_name" },
  { label: "JP Title", key: "jp_title" },
  { label: "JP publisher", key: "publisher_name" },
  { label: "Pre Publication", key: "pre_publication_name" },
  { label: "Authors", key: "authors" },
  { label: "Cover Image", key: "cover_image" },
  { label: "Illustrators", key: "illustrators" },
  { label: "No. of vol", key: "no_of_volumes" },
  { label: "Ongoing", key: "ongoing" },
  { label: "Type", key: "type_name" },
  { label: "Genres", key: "genres" },
  { label: "Publics", key: "publics" },
  { label: "Print Rights In France", key: "print_rights_in_france" },
  { label: "Digital Rights In France", key: "digital_rights_in_france" },
  { label: "Print Rights In US", key: "print_rights_in_us" },
  { label: "Digital Rights In US", key: "digital_rights_in_us" },
  { label: "Print Rights In DE", key: "print_rights_in_de" },
  { label: "Digital Rights In DE", key: "digital_rights_in_de" },
  { label: "JP Print Run 1", key: "jp_print_run_1" },
  { label: "JP Print Run 2", key: "jp_print_run_2" },
  { label: "JP Print Run 3", key: "jp_print_run_3" },
  { label: "JP Print Run 4", key: "jp_print_run_4" },
  { label: "Product P&L", key: "product_pl" },
  { label: "JP Wikipedia", key: "jp_wikipedia_link" },
  { label: "Nautiljon", key: "nautiljon" },
  { label: "JP Amazon", key: "jp_amazon_link" },
  { label: "JP BookWalker", key: "jp_bookwalker_link" },
  { label: "JP Raw", key: "jp_raw" },
  { label: "ScanTrad", key: "scantrad" },
  { label: "Low Sales", key: "low_sales" },
  { label: "Medium Sales", key: "medium_sales" }, 
  { label: "High Sales", key: "high_sales" },
  { label: "FR Consumer Price", key: "fr_consumer_price" },
  { label: "Flag To Users", key: "flag_to_users" },
  { label: "Summary", key: "summary" },
  { label: "CreatedAt", key: "created_at" },
];

export const getData = (data) => {
  const lightNovelData = [{
    en_title:data.en_title,
    universe_name:data.universe_name,
    status_name:data.status_name,
    jp_title:data.jp_title,
    publisher_name:data.publisher_name,
    pre_publication_name:data.pre_publication_name,
    cover_image:data.cover_image,
    authors:data.authors?.map((author)=>author.name.split(', ')),
    illustrators:data.illustrators?.map((illustrator)=>illustrator.name.split(', ')),
    no_of_volumes:data.no_of_volumes,
    ongoing:data.ongoing === true ? "Yes" : "No",
    type_name:data.type_name,
    genres:data.genres?.map((genre)=>genre.name.split(', ')),
    publics:data.publics?.map((publicsD)=> publicsD.name.split(', ')),
    print_rights_in_france:data.print_rights_in_france_publisher_name,
    digital_rights_in_france:data.digital_rights_in_france_publisher_name,
    print_rights_in_us:data.print_rights_in_us_publisher_name,
    digital_rights_in_us:data.digital_rights_in_us_publisher_name,
    print_rights_in_de:data.print_rights_in_de_publisher_name,
    digital_rights_in_de:data.digital_rights_in_de_publisher_name,
    jp_print_run_1:data.jp_print_run_1,
    jp_print_run_2:data.jp_print_run_2,
    jp_print_run_3:data.jp_print_run_3,
    jp_print_run_4:data.jp_print_run_4,
    jp_wikipedia_link:data.jp_wikipedia_link,
    nautiljon:data.nautiljon,
    low_sales:data.low_sales,
    medium_sales:data.medium_sales,
    high_sales:data.high_sales,
    fr_consumer_price:data.fr_consumer_price,
    jp_bookwalker_link:data.jp_bookwalker_link,
    jp_amazon_link:data.jp_amazon_link,
    jp_raw:data.jp_raw,
    scantrad:data.scantrad,
    flag_to_users:data.flag_to_users?.map((flaguser)=>flaguser.first_name.split(', ')),
    summary:data.summary,
    product_pl:data.product_pl,
    created_at:moment(data.created_at).format("YYYY-MM-DD HH:mm:ss")
  }];

  return lightNovelData
}

function ViewLightNovel() {
  const { state } = useLocation();
  const [lightNovelQueryParams ,setLightNovelQueryParams] = useSearchParams();
  const currentPage = lightNovelQueryParams.currentPage;
  const enTitle = lightNovelQueryParams.enTitle;
  const jpTitle = lightNovelQueryParams.jpTitle;
  const jpRaw = lightNovelQueryParams.jpRaw;
  const fromDate = lightNovelQueryParams.fromDate;
  const toDate = lightNovelQueryParams.toDate;
  const userIdString = lightNovelQueryParams.userIdString;
  const statusIdString = lightNovelQueryParams.statusIdString;
  const publisherIdString = lightNovelQueryParams.publisherIdString;
  const typeIdString = lightNovelQueryParams.typeIdString;
  const prePublicationIdString = lightNovelQueryParams.prePublicationIdString;
  const is_bookmark = lightNovelQueryParams.is_bookmark;
  const redirect = lightNovelQueryParams.redirect !== null || undefined && lightNovelQueryParams.redirect;
  const is_form_dashboard = lightNovelQueryParams.is_form_dashboard !== null || undefined && lightNovelQueryParams.is_form_dashboard ? true : false;
  const [lightNovel,setLightNovel] = useState(state.row);
  const [show, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [currentComment, setCurrentComment] = useState(state.row.comment);
  const [currentLoggedInUser,setCurrentLoggedInUser] = useState({})
  const [universeSummaryData, setUniverseSummaryData] = useState([]);
  const [authorSummaryData, setAuthorSummaryData] = useState([]);
  const [illustratorSummaryData, setIllustratorSummaryData] = useState([]);
  const [openUniverseModel, setOpenUniverseModel] = useState(false);
  const [openAuthorModel, setOpenAuthorModel] = useState(false);
  const [openIllustratorModel, setOpenIllustratorModel] = useState(false);
  const [csvData,setCsvData] = useState([])
  const [openUserRatingListModel, setOpenUserRatingListModel] = useState(false);
  const [ratingType, setRatingType] = useState('');
  const [ratingInfo, setRatingInfo] = useState([]);

  const navigate = useNavigate();
  // const updateFlagStatus = async () => {
  //   await lightNovelUpdateFlagStatus({
  //     light_novel_id: state.row.id,
  //   });
  // };

  const { confirm } = Modal;
  const lightNovelId = state.row.id;
  const isSameLightNovelViewPage = localStorage.getItem('is_same_light_novel_view_page');

  const handleDeleteLightNovelComment = async () => {
    try {
      setCurrentComment('')
      const response = await deleteLightNovelComment(lightNovelId);
      if (response.isSuccess) {
        toast.success(`${response.message}`);
      }
    } catch (error) {
      setCurrentComment(state.row.comment)
      toast.error(`${error.message}`);
    }
  };

  const fetchUniverseDetail = async (unvierseId) => {
      setOpenUniverseModel(true);
      const res = await getUniverseSummaryById(unvierseId);
      if (res && res.isSuccess) {
        setUniverseSummaryData(res.data);
      }
  };

  const fetchAuthorsDetail = async (authorId) => {
      setOpenAuthorModel(true);
      const res = await getAuthorSummaryById(authorId);
      if (res && res.isSuccess) {
        setAuthorSummaryData(res.data);
      }
  };

  const fetchIllustratorDetail = async (illustratorId) => {
      setOpenIllustratorModel(true);
      const res = await getIllustratorSummaryById(illustratorId);
      if (res && res.isSuccess) {
        setIllustratorSummaryData(res.data);
      }
  };


  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this comment ?",
      icon: <ExclamationCircleFilled />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleDeleteLightNovelComment();
      },
    });
  };

  useEffect(() => {
    // updateFlagStatus();
    const data = getData(lightNovel)
    setCsvData(data)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
      setCurrentLoggedInUser(loginData.user);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await getLightNovelById({
          lightNovel_id: lightNovelId,
        });
        if(response && response.isSuccess){
          setCurrentComment(response.data.comment)
        }
      } catch (error) {
        toast.error(`${error.message}`);
      }
    })();
  }, []);

  const handleSearchParams = (baseurl,isback,isRedirect) => {
    if(currentPage) {
      isback ?  baseurl+=`&page=${currentPage}` : baseurl+=`?page=${currentPage}`
    }
    if(enTitle) baseurl+= `&en_title=${enTitle}`
    if(jpTitle) baseurl+= `&jp_title=${ jpTitle}`
    if(jpRaw) baseurl+= `&jp_raw=${jpRaw}`
    if(fromDate) baseurl+= `&from_date=${fromDate}`
    if(toDate) baseurl+= `&to_date=${toDate}`
    if(prePublicationIdString) baseurl += `&pre_publication_id=${prePublicationIdString}`
    if(userIdString) baseurl+= `&user_id=${userIdString}`
    if(publisherIdString) baseurl += `&publisher_id=${ publisherIdString}`
    if(typeIdString) baseurl += `&type_id=${typeIdString}`
    if(is_bookmark || is_bookmark === "false" || is_bookmark === "none") baseurl += `&is_bookmark=${is_bookmark === "true" ? "true" : is_bookmark === "none" ? "none" :"false"}`;
    if(statusIdString) baseurl += `&status_id=${statusIdString}`
    if(isRedirect && isSameLightNovelViewPage == 'false' && !is_form_dashboard){
      baseurl += `&redirect=1`
    };
    
    return baseurl;
  }

  useEffect(()=>{
    setLightNovel(state.row);
  },[state.row])

  
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card>
          <Row>
            <Col lg="7">
              <Card className="shadow m-4 detail_card">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg="5">
                      <h1 className="mb-0"> Light Novel Detail <Checkbox
                        {...label}
                        icon={<BookmarkBorderIcon />}
                        checkedIcon={<BookmarkIcon />}
                        defaultChecked={lightNovel.bookmark_id !== null ? true : false}
                        onClick={(e)=>handleLightNovelBookMark({isChecked:e.target.checked,lightNovelId:lightNovel?.id})}
                      /></h1>
                      
                    </Col>
                    <Col lg="7" className="add_btn text-right">
                    {permissions &&
                    permissions.map((permission) =>
                      permission === "update-light-novel" ? (
                        <Button
                        color="primary"
                        onClick={async () => {
                          const res = await getLightNovelById({
                            lightNovel_id: state.row.id,
                          });
                          let isSetRedirect = true;
                          let baseURL = `/admin/lightNovel/updateLightNovel/${lightNovelId}`;

                          if(redirect == 1 || is_form_dashboard){
                            baseURL += '?redirect=1'
                            isSetRedirect = false
                          }

                          if(redirect == 2){
                            baseURL += '?redirect=2'
                          }

                          const url = handleSearchParams(baseURL,false,isSetRedirect);
                          navigate(
                            url,
                            {
                              state: { row: res?.data },
                            }
                          );
                        }}
                      >
                        <span className="indicator-label">Edit</span>
                      </Button>

                      ):(""))}
                      
                      <CSVLink
                          data={csvData}
                          headers={headers}
                          filename={genrateCsvFileName(`light_novel_${lightNovel.en_title}`)}
                          className="btn btn-primary"
                          target="_blank"
                        >
                          Export to CSV
                      </CSVLink>

                      <Button
                        color="primary"
                        className="m-2"
                        onClick={() =>{
                          navigate(`/admin/lightNovel/view-Light-Novel-Print-Selection/${lightNovelId}`,{state:{row:lightNovel}});
                        }}
                      >
                        <span className="me-2">Print</span>
                      </Button>

                      <Button
                        color="primary"
                        className="m-2"
                        onClick={() => {
                          if(isSameLightNovelViewPage == 'true'){
                            navigate(-1)
                            localStorage.setItem('is_same_light_novel_view_page',false);
                          }

                          if(redirect == 1){
                            navigate(-1)
                          }
                          
                          if(redirect == 2 && redirect !== 1 || !redirect){
                            let baseURL = `/admin/lightNovel/light-Novel-list?id=${lightNovelId}`;
                            const url = handleSearchParams(baseURL,true);
                            navigate(url);
                            localStorage.setItem('is_same_light_novel_view_page',false);
                          }

                        }}
                      >
                        <span className="me-2">Back</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="custom-margin-top">
                  <Row>
                    {lightNovel?.en_title ? (
                      <>
                        <Col lg="4"> EN Title</Col>
                        <Col lg="8">
                          <div className="text-dark h2">
                            {lightNovel.en_title}
                          </div>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row className="mt-1">
                    {lightNovel?.universe_name ? (
                      <>
                        <Col lg="4">Universe Name</Col>
                        <Col lg="8">
                        <span
                            className="primary-text-color" style={{cursor: "pointer"}}
                            onClick={() =>
                              fetchUniverseDetail(lightNovel.universe_id)
                            }
                          >
                            {lightNovel?.universe_name}
                          </span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row className="mt-1">
                    {lightNovel?.jp_title ? (
                      <>
                        <Col lg="4">JP Title</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.jp_title}
                          </span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  {lightNovel?.status_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Status</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.status_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.publisher_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP publisher</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                  {lightNovel?.pre_publication_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Pre Publication</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.pre_publication_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.no_of_volumes ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">No. of vol</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.no_of_volumes}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.ongoing || !lightNovel?.ongoing && lightNovel?.ongoing !== null ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Ongoing</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel.ongoing === true ? "Yes" : "No"}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.type_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Type</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.type_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.print_rights_in_france ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Print Rights In France</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.print_rights_in_france_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.digital_rights_in_france ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Digital Rights In France</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.digital_rights_in_france_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.print_rights_in_us ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Print Rights In US</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.print_rights_in_us_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.digital_rights_in_us ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Digital Rights In US</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.digital_rights_in_us_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.print_rights_in_de ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Print Rights In DE</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.print_rights_in_de_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.digital_rights_in_de ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Digital Rights In DE</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.digital_rights_in_de_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_print_run_1 ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Print Run 1</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.jp_print_run_1}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_print_run_2 ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Print Run 2</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.jp_print_run_2}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_print_run_3 ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Print Run 3</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.jp_print_run_3}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_print_run_4 ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Print Run 4</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.jp_print_run_4}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_wikipedia_link ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Wikipedia</Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={lightNovel?.jp_wikipedia_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {lightNovel?.jp_wikipedia_link?.length > 50
                                ? `${lightNovel?.jp_wikipedia_link.slice(
                                    0,
                                    40
                                  )}...`
                                : lightNovel?.jp_wikipedia_link}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.nautiljon ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Nautiljon</Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={lightNovel?.nautiljon}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {lightNovel?.nautiljon?.length > 50
                                ? `${lightNovel?.nautiljon.slice(0, 40)}...`
                                : lightNovel?.nautiljon}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_amazon_link ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Amazon</Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={lightNovel?.jp_amazon_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {lightNovel?.jp_amazon_link?.length > 50
                                ? `${lightNovel?.jp_amazon_link.slice(
                                    0,
                                    40
                                  )}...`
                                : lightNovel?.jp_amazon_link}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_bookwalker_link ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP BookWalker</Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={lightNovel?.jp_bookwalker_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {lightNovel?.jp_bookwalker_link?.length > 50
                                ? `${lightNovel?.jp_bookwalker_link.slice(
                                    0,
                                    40
                                  )}...`
                                : lightNovel?.jp_bookwalker_link}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                {lightNovel?.jp_raw ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Raw</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.jp_raw}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.scantrad ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">ScanTrad</Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={lightNovel?.scantrad}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {lightNovel?.scantrad?.length > 50
                                ? `${lightNovel?.scantrad.slice(
                                    0,
                                    40
                                  )}...`
                                : lightNovel?.scantrad}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {permissions &&
                    permissions.map((permission) =>
                      permission === "view-light-novel-low-sale" ? (
                        <>
                          {lightNovel?.low_sales ? (
                              <Row className="mt-1">
                                <Col lg="4">Low Sales</Col>
                                <Col lg="8">
                                  <span className="text-dark">
                                    {lightNovel?.low_sales}
                                  </span>
                                </Col>
                              </Row>
                          ) : (
                            ""
                          )}  
                        </>
                      ) : (
                        ""
                      )
                  )}

                  {permissions &&
                      permissions.map((permission) =>
                        permission === "view-light-novel-medium-sale" ? (
                          <>
                          {lightNovel?.medium_sales ? (
                                <Row className="mt-1">
                                  <Col lg="4">Medium Sales</Col>
                                  <Col lg="8">
                                    <span className="text-dark">
                                      {lightNovel?.medium_sales}
                                    </span>
                                  </Col>
                                </Row>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )
                  )}

                  {permissions &&
                      permissions.map((permission) =>
                        permission === "view-light-novel-high-sale" ? (
                          <>
                            {lightNovel?.high_sales ? (
                                  <Row className="mt-1">
                                    <Col lg="4">High Sales</Col>
                                    <Col lg="8">
                                      <span className="text-dark">
                                        {lightNovel?.high_sales}
                                      </span>
                                    </Col>
                                  </Row>
                              ) : (
                                ""
                              )}
                          </>
                        ) : (
                          ""
                        )
                  )}
                   
                  {lightNovel?.fr_consumer_price ? (
                      <>
                        <Row className="mt-1">
                          <Col lg="4">FR Consumer Price</Col>
                          <Col lg="8">
                            <span className="text-dark">
                              {lightNovel?.fr_consumer_price}
                            </span>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ""
                  )}

                  {lightNovel?.summary ? (
                    <>
                      <Row className="mt-21">
                        {lightNovel?.summary ? (
                          <>
                            <Col lg="4">Summary</Col>
                            <Col lg="8">
                              <span className="text-dark">
                                {lightNovel?.summary}
                              </span>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                {permissions &&
                      permissions.map((permission) =>
                        permission === "view-light-novel-product-pl" ? (
                          <>
                              {lightNovel?.product_pl ? (
                                <>
                                <Row className="mt-1">
                                  <Col lg="4">Product P&L</Col>
                                  <Col lg="8">
                                     
                                      <span className="link primary-text-color text-wrap">
                                          <a
                                            href={lightNovel?.product_pl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {lightNovel?.product_pl?.length >
                                            50
                                              ? `${lightNovel?.product_pl.slice(
                                                  0,
                                                  40
                                                )}...`
                                              : lightNovel?.product_pl}
                                          </a>
                                      </span>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )
                )}

                  {lightNovel?.created_at ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">CreatedAt</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel.created_at
                              ? moment(lightNovel.created_at).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : ""}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg="2" className="columns"></Col>
            <Col lg="3">
              <Card className="text-right shadow m-4 view_cards">
                <CardBody>
                  {lightNovel?.cover_image ? (
                    <>
                      <CardImg
                        src={lightNovel?.cover_image}
                        alt="Upload"
                        className="overflow-hidden"
                        onClick={async () => {
                          setShowModal(true);
                          setImage(lightNovel.cover_image);
                        }}
                      />
                    </>
                  ) : (
                    <CardImg
                      src={require("../../../assets/images/no-image.png")}
                      alt="Upload"
                      className=" overflow-hidden"
                      onClick={async () => {
                        setShowModal(true);
                        setImage(
                          require("../../../assets/images/no-image.png")
                        );
                      }}
                    />
                  )}
                </CardBody>
              </Card>

              <Card className="text-left shadow m-4 view_cards">
                <CardBody>
                   <h4>Ratings</h4>

                   <div className="d-inline-flex">
                        <div>
                            <img 
                              src={`${process.env.PUBLIC_URL}/rating-images/1.jpg`} 
                              alt="rating-1" 
                              style={{ width: '30%' }} 
                              />
                            <span 
                              className={`m-1 ${lightNovel?.rating_one_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`}
                              onClick={()=>{
                                if(lightNovel?.rating_one_counts !== "0"){
                                  setRatingType(1);
                                  setRatingInfo(lightNovel);
                                  setOpenUserRatingListModel(true);
                                  }
                              }} 
                             > 
                             {lightNovel?.rating_one_counts || 0}
                            </span>  
                        </div>

                        <div className="pe-auto">
                            <img 
                              src={`${process.env.PUBLIC_URL}/rating-images/2.jpg`} 
                              alt="rating-2" 
                              style={{ width: '30%' }} 
                            />
                             <span 
                            className={`m-1 ${lightNovel?.rating_two_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`}
                            onClick={()=>{
                              if(lightNovel?.rating_two_counts !== "0"){
                                setRatingType(2);
                                setRatingInfo(lightNovel);
                                setOpenUserRatingListModel(true);
                                }
                            }} 
                            >
                            {lightNovel?.rating_two_counts || 0}
                           </span>
                        </div>

                        <div>
                            <img 
                              src={`${process.env.PUBLIC_URL}/rating-images/3.jpg`} 
                              alt="rating-3" 
                              style={{ width: '27%'}} 
                            />
                             <span 
                              className={`m-2 ${lightNovel?.rating_three_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`}
                              onClick={()=>{
                                if(lightNovel?.rating_three_counts !== "0"){
                                  setRatingType(3);
                                  setRatingInfo(lightNovel);
                                  setOpenUserRatingListModel(true);
                                  }
                              }} 
                            >
                              {lightNovel?.rating_three_counts || 0}
                            </span> 
                        </div>
                    </div>
                </CardBody>
              </Card>
              
            </Col>
          </Row>
          <Row>
            {lightNovel?.authors.length ? (
              <Col lg="4">
                <Card className=" shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Authors</h3>
                    <Col>
                      {lightNovel.authors.map((author, index) => (
                         <li
                         className="primary-text-color" style={{cursor: "pointer"}}
                         onClick={() =>
                           fetchAuthorsDetail(author.id)
                         }
                         key={index}
                       >
                         {author.name}
                       </li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {lightNovel?.authors.length ? <Col lg="2"></Col> : ""}
            {lightNovel?.genres.length ? (
              <Col lg="4">
                <Card className="me-2 shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Genre</h3>
                    <Col>
                      {lightNovel.genres.map((genre, index) => (
                        <li key={index}>{genre.name}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {lightNovel?.illustrators.length ? (
              <Col lg="4">
                <Card className=" shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Illustrator</h3>
                    <Col>
                      {lightNovel.illustrators.map((illustrators, index) => (
                        <li className="primary-text-color" style={{cursor: "pointer"}}  onClick={() =>
                          fetchIllustratorDetail(
                            illustrators.id
                          )
                        } key={index}>{illustrators.name}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {lightNovel?.illustrators.length ? <Col lg="2"></Col> : ""}

            {lightNovel?.publics.length ? (
              <Col lg="4">
                <Card className="me-2 shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Public</h3>
                    <Col>
                      {lightNovel.publics.map((publicsD, index) => (
                        <li key={index}>{publicsD.name}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col lg="12">
              <Card className="me-2 shadow m-4 ">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Comments</h3>
                  {currentComment  || lightNovel.all_comments ? (
                    <>
                      <Row>
                        {currentComment  ? (
                          <Col lg="3">
                            <Card className=" shadow mt-2 ">
                              <CardHeader className="h4">
                              {currentLoggedInUser.first_name} {currentLoggedInUser.last_name}
                              <RiDeleteBin6Line
                                  onClick={showDeleteConfirm}
                                  className="ml-7"
                                  style={{ cursor: "pointer" }}
                                  size={26}
                                  color="#e62a2e"
                                />
                              </CardHeader>
                              <CardBody>{currentComment}</CardBody>
                            </Card>
                          </Col>
                        ) : (
                          ""
                        )}
                        {lightNovel?.all_comments?.length > 0
                          ? lightNovel.all_comments.map((x) => (
                              <>
                                <Col lg="3">
                                  <Card className="shadow mt-2 ">
                                    <CardHeader className="h4">
                                      {x.first_name} {x.last_name}
                                    </CardHeader>
                                    <CardBody>{x.comment}</CardBody>
                                  </Card>
                                </Col>
                              </>
                            ))
                          : ""}
                      </Row>
                    </>
                  ) : (
                    "No Comments"
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ImageModal
            show={show}
            image={image}
            onClose={() => {
              setShowModal(false);
            }}
          />
        <UniverseModal openUniverseModel = {openUniverseModel} setOpenUniverseModel={setOpenUniverseModel} universeSummaryData = {universeSummaryData} isPageReload = {isPageReload.LIGHT_NOVEL} currentLightNovelId = {lightNovelId} isLinkFunctionalityActive={true}/>
        <AuthorModal openAuthorModel = {openAuthorModel} setOpenAuthorModel={setOpenAuthorModel} authorSummaryData={authorSummaryData } isPageReload = {isPageReload.LIGHT_NOVEL} currentLightNovelId = {lightNovelId} isLinkFunctionalityActive={true}/>
        <IllustratorModal openIllustratorModel = {openIllustratorModel} setOpenIllustratorModel={setOpenIllustratorModel} illustratorSummaryData={illustratorSummaryData} isPageReload = {isPageReload.LIGHT_NOVEL} currentLightNovelId = {lightNovelId} isLinkFunctionalityActive={true} />
        <RatingUserListModal openUserRatingListModel={openUserRatingListModel} setOpenUserRatingListModel={setOpenUserRatingListModel} ratingInfo={ratingInfo} ratingType = {ratingType} title = "Light Novel Rating Details" />
        </Card>
      </Container>
    </>
  );
}

export default ViewLightNovel;
